import {
    CONTAINER_ADD_CLICKED,
    CONTAINER_ADD_OWNER_CLICKED,
    CONTAINER_DEL_CLICKED,
    CONTAINER_DEL_OWNER_CLICKED,
    CONTAINER_FIELD_CHANGED,
    CONTAINER_MEMBER_CLICKED,
    CONTAINER_RECEIVED_MEMBERS,
    CONTAINER_REQUEST_MEMBERS,
    CONTAINER_SAVED,
    CONTAINER_SAVING,
} from "../actions/containerActions";

function container(
  state = {
    isFetching: false,
    saved: false,
    members: [],
    group: [],
    owners: [],
  },
  action
) {
  let group = [];
  let remainingGroup = [];
  let remainingMembers = [];
  let selectedGroup = [];
  let selectedMembers = [];
  let selectedItems = [];
  let remainingOwners = [];
  let mainList = [];

  switch (action.type) {
    case CONTAINER_SAVING:
      return Object.assign({}, state, {
        saved: "nok",
        saving: true,
      });
    case CONTAINER_SAVED:
      // we want to indicate a successful save
      return Object.assign({}, state, {
        saved: action.payload,
        saving: false,
      });
    case CONTAINER_FIELD_CHANGED:
      if (action.payload.value === "") {
        //we dont want the attribute
        return Object.assign({}, state, { [action.payload.field]: null });
      } else {
        return Object.assign({}, state, {
          [action.payload.field]: action.payload.value,
        });
      }
    case CONTAINER_DEL_OWNER_CLICKED:
      state.owners.forEach((member) => {
        if (member.selected) {
          selectedItems.push({ ...member, selected: false });
        } else {
          remainingOwners.push(member);
        }
      });
      mainList = [...state.members, ...selectedItems];
      return { ...state, owners: remainingOwners, members: mainList };
    case CONTAINER_DEL_CLICKED:
      //this is the reverse pf the ADD_CLICKED
      remainingGroup = state.group.filter((member) => !member.selected);
      selectedGroup = state.group
        .filter((member) => member.selected)
        .map((member) => ({ ...member, selected: false }));
      mainList =  [...state.members, ...selectedGroup];
      return { ...state, group: remainingGroup, members: mainList };
    case CONTAINER_ADD_OWNER_CLICKED:
      // remove the selected Items from members array and add them to the group list.
      // reset the selected attribute at the same time.
      remainingMembers = state.members.filter((member) => {
        return !member.selected;
      });
      selectedMembers = state.members.filter((member) => {
        return member.selected;
      }).map((member) => {
        return {
          ...member,
          selected: false,
        };
      });
      const owners = state.owners.concat(selectedMembers);
      return {
        ...state,
        owners: owners,
        members: remainingMembers,
      };
    case CONTAINER_ADD_CLICKED:
      const remainingMembers1 = [];
      const selectedMembers1 = [];

      state.members.forEach((member) => {
        if (member.selected) {
          selectedMembers1.push({ ...member, selected: false });
        } else {
          remainingMembers1.push(member);
        }
      });

      group = [...state.group, ...selectedMembers1];

      return { ...state, group, members: remainingMembers1 };
    case CONTAINER_MEMBER_CLICKED:
      const items = state[action.payload.collection.toLowerCase()];
      const newItems = items.map((item) => {
        if (item.username === action.payload.itemKey) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
      return {
        ...state,
        [action.payload.collection.toLowerCase()]: newItems,
      };
    case CONTAINER_REQUEST_MEMBERS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CONTAINER_RECEIVED_MEMBERS:
      //loop through the payload and add a selected flag to each member
      let members = action.payload;
      members.forEach((member) => {
        member["selected"] = false;
      });
      return Object.assign({}, state, {
        isFetching: false,
        members: members,
        group: [],
      });
    default:
      return state;
  }
}
export default container;
