// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import {Navigate} from 'react-router-dom'
import {connect} from "react-redux";

const PrivateRoute = ({ user, children, ...rest }) => {
    let props = {...rest};
    let isLoggedIn = props.auth;
    if (!isLoggedIn) {
        return <Navigate to="/landing" replace />;
    }
    return children;
};
/*const PrivateRoute = ({element: Component, ...rest }) => {

    // Add your own authentication on the below line.
    let props = {...rest};
    let isLoggedIn = props.auth;
//console.log(isLoggedIn);
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn !== false? (
                    <Component {...props} />
                ) : (
                    <Navigate to={{ pathname: '/', state: { from: props.location } }} />
                )
            }
        />
    )
}*/

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};
export default connect(mapStateToProps) (PrivateRoute);
