import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Button, TextField} from '@mui/material';
import {setSelectedDate} from '../../actions/customerActions';
import Box from "@mui/material/Box"; // Import the actions
//changes made on 12/09/2023 to set a default date to the first day of the month
const DateSearchComponent = ({customerId, searchFunction}) => {
    const dispatch = useDispatch();
    const selectedDate = useSelector((state) => state.customer.selectedDate); // Fetch date from Redux state

    const handleDateChange = (newDate) => {
        if(newDate) {
            dispatch(setSelectedDate(newDate.toISOString().split('T')[0])); // Update date in Redux state
        }else{
            const firstDayOfMonth = new Date();
            firstDayOfMonth.setDate(1);
            dispatch(setSelectedDate(firstDayOfMonth.toISOString().split('T')[0])); // Update date in Redux state
        }
    };

    const handleSearch =  () => {
        searchFunction();
    };

    return (
        <Box display="flex" alignItems="flex-end">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="From Date"
                    inputFormat="yyyy-MM-dd"
                    value={selectedDate ? new Date(selectedDate) : null} // Convert the date string back to a Date object
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} sx={{ height: 50 }} />}
                />
            </LocalizationProvider>
            <Box ml={2} >
            <Button variant="contained" color="primary"  sx={{ height: 50 }} onClick={handleSearch} >
                Search
            </Button>
            </Box>
        </Box>
    );
};

export default DateSearchComponent;
