// utils/api.js
import axios from 'axios';

const api = axios.create({
    baseURL: '/',
});

let navigate;

export const setNavigate = (navFunc) => {
    navigate = navFunc;
};

// Add a response interceptor to check for session expiration
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401 && navigate) {
            // Session has expired, navigate to the login page
           // navigate('/login');

            // Replicate the action of the logout button by sending a request to /api/logout
            api.get('/api/logout')
                .then(() => {
                    // Optional: You can also dispatch the user logout action here if needed
                    // This depends on your Redux setup
                    // dispatch(logoutUser()); // Dispatch the logout action

                    // Reload the page to ensure a fresh session
                    window.location.reload();
                })
                .catch((logoutError) => {
                    // Handle any errors that may occur during logout
                    console.error('Logout error:', logoutError);
                });
        }
        return Promise.reject(error);
    }
);

export default api;
