//import axios from 'axios';

import api from "../utils/api";

import {notifyError} from "./index";
import {checkFor401, enqueueSnackbar} from "./cbuserActions";
import {FETCH_INVENTORY_TX_SUMMARY} from "./cbinventoryActions";
import {FETCH_CBORDERS} from "./cbOrderActions";

export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
const FETCH_CUSTOMER_MOVEMENTS = 'FETCH_CUSTOMER_MOVEMENTS';
const FETCH_CUSTOMER_TASKS = 'FETCH_CUSTOMER_TASKS';
const FETCH_CUSTOMER_COSTS = 'FETCH_CUSTOMER_COSTS';
export const FETCH_CUSTOMER_INVOICES = 'FETCH_CUSTOMER_INVOICES';

export const UPDATE_TASKS = 'UPDATE_TASKS';
export const UPDATE_COSTS = 'UPDATE_COSTS';

export const SYNC_ORDERS = 'SYNC_ORDERS';


export const syncOrders = (customerId) => async dispatch => {
    const url = `/api/cbuser/customer/${customerId}/orders/sync.json`;
    dispatch(enqueueSnackbar("syncing orders", "info", 2500, true));
    dispatch({ type: `${SYNC_ORDERS}_REQUEST` });
    try {
        let res = await api.post(url);
        //console.log(res.data);
        dispatch({ type: `${SYNC_ORDERS}_SUCCESS`, payload: res.data });
        dispatch(enqueueSnackbar("orders imported", "info", 2500, true));
    } catch (e) {
        dispatch({ type: `${SYNC_ORDERS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};

export const fetchCustomerInvoices = () => async (dispatch, getState) => {
    const url = "/api/customer/invoices";
    try {
        dispatch({type: `${FETCH_CUSTOMER_INVOICES}_REQUEST`});
        let res = await api.get(url);
        dispatch({type: `${FETCH_CUSTOMER_INVOICES}_SUCCESS`, payload: res.data});
    } catch (e) {
        dispatch({type: `${FETCH_CUSTOMER_INVOICES}_FAILURE`, payload: e.message});
        notifyError(e, dispatch);

        await checkFor401(e, dispatch);
    }
};

export const fetchCustomerOrders = () => async dispatch => {
    const url = `/api/customer/orders`;
    dispatch({ type: `${FETCH_CBORDERS}_REQUEST` });
    try {
        let res = await api.get(url);
        //console.log(res.data);
        dispatch({ type: `${FETCH_CBORDERS}_SUCCESS`, payload: res.data });
    } catch (e) {
        dispatch({ type: `${FETCH_CBORDERS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};

export const fetchCustomerInventoryTxSummary = () => async dispatch =>{
    const url = `/api/customer/inventory/transactions/summary`;
    try {
        dispatch({ type: `${FETCH_INVENTORY_TX_SUMMARY}_REQUEST` });
        let res = await api.get(url);
        dispatch({type: `${FETCH_INVENTORY_TX_SUMMARY}_SUCCESS`, payload: res.data});
    }catch (e) {
        dispatch({ type: `${FETCH_INVENTORY_TX_SUMMARY}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }

}

// actions.js

export const UPDATE_MOVEMENTS = 'UPDATE_MOVEMENTS';

export const fetchCustomerMovements = () => async (dispatch, getState) =>{
    const url = `/api/customer/movements`;

    const fromDate = getState().customer.selectedDate;

    try {
        dispatch({ type: `${FETCH_CUSTOMER_MOVEMENTS}_REQUEST` });
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                fromDate: fromDate
            }
        }
        let res = await api.get(url, config);
        dispatch(updateMovements(res.data));
    }catch (e) {
        dispatch({ type: `${FETCH_CUSTOMER_MOVEMENTS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }

}


export const fetchCustomerTasks = () => async (dispatch, getState) =>{
    const url = `/api/customer/tasks`;
    const fromDate = getState().customer.selectedDate;
    try {
        dispatch({ type: `${FETCH_CUSTOMER_TASKS}_REQUEST` });
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                fromDate: fromDate
            }
        }
        let res = await api.get(url, config);
        // Check if 'res' is defined and contains a 'data' property
        if (res && res.data) {
            console.log(res.data);
            dispatch(updateTasks(res.data));
        } else {
            console.log('no res or data');
            dispatch({
                type: `${FETCH_CUSTOMER_TASKS}_FAILURE`,
                payload: 'Response data is missing or undefined.',
            });
        }
    }catch (e) {
        console.log(e);
        dispatch({ type: `${FETCH_CUSTOMER_TASKS}_FAILURE`, payload: e.message });
        //notifyError(e, dispatch);
    }

}

export const fetchCustomerCosts = () => async (dispatch, getState) =>{
    const url = `/api/customer/costs`;
    const fromDate = getState().customer.selectedDate;

    try {
        dispatch({ type: `${FETCH_CUSTOMER_COSTS}_REQUEST` });
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                fromDate: fromDate
            }
        }
        let res = await api.get(url, config);
        dispatch(updateCosts(res.data));
    }catch (e) {
        dispatch({ type: `${FETCH_CUSTOMER_COSTS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }

}

export const setSelectedDate = (date) => ({
    type: SET_SELECTED_DATE,
    payload: date,
});

export const updateMovements = (data) => ({
    type: UPDATE_MOVEMENTS,
    payload: data,
});

export const updateTasks = (data) => ({
    type: UPDATE_TASKS,
    payload: data,
});

export const updateCosts = (data) => ({
    type: UPDATE_COSTS,
    payload: data,
});

