import axios from "axios";
import axiosRetry from "axios-retry";
import {fetchUser, resetStore} from "./index";

export const RECEIVED_RELATED = 'RECEIVED_RELATED';

export const REQUEST_RELATED_ERROR = 'REQUEST_RELATED_ERROR';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';

axiosRetry(axios, { retries: 0 });
//a new way to get at user data that is not wrapped up with the authentication actions

function updateEmailSuccess(userId, email) {
    return {
        type: UPDATE_EMAIL_SUCCESS,
        userId,
        email,
        receivedAt: Date.now()
    };
}

function updateEmailError(userId, error) {
    return {
        type: UPDATE_EMAIL_ERROR,
        userId,
        error,
        receivedAt: Date.now()
    };
}

export const updateEmailAction = (userId, newEmail) => async dispatch => {
    try {
        // Make API call to update email
        const url = "/api/user/" + userId;
        console.log(`updateEmailAction url: ${url}`);
        const response = await axios.post(url, { email: newEmail });
        // Dispatch success action with updated email
        //fetch the user data
        dispatch(fetchUser());
        dispatch(updateEmailSuccess(userId, newEmail));
    } catch (error) {
        // Dispatch error action if the request fails
        console.log("updateEmailAction error", error.message || 'Email update failed')
        dispatch(updateEmailError(userId, error.message || 'Email update failed'));
    }
};
function receiveRelated(json){
    return {
        type: RECEIVED_RELATED,
        payload: json,
        receivedAt: Date.now()
    };
}

function receiveRelatedError(userId,code){
    return {
        type: REQUEST_RELATED_ERROR,
        userId,
        payload: code,
        receivedAt: Date.now()
    };
}

/*
export const fetchMember = (userId) => async dispatch => {
    const url = "/api/user/" + userId;
    try {
        const res = await axios.get(url);
        //console.log(res.data);
        dispatch(receiveUser(res.data));
    }catch(e){
        //console.log("error",e);
        dispatch(receiveUserError(userId,e.code));
    }
};
*/

export const  changePwd = () => async (dispatch,getState) => {
    const userId = getState().auth._id;
    const oldPwd = getState().ui.searchParams.oldPwd;
    const newPwd = getState().ui.searchParams.newPwd;
    const confirmPwd = getState().ui.searchParams.confirmPwd;
    try {
        await axios.put("/api/user/credentials/" + userId, {
            oldPwd: oldPwd,
            newPwd: newPwd,
            confirmPwd: confirmPwd
        });
        dispatch(resetStore());
        await axios.get('/api/logout',{ validateStatus: function (status) {
                return status >= 200 || status === 304; // default
            },});

    }catch (e) {
        console.log(e.message);
        console.log(e);
    }
}
export const fetchRelatedMembers = (userId) => async dispatch => {
    const url = "/api/user/related/" + userId;
    try{
        const res = await axios.get(url);
        dispatch( receiveRelated(res.data));

    }catch(e){
        dispatch(receiveRelatedError(userId,e.code));
    }
};

