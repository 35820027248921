// selectedRowsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const selectedOrdersSlice = createSlice({
    name: 'selectedOrders',
    initialState: [],
    reducers: {
        addSelectedOrder: (state, action) => {
            // Check if the order already exists in the state
            const existingOrder = state.some(order => order._id === action.payload._id);

            // Only add the order if it does not already exist
            if (!existingOrder) {
                state.push(action.payload);
            }
        },
        removeSelectedOrder: (state, action) => {
            const index = state.findIndex(row => row._id === action.payload._id);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
    },
});

export const { addSelectedOrder, removeSelectedOrder } = selectedOrdersSlice.actions;
export default selectedOrdersSlice.reducer;
