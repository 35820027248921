import axios from "axios";
import axiosRetry from "axios-retry";
import {GLRError} from "./index";

export const REQUEST_DRAFT_ORDER_TOTALS = "REQUEST_DRAFT_ORDER_TOTALS";
export const RECEIVED_DRAFT_ORDER_TOTALS = "RECEIVED_DRAFT_ORDER_TOTALS";
export const REQUEST_DRAFT_ORDER_MTD_TOTALS = "REQUEST_DRAFT_ORDER_MTD_TOTALS";
export const REQUEST_DRAFT_ORDER_YTD_TOTALS = "REQUEST_DRAFT_ORDER_YTD_TOTALS";
export const RECEIVED_DRAFT_ORDER_MTD_TOTALS = "RECEIVED_DRAFT_ORDER_MTD_TOTALS";
export const RECEIVED_DRAFT_ORDER_YTD_TOTALS = "RECEIVED_DRAFT_ORDER_YTD_TOTALS";
export const RECEIVED_DRAFT_ORDER_RANKED_CENTRES = "RECEIVED_DRAFT_ORDER_RANKED_CENTRES";
export const REQUEST_DRAFT_ORDER_RANKED_CENTRES = "REQUEST_DRAFT_ORDER_RANKED_CENTRES";
axiosRetry(axios, { retries: 0 });

function requestRankedCentres() {
    return {
        type: REQUEST_DRAFT_ORDER_RANKED_CENTRES,
        payload: {}
    };
}

function receivedRankedCentres (centreList){
    return {
        type: RECEIVED_DRAFT_ORDER_RANKED_CENTRES,
        payload: centreList
    }
}
function requestTotals() {
    return {
        type: REQUEST_DRAFT_ORDER_TOTALS,
        payload: {}
    };
}

function receivedTotals(totals) {
    return {
        type: RECEIVED_DRAFT_ORDER_TOTALS,
        payload: totals
    };
}
function requestMTDTotals() {
    return {
        type: REQUEST_DRAFT_ORDER_MTD_TOTALS,
        payload: {}
    };
}
function requestYTDTotals() {
    return {
        type: REQUEST_DRAFT_ORDER_YTD_TOTALS,
        payload: {}
    };
}
function receivedMTDTotals(results) {
    return {
        type: RECEIVED_DRAFT_ORDER_MTD_TOTALS,
        payload: results
    };
}

function receivedYTDTotals(results) {
    return {
        type: RECEIVED_DRAFT_ORDER_YTD_TOTALS,
        payload: results
    };
}
export const getDraftOrderTotals = () => async (dispatch) => {
    let url = "/api/reports/draftorder/totals";
    try{
        dispatch(requestTotals());
        const response = await axios.get(url);
        dispatch(receivedTotals(response.data));
    } catch (e) {
        console.log(e.message);
        console.log(e);
    }
}
export const getDraftOrderMTDTotals = () => async (dispatch) => {
    let url = "/api/reports/draftorder/mtd/totals";
    try{
        dispatch(requestMTDTotals());
        const response = await axios.get(url);
        dispatch(receivedMTDTotals(response.data));
    } catch (e) {
        console.log(e.message);
        console.log(e);
        GLRError({errorMessage: e.message, status: e.status});
    }
}

export const getDraftOrderYTDTotals = () => async (dispatch) => {
    let url = "/api/reports/draftorder/ytd/totals";
    try{
        dispatch(requestYTDTotals());
        const response = await axios.get(url);
        dispatch(receivedYTDTotals(response.data));
    } catch (e) {
        console.log(e.message);
        console.log(e);
        GLRError({errorMessage: e.message, status: e.status});
    }
}

export const getRankedCentres = () => async (dispatch) => {
    let url = "/api/reports/draftorder/ranked/centres";
    try{
        dispatch(requestRankedCentres());
        const response = await axios.get(url);
        dispatch(receivedRankedCentres(response.data));
    }catch (e) {
        console.log(e.message);
        console.log(e);
    }
}

