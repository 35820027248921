export const RELATED_USER_SELECTED = "RELATED_USER_SELECTED";
export const MASTER_ORDER_SELECTED = "MASTER_ORDER_SELECTED";
export const SEARCH = "SEARCH";
export const NEW_DRAFT_CHANGE = "NEW_DRAFT_CHANGE";
export const SAVE_DRAFT = "SAVE_DRAFT";
export const NEW_DRAFT = "NEW_DRAFT";
export const NEW_DRAFT_LINE_CHANGE = "NEW_DRAFT_LINE_CHANGE";
export const CLONE_DRAFT_ORDER = "CLONE_DRAFT_ORDER";
export const CENTRE_CHANGED = "CENTRE_CHANGED";
export const CENTRE_CLEAR = "CENTRE_CLEAR";
export const CENTRE_SORT_REQUESTED = "CENTRE_SORTED_REQUESTED";
export const DRAFT_ORDER_SELECTED = "DRAFT_ORDER_SELECTED";
export const OPEN_CREDENTIALS_FORM = "OPEN_CREDENTIALS_FORM";
export const VARIANT_SELECTED = "VARIANT_SELECTED";
export const CB_TOTAL_PARAM_SELECTED = "CB_TOTAL_PARAM_SELECTED";
export const CB_TOTAL_ACTIVE_SWITCHED = "CB_TOTAL_ACTIVE_SWITCHED";
export const DRAFT_ORDER_RESET = "DRAFT_ORDER_RESET";
export const MOVEMENT_GRID_CHANGED = "MOVEMENT_GRID_CHANGED";
export const MOVEMENT_CLEAR = "MOVEMENT_CLEAR";

export function resetDraftOrder() {
  return dispatch => {
    dispatch({type: DRAFT_ORDER_RESET, payload: null});
  }
}
export function setCBTotalDateParam (period){
  return dispatch => {
    dispatch({
      type: CB_TOTAL_PARAM_SELECTED,
      payload: period
    });
  };
}
export function setCBCustomersActive(active){
  return dispatch => {
    dispatch({
      type:CB_TOTAL_ACTIVE_SWITCHED,
      payload: active
    })
  }
}

export function centreClear() {
  return dispatch => {
    dispatch({
      type: CENTRE_CLEAR,
      payload: null
    });
  };
}
export function movementClear() {
  return dispatch => {
    dispatch({
      type: MOVEMENT_CLEAR,
      payload: null
    });
  };
}

export function setOpenCredentialsForm(){
  return dispatch => {
    dispatch({type: OPEN_CREDENTIALS_FORM, payload: null});
  }
}
export function selectDraftOrder(id,draft){
  return dispatch => {
    dispatch({
      type:DRAFT_ORDER_SELECTED,
      payload: {id: id, draft: draft}
    })
  }
}
export function sortCentreTable(fieldId) {
  return dispatch => {
    dispatch({
      type: CENTRE_SORT_REQUESTED,
      payload: fieldId
    });
  };
}

export function saveDraftTriggered() {
  return dispatch => {
    dispatch({
      type: SAVE_DRAFT,
      payload: null
    });
  };
}

export function cloneDraftOrderTriggered(id) {
  return dispatch => {
    dispatch({ type: CLONE_DRAFT_ORDER, payload: id });
  };
}
export function newDraftTriggered() {
  return dispatch => {
    dispatch({
      type: NEW_DRAFT,
      payload: null
    });
  };
}
//fired off as soon as a user begins to type into the Input field
// this builds up the draft order definition ready to be saved
export function newDraftChange(fieldId, text) {
  let payload = { field: fieldId, value: text };
  return dispatch => {
    dispatch({
      type: NEW_DRAFT_CHANGE,
      payload: payload
    });
  };
}

export function newDraftLineChange(fieldId, data) {
  let payload = data;
  return dispatch => {
    dispatch({
      type: NEW_DRAFT_LINE_CHANGE,
      payload: payload
    });

  };
}


//fired off as soon as a user begins to type into the Input field
export function search(fieldId, text) {
  let payload = { field: fieldId, value: text };
  return dispatch => {
    dispatch({
      type: SEARCH,
      payload: payload
    });
  };
}
export function centreChanged(centreId, fieldId, text) {
  let payload = { _id: centreId, fieldId: fieldId, value: text };
  return dispatch => {
    dispatch({
      type: CENTRE_CHANGED,
      payload: payload
    });
  };
}
export function movementGridChanged(movementId, fieldId, text){
  let payload = {_id: movementId,fieldId: fieldId, value: text}
  return dispatch => {
    dispatch({
      type:MOVEMENT_GRID_CHANGED,
      payload: payload
    });
  }
}
export function selectOrder(orderId) {
  console.log("action: selectOrder()");
  return (dispatch, getState) => {
    const orders = getState().order.GLROrderList;
    const orderIndex = orders.findIndex(order => {
      return order._id === orderId;
    });
    const payload = { masterOrderIndex: orderIndex, masterOrderId: orderId };
    dispatch({ type: MASTER_ORDER_SELECTED, payload });
  };
}


export const RESET_SEARCH = "RESET_SEARCH";

export function resetSearch() {
  return dispatch => {
    dispatch({
      type: RESET_SEARCH,
      payload: null,
    });
  };
}



