import React, {Component} from "react";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-theme-balham.css";
import {Box} from "@mui/material";
import PropTypes from "prop-types";

function getCostDate(params) {
    const dateTime = params.data.costDate.split("T");
    return dateTime[0];
}


function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
}

class CustomerCostsGrid extends Component {
    colDefs = [
        {
            headerName: "Date",
            colId: "costDate",
            width:250,
            valueGetter: getCostDate,
            sort: 'desc',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            sortable: true
        },
        {
            field: "type",
            colId: "type",
            headerName: "Type",
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            sortable: true
        },

        {
            headerName: "SubType",
            field: "name",
            colId: "name",
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            width:250,
            sortable: true
        },
        {
            headerName: "Quantity",
            field: "quantity",
            colId: "quantity",
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            width:250,
            sortable: true
        },

        {
            valueFormatter: (params) => {
                if (typeof params.value === Number) {
                    return params.value.toFixed(2);
                }
            },
            field: "actualCost",
            colId: "actualCost",
            headerName: "Cost",
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            sortable: true,
        },
        {

            valueFormatter: (params) => {
                if (typeof params.value === Number) {
                    return params.value.toFixed(2);
                }
            },
            field: "vat",
            colId: "vat",
            headerName: "VAT",
            sortable: true
        }
    ];
    onGridReady = params => {
        if (this.props.setGridApi) {
            this.props.setGridApi(params.api);
        }
    }
    render() {
        return (
            <Box
                 id="CBTasksGrid"
                 style={{ height: 500 }}
                 className="ag-theme-balham"
            >
                <AgGridReact
                    gridOptions={{
                        domLayout: "normal",
                        pagination: true,
                        paginationPageSize: 50,
                        getRowId: params => params.data._id,
                        onFirstDataRendered: onFirstDataRendered
                    }}
                    defaultColDef={{ flex: 1 }}
                    columnDefs={this.colDefs}
                    rowData={this.props.list}
                    onGridReady={this.onGridReady}
                    animateRows={true}
                />
            </Box>
        );
    }
}

CustomerCostsGrid.propTypes = {
    list: PropTypes.array.isRequired,
    setGridApi: PropTypes.func
};

export default CustomerCostsGrid;
