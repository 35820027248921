// noinspection JSUnresolvedReference

/*
import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteLineItems, fetchLineItems, invalidateOrder, lineItemsDelete} from "../../actions/orderActions";
import OrderDetails from "../rewards/OrderDetails";
import PropTypes from "prop-types";
import OrderDetailCommands from "../rewards/OrderDetailCommands";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";

class MemberOrderContainer extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
    this.handleDeletePost = this.handleDeletePost.bind(this);
  }

  componentDidMount() {
    if (this.props.user) {
      if (!this.props.user.roles.includes("guardian")) {
        let centre = this.props.user._learningCentreId;
        let studentId = this.props.user._student._id;
        this.props.dispatch(invalidateOrder(centre));
        this.props.dispatch(fetchLineItems(centre, studentId));
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this should not fire if I am just updating the delete checkbox
    if (this.props.user && this.props.user !== prevProps.user) {
      const { dispatch, user } = this.props;
      let centre = user._learningCentreId;
      let studentId = user._student._id;
      dispatch(fetchLineItems(centre, studentId));
    }
  }

  handleDeleteClicked(event) {
    //let lineId = event.nativeEvent.target.value;
    console.log(event);
    this.props.dispatch(
      lineItemsDelete(
        false,
        event.target.checked,
        event.target.value
      )
    );
  }

  handleDeletePost() {
    this.props.dispatch(
      deleteLineItems(
        this.props.user._learningCentreId,
        this.props.user._student._id,
        this.props.deletes
      )
    );
  }

  render() {
    //console.log("render");

    const {orders, isFetching, orderExists } = this.props;
    //console.log(orders);
    if (!orders && this.props.orderExists) return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
    );
    return (
      <Box>
        {isFetching && JSON.stringify(orders) === JSON.stringify({}) && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
        )}
        {!isFetching && !orderExists && (
          <Box>
            <Typography variant={"h5"}>
            No Order Exists for this centre. Add items to create a new order
            </Typography>
          </Box>
        )}
        {JSON.stringify(orders) !== undefined && (
          <Grid container direction={"column"} style={{ opacity: isFetching ? 0.5 : 1 }}>
            <OrderDetailCommands deleteClick={this.handleDeletePost} />
            {orders.map((order) => {
              let fulfilledDate = "";
              if (order.dateFulfilled){
                fulfilledDate = new Date(order.dateFulfilled).toDateString();
              }
              return (
                  <Grid item > <Typography variant={"h3"}> {
                order.fulfillStatus + " : " + fulfilledDate}

                  </Typography>
              <OrderDetails
                  deletes={this.props.deletes}
                  isOpenOrder={(order.fulfillStatus === 'unfulfilled')}
                  lineItems={order.lineItems}
                  onDeleteClicked={this.handleDeleteClicked}
              />
              </Grid>
              )//end return
            })
            }
          </Grid>
        )}
      </Box>
    );
  }
}

MemberOrderContainer.propTypes = {
  centre: PropTypes.string,
  orderExists: PropTypes.bool,
  order: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { order } = state;
  const {
    isFetching,
    lastUpdated,
    orders,
    deletes,
    orderExists
  } = order || {
    isFetching: true
  };
  let user = state.ui.orderuser;
  return {
    user,
    deletes,
    orders,
    isFetching,
    lastUpdated,
    orderExists
  };
}
export default connect(mapStateToProps)(MemberOrderContainer);
*/

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteLineItems, fetchLineItems, invalidateOrder, lineItemsDelete,} from "../../actions/orderActions";
import OrderDetails from "../rewards/OrderDetails";
import PropTypes from "prop-types";
import OrderDetailCommands from "../rewards/OrderDetailCommands";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";

function MemberOrderContainer() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.orders);
  const isFetching = useSelector((state) => state.order.isFetching);
  const deletes = useSelector((state) => state.order.deletes);
  const orderExists = useSelector((state) => state.order.orderExists);
  const user = useSelector((state) => state.ui.orderuser);

  useEffect(() => {
    if (user) {
      if (!user.roles.includes("guardian")) {
        let centre = user._learningCentreId;
        let studentId = user._student._id;
        dispatch(invalidateOrder(centre));
        dispatch(fetchLineItems(centre, studentId));
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      const { user: currentUser } = user;
      let centre = currentUser._learningCentreId;
      let studentId = currentUser._student._id;
      dispatch(fetchLineItems(centre, studentId));
    }
  }, [dispatch, user]);

  const handleDeleteClicked = (event) => {
    dispatch(
        lineItemsDelete(false, event.target.checked, event.target.value)
    );
  };

  const handleDeletePost = () => {
    dispatch(
        deleteLineItems(user._learningCentreId, user._student._id, deletes)
    );
  };

  if (!orders && orderExists)
    return (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
    );

  return (
      <Box>
        {isFetching && JSON.stringify(orders) === JSON.stringify({}) && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
        )}
        {!isFetching && !orderExists && (
            <Box>
              <Typography variant={"h5"}>
                No Order Exists for this centre. Add items to create a new order
              </Typography>
            </Box>
        )}
        {JSON.stringify(orders) !== undefined && (
            <Grid
                container
                direction={"column"}
                style={{ opacity: isFetching ? 0.5 : 1 }}
            >
              <OrderDetailCommands deleteClick={handleDeletePost} />
              {orders.map((order) => {
                let fulfilledDate = "";
                if (order.dateFulfilled) {
                  fulfilledDate = new Date(order.dateFulfilled).toDateString();
                }
                return (
                    <Grid item key={order._id}>
                      <Typography variant={"h3"}>
                        {order.fulfillStatus} : {fulfilledDate}
                      </Typography>
                      <OrderDetails
                          deletes={deletes}
                          isOpenOrder={order.fulfillStatus === "unfulfilled"}
                          lineItems={order.lineItems}
                          onDeleteClicked={handleDeleteClicked}
                      />
                    </Grid>
                );
              })}
            </Grid>
        )}
      </Box>
  );
}

MemberOrderContainer.propTypes = {
  centre: PropTypes.string,
  orderExists: PropTypes.bool,
  order: PropTypes.object,
  isFetching: PropTypes.bool,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func
};

export default MemberOrderContainer;
