import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for adding a new lead
export const addNewLead = createAsyncThunk(
  "leads/addNewLead",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState(); // Get the current Redux state
    const leadData = state.leads?.newLead; //
    const response = await axios.post("/api/leads", leadData);
    return response.data;
  }
);
export const addNewNote = createAsyncThunk(
  "leads/addNewNote",
  async (_, thunkAPI) => {
    const { auth, leads } = thunkAPI.getState(); // Assuming your user's name is stored here
    let noteData = leads.newNote;
    // Set the default date if not provided
    const today = new Date().toISOString().split("T")[0];
    const date = noteData.date || today;

    // Set the createdBy using the username from state
    const createdBy = noteData.createdBy || auth.username;

    // Combine the new note data with the defaults
    const newNote = {
      ...noteData,
      date,
      createdBy,
    };
    console.log(newNote);
    const url = "/api/leads/" + leads.selectedLeadId + "/note/add";
    const response = await axios.post(url, newNote);
    return response.data;
  }
);

export const addNewContact = createAsyncThunk(
  "leads/addNewContact",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState(); // Get the current Redux state
    const contactData = state.leads?.newContact; //
    const url = "/api/leads/" + state.leads.selectedLeadId + "/contact/add";
    const response = await axios.post(url, contactData);
    return response.data;
  }
);

export const deleteContact = createAsyncThunk(
  "leads/deleteContact",
  async ({ leadId, contactId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/leads/${leadId}/contact/${contactId}`
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to delete the contact");
      }
      // Return the IDs to use in the reducer to update the state
      return { leadId, contactId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteNote = createAsyncThunk(
    "leads/deleteNote",
    async ({ leadId, noteId }, { rejectWithValue }) => {
      try {
        const response = await axios.delete(
            `/api/leads/${leadId}/note/${noteId}`
        );
        if (response.status !== 200) {
          return rejectWithValue("Failed to delete the contact");
        }
        // Return the IDs to use in the reducer to update the state
        return { leadId, noteId };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);
// Async thunk for fetching all leads
export const fetchLeads = createAsyncThunk("leads/fetchLeads", async () => {
  const response = await axios.get("/api/leads");
  return response.data;
});

// Async thunk for fetching a single lead by ID
export const fetchLeadById = createAsyncThunk(
  "leads/fetchLeadById",
  async (id) => {
    const response = await axios.get(`/api/leads/${id}`);
    return response.data;
  }
);

// Async thunk for updating an existing lead
export const updateLead = createAsyncThunk(
  "leads/updateLead",
  async (id, thunkAPI) => {
      //the object in newLead should contain the updates we want to send
      const state = thunkAPI.getState();
       const updatedLead = state.leads.newLead;
    const response = await axios.patch(`/api/leads/${id}`, updatedLead);
    return response.data;
  }
);

// Async thunk for deleting a lead
export const deleteLead = createAsyncThunk("leads/deleteLead", async (id) => {
  await axios.delete(`/api/leads/${id}`);
  return id;
});

const initialState = {
  leads: [],
  status: "idle",
  error: null,
  selectedLeadId: null,
  newContact: {
    name: null,
    email: null,
    phone: null,
    role: null,
    otherRole: null,
  },
  newNote: {
    date: null,
    note: null,
    createdBy: null,
    contactPersonsInvolved: null,
    staffContact: null,
    type: null,
  },
  newLead: {
    customerName: "",
    status: "",
    dateCreated: new Date().toISOString(), //iso date
      dateUpdated: new Date().toISOString(),
    address: {
      Address1: "",
      Address2: "",
      Address3: "",
      City: "",
      County: "",
      Country: "",
      postCode: "",
    },
    contactPersons: [],
    notes: [],
  },
};

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
      initializeEdit: (state, action) => {
          state.newLead = action.payload;
      },
    resetNewFields: (state, action) => {
      state.newLead = initialState.newLead;
      state.newContact = initialState.newContact;
      state.newNote = initialState.newNote;
    },
    updateLeadField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newLead[fieldName] = value;
      // need to update the actual stored lead as weill
    },
    updateAddressField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newLead.address[fieldName] = value;
    },
    updateNewContactField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newContact[fieldName] = value;
    },
    updateNewNoteField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.newNote[fieldName] = value;
    },
    setSelectedLeadId(state, action) {
      console.log(`setSelectedId: ${action.payload}`);
      state.selectedLeadId = action.payload;
    },
    contactUpdated(state, action) {
      const { id, field, value, lead } = action.payload;
      console.log(action.payload);
      return {
        ...state,
        leads: state.leads.map((lead) =>
          lead._id === lead._id
            ? {
                ...lead,
                contactPersons: lead.contactPersons.map((contact) =>
                  contact._id === id ? contact : contact
                ),
              }
            : lead
        ),
      };
    },
    noteUpdated(state, action) {
      const { leadId, noteId, updatedNote } = action.payload;
      console.log(action.payload);
      return {
        ...state,
        leads: state.leads.map((lead) =>
          lead._id === leadId
            ? {
                ...lead,
                notes: lead.notes.map((note) =>
                  note._id === noteId ? updatedNote : note
                ),
              }
            : lead
        ),
      };
    },
    leadUpdated(state, action) {
      const { id, field, value, lead } = action.payload;
      console.log(`lead: ${lead}`);
      console.log(action.payload);
      state.leads = state.leads.map((lead) => {
        if (lead._id === id) {
          //find the field that matches field and update the value to the value
          console.log("found lead");
          lead[field] = value;
          return lead;
        } else {
          return lead;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(deleteNote.fulfilled, (state, action) => {
          const { leadId, noteId } = action.payload;
          // Find the lead from which to remove the contact
          const leadIndex = state.leads.findIndex((lead) => lead._id === leadId);
          if (leadIndex !== -1) {
            // Remove the contact by filtering out the contact with the specified _id
            state.leads[leadIndex].notes = state.leads[
                leadIndex
                ].notes.filter((note) => note._id !== noteId);
          }
        })
        .addCase(deleteNote.rejected, (state, action) => {
          // Handle any errors, such as showing an error message
          console.error("Failed to delete note:", action.payload);
          state.status = "failed"
        })
      .addCase(deleteContact.fulfilled, (state, action) => {
        const { leadId, contactId } = action.payload;
        // Find the lead from which to remove the contact
        const leadIndex = state.leads.findIndex((lead) => lead._id === leadId);
        if (leadIndex !== -1) {
          // Remove the contact by filtering out the contact with the specified _id
          state.leads[leadIndex].contactPersons = state.leads[
            leadIndex
          ].contactPersons.filter((contact) => contact._id !== contactId);
        }
      })
      .addCase(deleteContact.rejected, (state, action) => {
        // Handle any errors, such as showing an error message
        console.error("Failed to delete contact:", action.payload);
        state.status = "failed"
      })
      .addCase(fetchLeads.pending, (state) => {
        state.status = "loading";
        state.error = null; // Reset the error state here
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.leads = action.payload;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLeadById.fulfilled, (state, action) => {
        const index = state.leads.findIndex(
          (lead) => lead._id === action.payload._id
        );
        if (index !== -1) {
          state.leads[index] = action.payload;
        } else {
          state.leads.push(action.payload);
        }
      })
      .addCase(addNewLead.fulfilled, (state, action) => {
        state.status = "success";
        state.leads.push(action.payload);
      })
      .addCase(addNewContact.fulfilled, (state, action) => {
        state.status = "success";
        const newContact = action.payload.newContact;
        const leadId = state.selectedLeadId;
        // Find the lead to which the new contact belongs
        const leadIndex = state.leads.findIndex((lead) => lead._id === leadId);
        if (leadIndex !== -1) {
          // Add the new contact to the lead's contactPersons array
          state.leads[leadIndex].contactPersons = action.payload;
        }
      })
      .addCase(addNewContact.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(addNewNote.fulfilled, (state, action) => {
        state.status = "success";
        const newNote = action.payload.newNote;
        const leadId = state.selectedLeadId;
        // Find the lead to which the new contact belongs
        const leadIndex = state.leads.findIndex((lead) => lead._id === leadId);
        if (leadIndex !== -1) {
          // Add the new contact to the lead's contactPersons array
          state.leads[leadIndex].notes = action.payload;
        }
      })
      .addCase(addNewNote.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.status = "success";
        const index = state.leads.findIndex(
          (lead) => lead._id === action.payload._id
        );
        if (index !== -1) {
          state.leads[index] = action.payload;
        }
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.status = "success";
        state.leads = state.leads.filter((lead) => lead._id !== action.payload);
      })
        .addCase(deleteLead.rejected, (state, action) => {
            state.status = "failed";

        });
  },
});
export const {
    initializeEdit,
  contactUpdated,
  noteUpdated,
  leadUpdated,
  setSelectedLeadId,
  updateAddressField,
  updateLeadField,
  updateNewContactField,
  updateNewNoteField,
  resetNewFields,
} = leadsSlice.actions;
export default leadsSlice.reducer;
