import {
    CB_CHARGES_MODAL,
    CB_CONTRACT_CHARGE_CHANGED,
    CB_CONTRACT_CHARGE_FORM_ADD,
    CB_CONTRACT_CHARGE_FORM_CHANGED,
    CB_CONTRACT_COST_CHANGED,
    CB_CONTRACT_COST_FORM_ADD,
    CB_CONTRACT_COST_FORM_CHANGED,
    CB_CONTRACT_MODAL,
    CB_CONTRACT_SUMMARY_CHANGED,
    CB_CONTRACT_SUMMARY_FORM_CHANGED,
    CB_COST_TYPE_CHANGED,
    CB_COSTS_MODAL,
    CB_CUSTOMER_CHANGED,
    CB_CUSTOMER_FORM_CHANGED,
    CB_CUSTOMER_MODAL,
    CB_ERROR,
    CB_M_GRID_EDIT_MODE,
    CB_RESET_CONTRACT_EDIT_FLAG,
    CREATED_CB_INVOICE,
    CREATED_MOVEMENT,
    DELETED_CB_INVOICE,
    E_CREATE_MOVEMENT,
    NEW_CB_COST_CHANGE,
    NEW_CB_MOVEMENT_CHANGE,
    NEW_CB_TASK_CHANGE,
    RECEIVED_CB_CONTRACT,
    RECEIVED_CB_CONTRACT_COSTS,
    RECEIVED_CB_COSTS,
    RECEIVED_CB_CUSTOMERS,
    RECEIVED_CB_INVOICES,
    RECEIVED_CB_MOVEMENTS,
    RECEIVED_CB_TASKS,
    RECEIVED_CB_TOTALS,
    REQUEST_CB_TOTALS,
    RESET_CB_CONTRACT,
    RESET_CB_COST,
    RESET_CB_CUSTOMER,
    RESET_CB_ERROR,
    RESET_CB_LISTS,
    RESET_CB_MOVEMENT,
    RESET_CB_TASK,
    SELECTED_CB_CUSTOMER,
    UPDATED_MOVEMENT,
} from "../actions/cbuserActions";

import _ from "lodash";

function cbuser(state = {status: "success", totals: []}, action) {

  switch (action.type) {
    case REQUEST_CB_TOTALS:
      return {
        ...state,
        totals: null,
        status: "loading",
      };
    case RESET_CB_LISTS:
      return {
        ...state,
        movements: null,
        tasks: null,
        costs: null,
      };
    case CB_M_GRID_EDIT_MODE:
      return {
        ...state,
        editMovement: action.payload,
      };

    case UPDATED_MOVEMENT:
      //copy the values from the ui state into the right centre (saves us retrieving the whole list)
      const movement = action.payload;
      let movements = state.movements.map((line) => {
        if (line._id === movement._id) {
          return Object.assign({}, line, movement);
        } else {
          return line;
        }
      });
      return Object.assign({}, state, { movements: movements });
    case CB_CHARGES_MODAL:
      return {
        ...state,
        chargesModal: action.payload,
      };
    case CB_COSTS_MODAL:
      return {
        ...state,
        costsModal: action.payload,
      };
    case CB_CONTRACT_MODAL:
      return {
        ...state,
        contractModal: action.payload,
      };
    case CB_CUSTOMER_MODAL:
      return {
        ...state,
        customerModal: action.payload,
      };
    case CB_CONTRACT_CHARGE_FORM_ADD:
      // move the newChargeData into the charge array for the contract
      return {
        ...state,
        newChargeData: null,
        contractEdited: true,
        contract: {
          ...state.contract,
          charges: [...state.contract.charges, action.payload],
        },
      };
    case CB_CONTRACT_COST_FORM_ADD:
      // move the newCostData into the charge array for the contract
      return {
        ...state,
        newCostData: null,
        contractEdited: true,
        contract: {
          ...state.contract,
          costs: [...state.contract.costs, action.payload],
        },
      };
    case CB_CONTRACT_SUMMARY_CHANGED:
      /*
 at this time we know we only have one contract object in memory even though we are using a grid
 if we end up with multiple a lot will have to change anyway
      */
      let clone = _.cloneDeep(state.contract);
      return {
        ...state,
        contractEdited: true,
        contract: {
          ...clone,
          [action.payload.field]: action.payload.value,
        },
      };
    case CB_CONTRACT_CHARGE_CHANGED:
      //we know this is a charge to get that array
      const charge = state.contract.charges.find(
        (charge) => charge._id === action.payload._id
      );

      return {
        ...state,
        contractEdited: true,
        contract: {
          ...state.contract,
          charges: [
            ...state.contract.charges.filter(
              (charge) => action.payload._id !== charge._id
            ),
            { ...charge, [action.payload.field]: action.payload.value },
          ],
        },
      };
    case CB_CONTRACT_COST_CHANGED:
      //we know this is a charge to get that array
      const cost = state.contract.costs.find(
        (cost) => cost._id === action.payload._id
      );

      return {
        ...state,
        contractEdited: true,
        contract: {
          ...state.contract,
          costs: [
            ...state.contract.costs.filter(
              (cost) => action.payload._id !== cost._id
            ),
            { ...cost, [action.payload.field]: action.payload.value },
          ],
        },
      };
    case CB_RESET_CONTRACT_EDIT_FLAG:
      return {
        ...state,
        contractEdited: false,
      };
    case CB_CUSTOMER_CHANGED:
      /*
           we are going to change some data in the array of customers. it will be a single field but it could
           be part of the address which is a nested object
            */
      //console.log(action.payload);
      let customer = state.customers.find(
        (customer) => customer._id === action.payload._id
      );
      //console.log(customer);
      //is the item already in the list if not add it
      let idInList = false;
      let idList = [];
      if (state.editedCustomers) {
        idList = state.editedCustomers.slice();
        idInList = state.editedCustomers.indexOf(action.payload._id) > -1;
      }
      if (!idInList) {
        idList.push(action.payload._id);
      }
      if (!action.payload.field.includes("address.")) {
        return {
          ...state,
          editedCustomers: idList,
          customers: [
            ...state.customers.filter(
              (customer) => action.payload._id !== customer._id
            ),
            { ...customer, [action.payload.field]: action.payload.value },
          ],
        };
      } else {
        let clone = _.cloneDeep(customer);
        clone.address[[action.payload.field.slice(8)]] = action.payload.value;
        //console.log(JSON.stringify(clone));
        return {
          ...state,
          editedCustomers: idList,
          customers: [
            ...state.customers.filter(
              (customer) => action.payload._id !== customer._id
            ),
            { ...clone },
          ],
        };
      }
    case DELETED_CB_INVOICE:
      //in this case we want to remove the item from the list of invoice - saves us retrieving again
      //find the =index of the item to remove
      let newInvoices = state.invoices.filter((invoice) => {
        return invoice._id !== action.payload;
      });
      //console.log(newInvoices);
      return {
        ...state,
        invoices: newInvoices,
      };
    case RECEIVED_CB_TOTALS:
      return {
        ...state,
        totals: action.payload,
        status: "success",
      };
    case RECEIVED_CB_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case CREATED_CB_INVOICE:
      return {
        ...state,
        createdInvoice: action.payload,
      };
    case RESET_CB_ERROR:
      return {
        ...state,
        error: null,
      };
    case CB_ERROR:
      //console.log(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case E_CREATE_MOVEMENT:
      return {
        ...state,
        error: action.payload,
      };
    case CREATED_MOVEMENT:
      return {
        ...state,
        test: action.payload,
      };
    case RECEIVED_CB_COSTS:
      return Object.assign({}, state, {
        costs: action.payload,
      });
    case RECEIVED_CB_TASKS:
      return Object.assign({}, state, {
        tasks: action.payload,
      });
    case RECEIVED_CB_MOVEMENTS:
      return Object.assign({}, state, {
        movements: action.payload,
      });
    case RECEIVED_CB_CONTRACT_COSTS:
      return Object.assign({}, state, {
        contractCosts: action.payload,
      });
    case CB_COST_TYPE_CHANGED:
      return Object.assign({}, state, {
        costType: action.payload,
      });
    case CB_CUSTOMER_FORM_CHANGED:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          newCustomerData: {
            ...state.newCustomerData,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          newCustomerData: {
            ...state.newCustomerData,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    case CB_CONTRACT_SUMMARY_FORM_CHANGED:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          newContractData: {
            ...state.newContractData,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          newContractData: {
            ...state.newContractData,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    case CB_CONTRACT_CHARGE_FORM_CHANGED:
      //this is actually adding a new row to an array in the current contract.
      return {
        ...state,
        newChargeData: {
          ...state.newChargeData,
          [action.payload.field]: action.payload.value,
        },
      };
    case CB_CONTRACT_COST_FORM_CHANGED:
      //this is actually adding a new row to an array in the current contract.
      return {
        ...state,
        newCostData: {
          ...state.newCostData,
          [action.payload.field]: action.payload.value,
        },
      };
    case NEW_CB_COST_CHANGE:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          newCostData: {
            ...state.newCostData,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          newCostData: {
            ...state.newCostData,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    case NEW_CB_TASK_CHANGE:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          newTaskData: {
            ...state.newTaskData,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          newTaskData: {
            ...state.newTaskData,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    case NEW_CB_MOVEMENT_CHANGE:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          newMovementData: {
            ...state.newMovementData,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          newMovementData: {
            ...state.newMovementData,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    case RECEIVED_CB_CUSTOMERS:
      return Object.assign({}, state, {
        customers: action.payload,
        editedCustomers: null,
      });
    case RESET_CB_COST:
      return Object.assign({}, state, { newCostData: null }); //clear changed
    case RESET_CB_TASK:
      return Object.assign({}, state, { newTaskData: null }); //clear changed
    case RESET_CB_MOVEMENT:
      return Object.assign({}, state, { newMovementData: null }); //clear changed
    case RESET_CB_CUSTOMER:
      return {
        ...state,
        newCustomerData: null,
      };
    case RESET_CB_CONTRACT:
      return {
        ...state,
        newContractData: null,
        newChargeData: null,
        newCostData: null,
      };
    case SELECTED_CB_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomerId: action.payload,
      });
    case RECEIVED_CB_CONTRACT:
      let resetValue = {};
      resetValue["customerId"] = action.payload.customerId;
      return {
        ...state,
        contract: action.payload,
        newContractData: null,
        costType: "",
      };
    default:
      return state;
  }
}

export default cbuser;
