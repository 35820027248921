import axios from "axios";
import axiosRetry from "axios-retry";
import {closeSnackbar, enqueueSnackbar as enqueueSnackbarAction} from "./snackActions";
import {resetStore} from "./index";
import React, {Fragment} from "react";
import Button from "@mui/material/Button";

axiosRetry(axios, { retries: 0 });



/*
 * action creators
 */
export const REQUEST_CABINET = "request_cabinet";
function requestCabinet(centre) {
  return {
    type: REQUEST_CABINET,
    centre
  };
}

export const RECEIVE_CABINET = "receive_cabinet";
function receiveCabinet(centre, json) {
  //console.log(centre,json);
  return {
    type: RECEIVE_CABINET,
    centre,
    payload: json,
    receivedAt: Date.now()
  };
}

export const RECEIVE_CABINET_EMPTY = "receive_cabinet_empty";
function receiveCabinetEmpty(centre,json){
  return {
    type:RECEIVE_CABINET_EMPTY,
    centre,
    payload:json,
    receivedAt: Date.now()
  }
}

export const INVALIDATE_CABINET = "invalidate_cabinet";
export function invalidateCabinet(centre) {
  return {
    type: INVALIDATE_CABINET,
    centre
  };
}
export const FILTER_FAV = "filter_fav";

export function filterCabinet(filterSwitch) {
  return {
    type: FILTER_FAV,
    filterSwitch: filterSwitch
  };
}

/*
 * Async type actions (API calls)
 */

export const fetchCabinet = (centre, summary) => async dispatch => {
  let url = "/api/cabinet";
  let options = {
    params: {
      centre: centre,
      summary: summary
    }
  };
  dispatch(requestCabinet(centre)); //update state to say we are fetching cabinet
  try {
    const res = await axios.get(url, options);
    //console.log("response data: ", res.data);
    dispatch(receiveCabinet(centre, res.data));
  } catch (e){
    console.log(e);
    dispatch(receiveCabinetEmpty(centre, {} ));
    notifyError(e, dispatch);
    dispatch(resetStore());
    await checkFor401(e);
  }
};

async function checkFor401(error) {
  console.log(error.response);
  if (error.response.status === 401) {
    await axios.get("/api/logout");
  }
}

function notifyError(dispatch, e) {
  dispatch(
      enqueueSnackbarAction({
        message: " the reason given was " + e.message,
        options: {
          variant: "error",
          autoHideDuration: null,
          action: (key) => (
              <Fragment>
                <Button
                    size="small"
                    onClick={() => {
                      dispatch(closeSnackbar(key));
                    }}
                >
                  Dismiss
                </Button>
              </Fragment>
          ),
        },
      })
  );
}
