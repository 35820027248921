import React, {Component} from "react";
import {Box, Button, Card, CardContent, CircularProgress, Grid, Tab, Tabs,} from "@mui/material";
import PropTypes from "prop-types";
import ProductGrid from "../cbinventory/ProductGrid";
import Typography from "@mui/material/Typography";
import OrderGridComponent from "../cbuser/OrderGridComponent";
import DateSearch from "../search/DateSearch";
import CustomerTasksGrid from "./CustomerTasksGrid";
import CustomerCostsGrid from "./CustomerCostsGrid";
import MovementsDashboard from "./MovementsDashboard";
import CreateOrderContainer from "../containers-customer/CreateOrderContainer";
import CustomerInvoiceGrid from "./CustomerInvoiceGrid";

class CustomerDashboard extends Component {
  state = {
    selectedTab: 0,
    tasksGridApi: null, // 1. Keeping track of Tasks Grid API
    costsGridApi: null, // 1. Keeping track of Costs Grid API
    inventoryGridApi: null, // 1. Keeping track of Inventory Grid API
  };
  setTasksGridApi = (api) => {
    // 2. Initialize the Tasks Grid API
    this.setState({ tasksGridApi: api });
  };

  setCostsGridApi = (api) => {
    // 2. Initialize the Costs Grid API
    this.setState({ costsGridApi: api });
  };

  setInventoryGridApi = (api) => {
    // 2. Initialize the Inventory Grid API
    this.setState({ inventoryGridApi: api });
  };

  handleTabChange = (event, newValue) => {
    const { fetchOrders } = this.props;
    this.setState({ selectedTab: newValue });
    if (newValue === 1) {
      fetchOrders();
    }
  };
  exportTasksToCSV = () => {
    // 3. Export to CSV for tasks
    const { tasksGridApi } = this.state;
    if (tasksGridApi) {
      const params = {
        fileName: "tasks.csv",
      };
      tasksGridApi.exportDataAsCsv(params);
    }
  };

  exportCostsToCSV = () => {
    // 3. Export to CSV for costs
    const { costsGridApi } = this.state;
    if (costsGridApi) {
      const params = {
        fileName: "costs.csv",
      };
      costsGridApi.exportDataAsCsv(params);
    }
  };

  exportInventoryToCSV = () => {
    // 3. Export to CSV for costs
    const { inventoryGridApi } = this.state;
    if (inventoryGridApi) {
      const params = {
        fileName: "inventory.csv",
      };
      inventoryGridApi.exportDataAsCsv(params);
    }
  };
  render() {
    const { selectedTab } = this.state;
    const { inventoryList, orderList } = this.props;

    return (
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Inventory" />
            <Tab label="Orders" />
            <Tab label={"Movements"} />
            <Tab label={"Tasks"} />
            <Tab label={"Costs"} />
            <Tab label={"Create Order"} />
            <Tab label={"Invoices"} />
          </Tabs>
        </Grid>
        {selectedTab === 0 && (
          <Grid item xs={12}>
            <Typography variant={"h4"} align={"center"}>
              Inventory
            </Typography>
            {inventoryList ? (
              <ProductGrid
                showActions={false}
                list={inventoryList}
                setGridApiRef={this.setInventoryGridApi}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        )}
        {selectedTab === 1 && (
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant={"h4"} align={"center"}>
                  Orders
                </Typography>
                {orderList ? (
                  <OrderGridComponent showActions={false} orders={orderList} />
                ) : (
                  <CircularProgress />
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
        {selectedTab === 2 && (
          <Grid item>
            <Card>
              <CardContent>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <DateSearch searchFunction={this.props.fetchMovements} />
                  </Grid>
                  <Grid item>
                    <MovementsDashboard list={this.props.movementList} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {selectedTab === 3 && (
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant={"h4"} align={"center"}>
                  Tasks
                </Typography>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <DateSearch searchFunction={this.props.fetchTasks} />
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
                      {
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.exportTasksToCSV}
                        >
                          Export to CSV
                        </Button>
                      }
                    </Box>
                  </Grid>
                  <Grid item>
                    <CustomerTasksGrid
                      list={this.props.taskList}
                      setGridApi={this.setTasksGridApi}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {selectedTab === 4 && (
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant={"h4"} align={"center"}>
                  Costs
                </Typography>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <DateSearch searchFunction={this.props.fetchCosts} />
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
                      {
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.exportCostsToCSV}
                        >
                          Export to CSV
                        </Button>
                      }
                    </Box>
                  </Grid>
                  <Grid item>
                    <CustomerCostsGrid
                      list={this.props.costList}
                      setGridApi={this.setCostsGridApi}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {selectedTab === 5 && (
          <Grid item>
            <Card>
              <CardContent>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <CreateOrderContainer />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {selectedTab === 6 && (
            <Grid item>
              <Card>
                <CardContent>
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <CustomerInvoiceGrid list={this.props.invoiceList} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
        )}
      </Grid>
    );
  }
}

CustomerDashboard.propTypes = {
  inventoryList: PropTypes.array,
  invoiceList: PropTypes.array,
  orderList: PropTypes.array,
  movementList: PropTypes.array,
  taskList: PropTypes.array,
  costList: PropTypes.array,
  fetchMovements: PropTypes.func,
  fetchTasks: PropTypes.func,
  fetchCosts: PropTypes.func,
  fetchOrders: PropTypes.func,
  fetchInventory: PropTypes.func,
  fetchInvoices: PropTypes.func,
};

export default CustomerDashboard;
