import {
  BATCH_UPDATE_FAILURE,
  BATCH_UPDATE_REQUEST,
  CLEAR_PRODUCT_CHANGES,
  CREATE_INVENTORY,
  CREATE_RETURN,
  EDIT_PRODUCT,
  FETCH_INVENTORY,
  FETCH_INVENTORY_TX_SUMMARY,
  RESET_INVENTORY,
  TOGGLE_RETURNS_MODE,
  TOGGLE_STOCK_TAKE_MODE,
  UPDATE_INVENTORY,
} from "../actions/cbinventoryActions";

const initialState = {
  data: { products: [] },
  loading: false,
  error: null,
  editingProduct: null,
  productChanges: [],
  summary: [],
  updatedProducts: []
};

// In your inventoryReducer.js or a selectors.js file
export const selectProductById = (state, productId) => {
  let foundProduct = null;
  try {
    foundProduct = state.summary.find((product) => {
      return product.productId === productId;
    });
  } catch (error) {
    console.log("Error: ", error);
  }

  return foundProduct;
};

export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_INVENTORY:
      return initialState;
    case `${FETCH_INVENTORY}_REQUEST`:
      return { ...state, loading: true, error: null };
    case `${FETCH_INVENTORY_TX_SUMMARY}_REQUEST`:
      return { ...state, loading: true, error: null };
    case EDIT_PRODUCT:
      console.log("edit_product");
      // Locate the product within inventory.data.products
      const productIndex = state.data.products.findIndex(
          (p) => p.productId === action.payload.productId
      );

      if (productIndex >= 0) {
        // Update the specific field of the product
        const updatedProduct = {
          ...state.data.products[productIndex],
          [action.payload.fieldId]: action.payload.value,
          ["updatedDate"]: new Date().toISOString(),
        };
        console.log("updated product", updatedProduct);
        // Check if the updated product is already in the updatedProducts array
        const isProductUpdatedAlready = state.updatedProducts.some(
            (p) => p.productId === action.payload.productId
        );
        console.log("is productUpdatedAlready", isProductUpdatedAlready);
        // Replace the product within inventory.data.products with the updated product
        // and add the product to the updatedProducts array if it's not already there
        return {
          ...state,
          data: {
            ...state.data,
            products: [
              ...state.data.products.slice(0, productIndex),
              updatedProduct,
              ...state.data.products.slice(productIndex + 1),
            ],
          },
          updatedProducts: isProductUpdatedAlready
              ? state.updatedProducts
              : [...state.updatedProducts, updatedProduct],
        };
      } else {
        // If the product isn't found, handle accordingly (return state unchanged here)
        return state;
      }


    case BATCH_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BATCH_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_PRODUCT_CHANGES:
      return {
        ...state,
        productChanges: [],
      };
    case `${UPDATE_INVENTORY}_REQUEST`:
      return { ...state, loading: true, error: null };
    case `${CREATE_INVENTORY}_REQUEST`:
      return { ...state, loading: true, error: null };
    case `${FETCH_INVENTORY}_SUCCESS`:
      //console.log(action.payload);
      return { ...state, loading: false, data: action.payload, error: null };
    case `${FETCH_INVENTORY_TX_SUMMARY}_SUCCESS`:
      return { ...state, loading: false, summary: action.payload, error: null };
    case `${UPDATE_INVENTORY}_SUCCESS`:
      return { data: action.payload, loading: false, error: null };
    case `${CREATE_INVENTORY}_SUCCESS`:
      console.log("create inventory success");
      return {
        data: [...state.data, action.payload],
        loading: false,
        error: null,
      };
    case `${FETCH_INVENTORY}_FAILURE`:
      return { ...state, loading: false, error: action.payload };
    case `${UPDATE_INVENTORY}_FAILURE`:
    case `${CREATE_INVENTORY}_FAILURE`:
      return { ...state, loading: false, error: action.payload };
    case TOGGLE_STOCK_TAKE_MODE:
      return {
        ...state,
        stockTakeMode: !state.stockTakeMode,
      };
    case TOGGLE_RETURNS_MODE:
      return {
        ...state,
        returnsMode: !state.returnsMode,
        returnData: null,
        returnsModeProduct: action.payload,
      };
    case CREATE_RETURN:
      return {
        ...state,
        returnData: action.payload,
      };
    default:
      return state;
  }
};
