// noinspection JSUnresolvedReference

import {SET_ORDER_USER, SET_PROGRESS_BAR, SET_STUDENT_PAGES} from "../actions/types";
import {
  CB_TOTAL_ACTIVE_SWITCHED,
  CB_TOTAL_PARAM_SELECTED,
  CENTRE_CHANGED,
  CENTRE_CLEAR,
  CENTRE_SORT_REQUESTED,
  CLONE_DRAFT_ORDER,
  DRAFT_ORDER_RESET,
  DRAFT_ORDER_SELECTED,
  MASTER_ORDER_SELECTED,
  MOVEMENT_CLEAR,
  MOVEMENT_GRID_CHANGED,
  NEW_DRAFT,
  NEW_DRAFT_CHANGE,
  NEW_DRAFT_LINE_CHANGE,
  OPEN_CREDENTIALS_FORM,
  RELATED_USER_SELECTED,
  SAVE_DRAFT,
  SEARCH,
  VARIANT_SELECTED,
    RESET_SEARCH
} from "../actions/UIActions";
import {GLR_ERROR, GLR_FEEDBACK, PROGRESS_OFF, PROGRESS_ON} from "../actions";

function updateObjectInArray(array, action) {
  return array.map((item) => {
    //console.log(item);
    if (item.variant_id !== action.payload.variant_id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.payload,
    };
  });
}


export const ui = (state = {}, action) => {
  switch (action.type) {
    case RESET_SEARCH:
      return {
        ...state,
        searchParams: null
      }
    case CB_TOTAL_ACTIVE_SWITCHED:
      return {
        ...state,
        cbActiveFlag: action.payload
      }
    case CB_TOTAL_PARAM_SELECTED:
      return {
        ...state,
        cbTotalPeriod: action.payload
      }
    case VARIANT_SELECTED:
      return {
        ...state,
        variant: action.payload,
      };
    case PROGRESS_ON:
      return {
        ...state,
        isFetching: true,
      };
    case PROGRESS_OFF:
      return {
        ...state,
        isFetching: false,
      };
    case GLR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GLR_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
      };
    case OPEN_CREDENTIALS_FORM:
      return Object.assign({}, state, { searchParams: null });
    case DRAFT_ORDER_SELECTED:
      // trying to reuse the same components and state as we use for newDraft
      // so I have to simplify the structure
      let newDraft = Object.assign({}, action.payload.draft, {
        line_items: action.payload.draft.shopifyDraftOrder.line_Items,
      });
      return {
        ...state,
        selectedDraft: action.payload.id,
        editDraft: true,
        newDraft: false,
        newDraftData: newDraft,
      };
    case MOVEMENT_GRID_CHANGED:
      return{
        ...state,
        movementChanged:{
          ...state.movementChanged,
          _id: action.payload._id,
          [action.payload.fieldId]: action.payload.value
        }
      }
    case MOVEMENT_CLEAR:
      return Object.assign({}, state, { movementChanged: null}); //clear changed
    case CENTRE_CHANGED:
      return {
        ...state,
        centreChanged: {
          ...state.centreChanged,
          _id: action.payload._id,
          [action.payload.fieldId]: action.payload.value,
        },
      };
    case CENTRE_CLEAR:
      //console.log("centre clear");

      return Object.assign({}, state, { centreChanged: null, editModal:false }); //clear changed

    case CENTRE_SORT_REQUESTED:
      if (action.payload === state.centreSortField) {
        return Object.assign({}, state, { sameField: true });
      } else {
        return Object.assign({}, state, {
          centreSortField: action.payload,
          sameField: false,
        });
      }
    case CLONE_DRAFT_ORDER:
      return Object.assign({}, state, {
        cloneDraft: true,
        selectedDraft: action.payload,
      });
    case NEW_DRAFT:
      return Object.assign({}, state, {
        newDraft: true,
        editDraft: false,
        newDraftData: { line_items: [] },
      });
    case SAVE_DRAFT:
      return Object.assign({}, state, { newDraft: false, editDraft: false });
    case DRAFT_ORDER_RESET:
      return Object.assign({},state, {cloneDraft: false, editDraft:false, newDraft:false});
    case SET_ORDER_USER:
      //console.log("SET_ORDER_USER: reducer: " + action.payload);
      return {
        ...state,
        ...state.ui,
        orderuser: action.payload,
      };
    case SET_PROGRESS_BAR:
      return Object.assign({}, state, { progressBarStatus: action.isOpen });
    case SET_STUDENT_PAGES:
      return Object.assign({}, state, action.payload);
    case RELATED_USER_SELECTED:
      //console.log(action.payload);
      return Object.assign({}, state, {
        orderuser: action.payload.member,
        selectedmember: action.payload.userId,
      });
    case MASTER_ORDER_SELECTED:
      //console.log("UIReducer: MASTER_ORDER_SELECTED: " + action.payload);
      return Object.assign({}, state, action.payload);
    case SEARCH:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    case NEW_DRAFT_LINE_CHANGE:
      //console.log(action.payload);
      let quantity = parseInt(action.payload.quantity);
      if (isNaN(action.payload.quantity)) {
        console.log("quantity was NaN");
        quantity = null;
      }
      //if the quantity is 0 then we need to remove the line
      //if the quantity is anything else we need to find the line and change it
      //or we need to add this as new line

      if (quantity > 0 || quantity === null) {
        let index = state.newDraftData.line_items.findIndex((item) => {
          //console.log(`item: ${JSON.stringify(item)}`);
          return item.variant_id === action.payload.variant_id;
        });
        //console.log(`index ${index}`);
        if (index === -1) {
          //new item so create a new array with the item added
          //we need to get the product data
          return Object.assign({}, state, {
            ...state,
            newDraftData: {
              ...state.newDraftData,
              line_items: [...state.newDraftData.line_items, action.payload],
            },
          });
        } else {
          return Object.assign({}, state, {
            ...state,
            newDraftData: {
              ...state.newDraftData,
              line_items: updateObjectInArray(
                state.newDraftData.line_items,
                action
              ),
            },
          });
        }
      } else if (quantity === 0) {
        // remove the line
        let index = state.newDraftData.line_items.findIndex((item) => {
          //console.log(`item.variant_id: ${item.variant_id} payload: ${action.payload.variant_id}`);
          return item.variant_id === action.payload.variant_id;
        });
        //console.log(`index:${index}`);
        if (index > -1) {
          return Object.assign({}, state, {
            ...state,
            newDraftData: {
              ...state.newDraftData,
              line_items: [
                ...state.newDraftData.line_items.slice(0, index),
                ...state.newDraftData.line_items.slice(index + 1),
              ],
            },
          });
        } else {
          return state;
        }
      }
      return state;

    case NEW_DRAFT_CHANGE:
      //console.log(action.payload);
      if (action.payload.value === "") {
        //we dont want the attribute
        return {
          ...state,
          newDraftData: {
            ...state.newDraftData,
            [action.payload.field]: null,
          },
        };
      } else {
        return {
          ...state,
          newDraftData: {
            ...state.newDraftData,
            [action.payload.field]: action.payload.value,
          },
        };
      }
    default:
      return state;
  }
};
export default ui;
