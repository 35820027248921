import React, {createContext, Fragment} from "react";
import io from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";

import {updateChatLog} from "../actions/chatActions";
import {closeSnackbar, enqueueSnackbar} from "../actions/snackActions";
import Button from "@mui/material/Button";
import {addAdminEvent} from "../reducers/adminEventsSlice";
import {setSocketStatus, updateOrdersFromWebSocket,} from "../reducers/rmSlice";

const WebSocketContext = createContext(null);
export { WebSocketContext };

const MessagingComponent = ({ children }) => {
  let socket;
  let ws;

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const sendMessage = (roomId, message) => {
    const payload = {
      roomId: roomId,
      data: message,
    };
    ws.socket.emit("event://send-message", JSON.stringify(payload));
    dispatch(updateChatLog(payload));
  };
  const handleEventNotification = (msg, duration = 4000) => {
    //we want to enqueue a snackbar message here that says the status of the job

    if (msg.status === "error") {
      dispatch(
          enqueueSnackbar({
            message: msg.text,
            options: {
              variant: "error",
              autoHideDuration: duration,
              action: (key) => (
                  <Fragment>
                    <Button
                        size="small"
                        onClick={() => {
                          dispatch(closeSnackbar(key));
                        }}
                    >
                      Dismiss
                    </Button>
                  </Fragment>
              ),
            },
          })
      );
    } else {
      dispatch(
          enqueueSnackbar({
            message: msg.text,
            options: { variant: msg.status, autoHideDuration: duration },
          })
      );
    }
  };
  if (!socket) {
    const transports = process.env.REACT_APP_SOCKET_PROTOCOLS.split("|");
    socket = io({
      transports: transports,
      auth: auth,
      autoConnect: false,
    });
    socket.connect();
    console.log(
      `connected socket in websockets...setting up message event listener`
    );

    ws = {
      socket: socket,
      sendMessage,
    };
  }
  socket.on("event://get-message", (msg) => {
    const payload = JSON.parse(msg);
    dispatch(updateChatLog(payload));
  });

  socket.on("cb.rm.movements.status", (msg) => {
    console.log("received status message from cb.rm.movements.status", msg);
    handleEventNotification(msg);
  });
  socket.on("cb.rm.fetch.orders.status", (msg) => {
    //console.log("received status message from cb.rm.fetch.orders", msg);

    handleEventNotification(msg);
  });

  socket.on("cb.rm.fetch.orders.results", (msg) => {
    //the message is a result set that was passed using JSON.stringify
    //need to turn this back into an object
    //console.log("received results message from cb.rm.fetch.orders", msg);
    dispatch(updateOrdersFromWebSocket(msg.data));
  });
  socket.on("cb.customer.post.orders.status", (msg) => {
    handleEventNotification(msg);
  });
  socket.on("glr.glradmin", (msg) => {
    console.log("received status message from glr.glradmin", msg);
    dispatch(addAdminEvent(msg.event));
    handleEventNotification(msg.event, 10000);
    if (socket) {
      //socket.emit('glr.glradmin.ack', {msgId: msg.id,userId: auth.user._id});
      console.log("emitting glr.glradmin.ack", {
        id: msg.id,
        userId: auth._id,
      });
      if (msg.userId !== auth._id) {
        // WE NEED TO ACK THIS
        socket.emit("glr.glradmin.ack", { userId: auth._id, id: msg.id });
      }
    }
  });

  socket.on("glr.glrexplore.status", (msg) => {
    //console.log("received status message from glr.glrexplore.status", msg);
    let template = msg.job + " has returned a status of " + msg.status;
    if (msg.status === "failed") {
      dispatch(
          enqueueSnackbar({
            message: template + " the reason given was " + msg.reason,
            options: {
              variant: "error",
              autoHideDuration: null,
              action: (key) => (
                  <Fragment>
                    <Button
                        size="small"
                        onClick={() => {
                          dispatch(closeSnackbar(key));
                        }}
                    >
                      Dismiss
                    </Button>
                  </Fragment>
              ),
            },
          })
      );
    }
    if (
        msg.status === "info" ||
        msg.status === "started" ||
        msg.status === "completed"
    ) {
      dispatch(
          enqueueSnackbar({ message: template, options: { variant: "info" } })
      );
    }
  });
  socket.on("cb.rm.sync.orders.status", (msg) => {
    dispatch(setSocketStatus(msg.status));
    handleEventNotification(msg);
  });
  // noinspection JSXUnresolvedComponent
  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default MessagingComponent;
