import axios from "axios";
import {centreClear} from "./UIActions";
import axiosRetry from "axios-retry";

export const FETCH_CENTRES = 'FETCH_CENTRES';
export const RECEIVED_CENTRES = 'RECEIVED_CENTRES';
export const LINK_USER_TO_CENTRE = 'LINK_USER_TO_CENTRE';
export const POST_CENTRE_START = "POST_CENTRE_START";
export const POST_CENTRE_DONE = "POST_CENTRE_DONE";
export const CENTRES_SORTED = "CENTRES_SORTED";
export const CENTRE_DRAFT_ORDER_CLONED = "CENTRE_DRAFT_ORDER_CLONED";
export const CENTRE_MODAL_FLAG_CHANGED = "CENTRE_MODAL_FLAG_CHANGED";

axiosRetry(axios, { retries: 0 });

function centresSorted(centres){
    return{
        type: CENTRES_SORTED,
        payload: centres
    }
}
function postCentreStart(){
    return {
        type: POST_CENTRE_START,
        payload: null
    }
}

function postCentreDone(centre){
    return {
        type: POST_CENTRE_DONE,
        payload: centre
    }
}
export function fetchCentres() {
    return {
        type: FETCH_CENTRES,
        payload: null
    };
}


export function receivedCentres(centres){
    return{
        type: RECEIVED_CENTRES,
        payload: centres
    }

}



export function setCentreClonedFlag(centreId) {
    return{
        type: CENTRE_DRAFT_ORDER_CLONED,
        payload: centreId
    }
}

export function setEditMode(flag){
    return {
        type: CENTRE_MODAL_FLAG_CHANGED,
        payload: flag
    }
}

/*export const fetchCentreById = (id) => async (dispatch) =>{
   const url = "/api/centres/" + id;
   try{
       dispatch(fetchCentreDetail());
       let res = await axios.get(url);
       //console.log(res.data);
       dispatch(receivedCentreDetail(res.data));
   }catch (e) {
       console.log(e.message);
   }
};*/

export const sortCentres = (field) => async (dispatch,getState) => {
    let centres = getState().centre.centres;
    //console.log(centres);
    let sortedCentres =
        Object.assign([], centres);
    //console.log(sortedCentres);
    if (field !== null) {
        sortedCentres.sort((a, b) => {
            if (!a[field]){
                return -1;
            }
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        });
    }
    dispatch(centresSorted(sortedCentres));
}

export const postCentre = () => async (dispatch, getState) => {
    //do the work then clear the change from memory
    const centre = getState().ui.centreChanged;
    try {
        dispatch(postCentreStart());
        await axios.post("/api/centres/" + centre._id, centre);
        dispatch(postCentreDone(centre));
    }catch (e) {
        console.log(e.message);
    }finally {
        dispatch(centreClear());
    }

};

export const fetchCentresByParams = (params) => async (dispatch) => {
    //console.log(`fetchCentresByParams: ${JSON.stringify(params)}`);
    const url = "/api/centres";

    try {
        //if no pagination then we assume this is a first call and need create the pagination state
        //before going on to call for the first page.
        dispatch(fetchCentres());
        let options = {
            params: params
        }
        let res = await axios.get(url, options);
        //console.log(res.data);
       dispatch(receivedCentres(res.data));
    }catch (e){
        console.log(e.message);
    }

}

export const linkCentreToShopify = (centreId,email) => async (dispatch) =>{
    dispatch(postCentreStart());
    await axios.post("/api/centres/" + centreId + "/link/" + email);
    dispatch(fetchCentresByParams());
}
