import PropTypes from "prop-types";
import React, {Component} from "react";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

class UserSearch extends Component {

  render() {

    return (
      <Box>
        <Grid container direction={"row"} spacing={1} justifyContent={"center"}>
          <Grid item sm={3}>
            <TextField
              id={"firstName"}
              name={"firstName"}
              variant={"standard"}
              label={"First Name"}
              onChange={e => {
                this.props.inputChanged(e);
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id={"lastName"}
              name={"lastName"}
              label={"Last Name"}
              variant={"standard"}
              onChange={e => {
                this.props.inputChanged(e);
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id={"cbCustomerName"}
              name={"cbCustomerName"}
              label={"CB Customer"}
              variant={"standard"}
              onChange={e => {
                this.props.inputChanged(e);
              }}
            />
            </Grid>
          <Grid item container direction={"column"} sm={2} justifyContent={"center"}>
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={false}
              onClick={() => {
                this.props.search();
                //reset the form
                document.getElementById("firstName").value = "";
                document.getElementById("lastName").value = "";
                document.getElementById("cbCustomerName").value = "";
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

UserSearch.propTypes = {
  search: PropTypes.func.isRequired,
  inputChanged: PropTypes.func.isRequired
};

export default UserSearch;
