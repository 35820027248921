import React, {Fragment, useState} from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AgGridReact} from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

//import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import OrderEditComponent from "./OrderEditComponent";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function OrdersGridComponent({
    isCBUser,
  orders,
  selectedOrder,
  handleEditClick,
    handleEditSave,
  closeEditDialog,
  showActions,
  selectedRows,
  handleSelectionChanged,
    handleDeleteClick
}) {

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedOrderForDeletion, setSelectedOrderForDeletion] = useState(null);

  const handleDeleteConfirm = () => {
    if (selectedOrderForDeletion) {
      handleDeleteClick(selectedOrderForDeletion);
    }
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  // Cell Renderer Function
  const addressCellRenderer = (params) => {
    const type = params.colDef.cellRendererParams.type; // 'billing' or 'shipping'
    const address = params.data[`${type}Address`];

    if (!address) return "N/A";

    return Object.keys(address)
      .map((key) => `${address[key]}`)
      .join(", ");
  };

  // add up all the line item quantites and add to the grid
  const quantityCellRenderer = (params) => {
    const lineItems = params.data.lineItems;
    let total = 0;
    lineItems.forEach((item) => {
      total += item.quantity;
    });
    return total;
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);

    // Find and remove unselected rows
    const unselectedRows = selectedRows.filter(
      (selectedRow) =>
        !selectedData.some((row) => row.orderId === selectedRow.orderId)
    );

    handleSelectionChanged(selectedData, unselectedRows);
  };
  function getDateReceived(params) {
    if(params.data.dateReceived) {
      const dateTime = params.data.dateReceived.split("T");
      return dateTime[0];
    }else{
      return "na"
    }
  }
  let colDefs = [
    {
      headerName: "Received On",
      field: "dateReceived",
      valueGetter: getDateReceived,
      width: 150,
      sortable: true,
      sortIndex: 0,
      sort: "desc",
      resizable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Order ID",
      field: "orderId",
      width: 100,
      sortable: true,
      sort: "desc",
      sortIndex: 2,
      resizable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      sortable: true,
      sort: "asc",
      sortIndex: 1,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      headerName: "First Name",
      field: "firstName",
      width: 100,
      sortable: true,
      resizable: true,
      cellClass: "wrap-text",
      autoHeight: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Last Name",
      field: "lastName",
      width: 100,
      sortable: true,
      resizable: true,
      cellClass: "wrap-text",
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    },
    {
      headerName: "Total Quantity",
      field: "lineItems",
      cellRenderer: quantityCellRenderer,
      cellStyle: (params) => {
        const lineItems = params.data.lineItems;
        let total = 0;
        lineItems.forEach((item) => {
          total += item.quantity;
        });
        if (total > 1) {
          return { backgroundColor: '#e57373' };
        } else {
          return { backgroundColor: "white" };
        }
      },
      width:100,
      maxWidth: 100,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      headerName: "Shipping",
      field: "shippingAddress",
      cellRenderer: addressCellRenderer,
      cellRendererParams: {
        type: "shipping",
      },
      minWidth: 200,
      width: 350,
      resizable: true,
      cellClass: "wrap-text",
      autoHeight: true,
      filter: true,
      floatingFilter: true
    },
    {
      headerName: "Movement Cost",
      field: "shipping.charge",
      width: 100,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      headerName: "Tracking Number",
      field: "shipping.trackingNumber",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Carrier",
      field: "shipping.carrier",
      width: 100,
      sortable: true,
      resizable: true,
      cellClass: "wrap-text",
      autoHeight: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Date Fulfilled",
      field: "dateFulfilled",
      filter: "agTextColumnFilter",
      width: 100,
      sortable: true,
      filterParams: {
        buttons: ["reset", "apply"],
      },
    },
  ];

  if (isCBUser){
    colDefs = colDefs.filter((col) => col.field !== "send"); //avoid duplicate columns
    colDefs.unshift({
      headerName: "Send",
      field: "checkbox",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 80,
      sortable: false,
      filter: false,
    });
  }

  if (showActions) {
    colDefs = colDefs.filter((col) => col.field !== "actions"); //avoid duplicate columns
    colDefs.push({
      headerName: "Actions",
      field: "actions",
      pinned: "right",
      cellStyle: { paddingTop: '0', paddingBottom: '0', margin: '0', display: 'flex', alignItems: 'center' },
      cellRenderer: (params) => (
          <Fragment>
            <IconButton
                title="Edit Order"
                sx={{ m: 0 }}
                variant="contained"
                color="primary"
                onClick={() => handleEditClick(params.data)}
            >
              <EditIcon fontSize={'small'}/>
            </IconButton>
            <IconButton
                title={"View Picking List"}
                sx={{ m: 0 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  let ref =  `/api/cbuser/orders/${params.data._id}/pickinglist.json`
                  window.open(ref, '_blank');}}
            >
              <MoreVertIcon fontSize={'small'}/>
            </IconButton>
            <IconButton
                title={"delete order"}
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  setSelectedOrderForDeletion(params.data);
                  setDeleteDialogOpen(true);
                }}
            >
              <DeleteIcon fontSize={"small"}/>
            </IconButton>
          </Fragment>
      ),
      width: 180, // Increased width
      sortable: false,
      filter: false,
    });
  }
//...


  return (
      <Fragment>
    <div id="cb-grid" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
      <AgGridReact
        rowSelection={"multiple"}
        gridOptions={{
          domLayout: "normal",
          headerHeight: 50,
          isRowSelectable: function (rowNode) {
            return !rowNode.data.forwardToCarrier;
          },
          pagination: true,
          paginationPageSize: 100,
          getRowId: (params) => {
            //console.log(params);
            return params.data.orderId;
          },
        }}
        rowData={orders}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
        }}
        columnDefs={colDefs}
        onSelectionChanged={onSelectionChanged}
        animateRows={true}
      />
      {selectedOrder && (
        <OrderEditComponent
          order={selectedOrder}
          handleClose={closeEditDialog}
          handleEditSave={handleEditSave}
        />
      )}
    </div>
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
  );
}

export default OrdersGridComponent;
