import {IconButton} from "@mui/material";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import React, {Component} from "react";
import PropTypes from "prop-types";

class InventoryButtonsRenderer extends Component {
    constructor(props) {
        super(props);
        this.renderReturnButton = this.renderReturnButton.bind(this);
    }


    renderReturnButton(item) {
        return (
            <IconButton
                sx={{
                    width: 8,  // or whatever size you prefer
                    height: 8  // or whatever size you prefer
                }}
                id={item.productId}
                node="a"
                target="_blank"
                aria-label={"picking list"}
                color={"default"}
                onClick={event => {
                    //console.log("button stops event propagation");
                    this.props.onReturnClicked(item);
                }}
            ><AssignmentReturnedIcon /></IconButton>
        );
    }


// Inside InventoryButtonsRenderer
    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{marginBottom: '-4px'}}>
                {this.renderReturnButton(this.props.data)}
                </div>
            </div>
        );
    }

}

InventoryButtonsRenderer.propTypes = {

    onReturnClicked: PropTypes.func,

};
export default InventoryButtonsRenderer;
