// noinspection JSUnusedAssignment

import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import App from "./components/App";
import reducers from "./reducers";
import {SnackbarProvider} from "notistack";
import WebSocketProvider from "../src/utils/WebSocket";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./themes/CopperBeechTheme";

let store = null;

store = configureStore({ reducer: reducers });

ReactDOM.render(
<ThemeProvider theme={theme}>
    <Provider store={store}>
      <SnackbarProvider>
        <WebSocketProvider>
          <App />
        </WebSocketProvider>
      </SnackbarProvider>
    </Provider>
</ThemeProvider>,
  document.getElementById("root")
);
