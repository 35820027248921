//import "materialize-css/dist/css/materialize.min.css";
import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import ResponsiveAppBar from "./ResponsiveAppBar";


class GLRHeader extends Component {
  constructor(props) {
    super(props);
    this.renderCartLink = this.renderCartLink.bind(this);
    this.renderContentTop = this.renderContentTop.bind(this);
    this.renderAdminItem = this.renderAdminItem.bind(this);
    this.renderGLRPoints = this.renderGLRPoints.bind(this);
    this.renderSuperAdmin = this.renderSuperAdmin.bind(this);
    this.renderCBUser = this.renderCBUser.bind(this);
    this.renderRewardCabinet = this.renderRewardCabinet.bind(this);
    this.renderProfileLink = this.renderProfileLink.bind(this);
    this.renderLogin = this.renderLogin.bind(this);
  }


  renderCartLink() {
    if (this.props.cart.length > 0) {
      //console.log(this.props.cart);
      return (
        //<NavItem className={"sidenav-close"}>
        //</NavItem>
        (<NavLink to={"/shop/cart"} key={"cart"}>New Basket</NavLink>)
      );
    }
  }

  renderProfileLink() {
    if (this.props.auth) {
      return (
        <NavLink to={"/profile"} key={"profile"}>
          {this.props.auth.firstName}
        </NavLink>
      );
    }
  }
  renderGLRPoints() {
    if (this.props.auth._student) {
      return (
        this.props.auth.username +
        ": Lizard Cards: " +
        this.props.auth._student.currentPoints
      );
    } else {
      return "No Lizard Cards";
    }
  }
  renderMyOrder() {
    if (this.props.auth.roles.includes("member")) {
      return (
        //<NavItem key={"lineItems"} className="sidenav-close">
        //</NavItem>
        (<NavLink to={"/lineitems"} key={"orders"}>Orders</NavLink>)
      );
    }
  }
  renderGuardian() {
    if (this.props.auth.roles.includes("guardian")) {
      return (
        //<NavItem key={"guardian"} className="sidenav-close">
        // </NavItem>
        //<NavItem key={"guardian"} href="/guardian" className="sidenav-close">Guardian Board </NavItem>
        (<NavLink to={"/guardian"} key={"guardian"}>Guardian Board</NavLink>)
      );
    }
  }
  renderCBUser() {
    if (this.props.auth.roles.includes("cbuser")) {
      return (
        //<NavItem key={"glradmin"} className="sidenav-close">
        // </NavItem>
        //<NavItem key={"glradmin"} href="glradmin" className="sidenav-close">Admin Dashboard </NavItem>
        ([<NavLink to={"/cbuser"} key={"cbuser_dashboard"}>
          Copper Beech
        </NavLink>])
      );
    }
  }
  renderSuperAdmin() {
    if (this.props.auth.roles.includes("glradmin")) {
      return (
        //<NavItem key={"glradmin"} className="sidenav-close">
        // </NavItem>
        //<NavItem key={"glradmin"} href="glradmin" className="sidenav-close">Admin Dashboard </NavItem>
        ([<NavLink to={"/glradmin"} key={"admin_dashboard"}>
          GLR Dashboard
        </NavLink>])
      );
    }
  }
  renderRewardCabinet() {
    if (
      this.props.auth.roles.some(role => {
        return ["admin", "member", "guardian"].includes(role);
      })
    ) {
      return (
        //<NavItem key={"cabinet"} className="sidenav-close">
        //</NavItem>
        //<NavItem key={"cabinet"} href="cabinet" className="sidenav-close">Reward Cabinet </NavItem>
        (<NavLink key={"cab"} to={"/cabinet"}>Reward Cabinet</NavLink>)
      );
    }
  }
  renderLogin() {
    return (
      <NavLink to={"/"} key="login">
        Login
      </NavLink>
    );
  }
  renderContentTop() {
    switch (this.props.auth) {
      case null:
        return  this.renderLogin();
      case false:
        return  this.renderLogin();
      default:
        // noinspection RequiredAttributes
        return [

          this.renderRewardCabinet(),
          this.renderMyOrder(),
          this.renderGuardian(),
          this.renderAdminItem(),
          this.renderSuperAdmin(),
          this.renderCBUser(),
          this.renderCartLink(),
          this.renderGLRPoints(),
          this.renderProfileLink(),
          <NavLink href="/api/logout" key={"logout"}>
            Logout
          </NavLink>
        ];
    }
  }

  renderAdminItem() {
    if (!this.props.auth.roles.includes("admin")) {
    } else {
      return [
        <NavLink key={"admin"} to={"/admin"}>
          Centre Dashboard
        </NavLink>
      ];
    }
  }

  render() {
    return (
          <ResponsiveAppBar />
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth ? state.auth : null,
    cart: state.cart.cart ? state.cart : null
  };
}

export default connect(mapStateToProps)(GLRHeader);
