// PieChartComponent.jsx

import React from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Pie} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartMovements = ({ list }) => {
    const getPieChartData = () => {
        const carrierCount = {};

        list.forEach((item) => {
            const carrier = item.carrier || "Unknown";
            carrierCount[carrier] = (carrierCount[carrier] || 0) + 1;
        });

        const labels = Object.keys(carrierCount);
        const data = Object.values(carrierCount);

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56",
                        "#00FF00",
                        "#FF00FF",
                        "#0000FF",
                        "#00FFFF",
                        "#FF0000",
                        "#FFFF00",
                        "#C0C0C0",
                    ],
                },
            ],
        };
    }

    const pieChartData = getPieChartData();

    return <Pie data={pieChartData}  id={"movementPie"}/>;
};

export default PieChartMovements;
