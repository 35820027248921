// MovementsDashboard.jsx
import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import PieChartComponent from "./PieChartMovements"; // Import the new PieChartComponent
import CustomerMovementGrid from "./CustomerMovementGrid"; // Import MovementGrid

const MovementsDashboard = ({ list }) => {
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  const exportToCSV = () => {
    if (gridApi) {
      const params = {
        fileName: "movements.csv",
        // Add additional params here if needed
      };
      gridApi.exportDataAsCsv(params);
    }
  };

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Movements Dashboard
      </Typography>
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={4}>
              <PieChartComponent list={list} />
            </Grid>
            <Grid item xs={4}>
              {/* BarChart will go here */}
            </Grid>
            <Grid item xs={4}>
              {/* TotalSpendCard will go here */}
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
        {
          <Button variant="contained" color="primary" onClick={exportToCSV}>
            Export to CSV
          </Button>
        }
      </Box>
      <Box mt={3}>
        <CustomerMovementGrid list={list} setGridApi={setGridApi} />
      </Box>
    </>
  );
};

export default MovementsDashboard;
