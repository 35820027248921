// noinspection DuplicatedCode

import React from "react";
import RequestForm from "../user/PasswordResetRequest";
import Feedback from "../Feedback";
import {connect} from "react-redux";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {requestPasswordReset} from "../../actions";
import {Grid} from "@mui/material";


function renderProgress (props){
  if (props.progress === true){
    return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
    );
  }
}
function PasswordResetRequestContainer(props) {
  //myProps = props;
  return (
    <Grid container spacing={1} direction={"column"}>
      {renderProgress(props)}
      <Grid item>
        <Feedback message={props.message} status={props.status} />
      </Grid>
      <Grid item>
        <RequestForm onSubmit={props.onSubmit} />
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  let message = "";
  let status = "";
  let progress = false;
  if (state.ui) {
    progress = state.ui.isFetching;
    if (state.ui.error) {
      message = state.ui.error.errorMessage;
      status = state.ui.error.status;
    } else if (state.ui.feedback) {
      message = state.ui.feedback.message;
      status = state.ui.feedback.status;
    }
  }
  return { message, status, progress};
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: event => {
      console.log(event.target[0]);
      dispatch(requestPasswordReset({ email: event.target[0].value }));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetRequestContainer);
