import axios from "axios";
import axiosRetry from "axios-retry";
import {cloneDeep, toInteger, toString} from "lodash";
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction,} from "./snackActions";
import Button from "@mui/material/Button";
import {movementClear} from "./UIActions";
import {notifyError, resetStore} from "./index";
import React, {Fragment} from "react";

export const REQUEST_CB_CUSTOMERS = "REQUEST_CB_CUSTOMERS";
export const RECEIVED_CB_CUSTOMERS = "RECEIVED_CB_CUSTOMERS";
export const SELECTED_CB_CUSTOMER = "SELECTED_CB_CUSTOMER";
export const RECEIVED_CB_CONTRACT = "RECEIVED_CB_CONTRACT";
export const NEW_CB_MOVEMENT_CHANGE = "NEW_CB_MOVEMENT_CHANGE";
export const RESET_CB_MOVEMENT = "RESET_CB_MOVEMENT";
export const NEW_CB_TASK_CHANGE = "NEW_CB_TASK_CHANGE";
export const RESET_CB_TASK = "RESET_CB_TASK";
export const RESET_CB_COST = "RESET_CB_COST";
export const RESET_CB_CUSTOMER = "RESET_CB_CUSTOMER";
export const RESET_CB_CONTRACT = "RESET_CB_CONTRACT";
export const CB_COST_TYPE_CHANGED = "CB_COST_TYPE_CHANGED";
export const RECEIVED_CB_CONTRACT_COSTS = "RECEIVED_CB_CONTRACT_COSTS";
export const RECEIVED_CB_COSTS = "RECEIVED_CB_COSTS";
export const REQUEST_CB_TASKS = "REQUEST_CB_TASKS";
export const REQUEST_CB_COSTS = "REQUEST_CB_COSTS";
export const RECEIVED_CB_TASKS = "RECEIVED_CB_TASKS";
export const REQUEST_CB_MOVEMENTS = "REQUEST_CB_MOVEMENTS";
export const RECEIVED_CB_MOVEMENTS = "RECEIVED_CB_MOVEMENTS";
export const NEW_CB_COST_CHANGE = "NEW_CB_COST_CHANGE";
export const DELETED_CB_MOVEMENT = "DELETED_CB_MOVEMENT";
export const DELETED_CB_COST = "DELETED_CB_COST";
export const DELETED_CB_TASK = "DELETED_CB_TASK";
export const E_CREATE_MOVEMENT = "E_CREATE_MOVEMENT";
export const CB_ERROR = "CB_ERROR";
export const CREATED_MOVEMENT = "CREATED_MOVEMENT";
export const UPDATED_MOVEMENT = "UPDATED_MOVEMENT";
export const RESET_CB_ERROR = "RESET_CB_ERROR";
export const CREATED_CB_INVOICE = "CREATED_CB_INVOICE";
export const REQUEST_CB_INVOICES = "REQUEST_CB_INVOICES";
export const RECEIVED_CB_INVOICES = "RECEIVED_CB_INVOICES";
export const RECEIVED_CB_TOTALS = "RECEIVED_CB_TOTALS";
export const REQUEST_CB_TOTALS = "REQUEST_CB_TOTALS";
export const DELETED_CB_INVOICE = "DELETED_CB_INVOICE";
export const CB_CUSTOMER_CHANGED = "CB_CUSTOMER_CHANGED";
export const CB_CUSTOMER_FORM_CHANGED = "CB_CUSTOMER_FORM_CHANGED";
export const CB_CONTRACT_SUMMARY_CHANGED = "CB_CONTRACT_SUMMARY_CHANGED";
export const CB_CONTRACT_CHARGE_CHANGED = "CB_CONTRACT_CHARGE_CHANGED";
export const CB_CONTRACT_COST_CHANGED = "CB_CONTRACT_COST_CHANGED";
export const CB_RESET_CONTRACT_EDIT_FLAG = "CB_RESET_CONTRACT_EDIT_FLAG";

export const CB_CONTRACT_SUMMARY_FORM_CHANGED =
  "CB_CONTRACT_SUMMARY_FORM_CHANGED";
export const CB_CONTRACT_CHARGE_FORM_CHANGED =
  "CB_CONTRACT_CHARGE_FORM_CHANGED";
export const CB_CONTRACT_CHARGE_FORM_ADD = "CB_CONTRACT_CHARGE_FORM_ADD";
export const CB_CONTRACT_COST_FORM_CHANGED = "CB_CONTRACT_COST_FORM_CHANGED";
export const CB_CONTRACT_COST_FORM_ADD = "CB_CONTRACT_COST_FORM_ADD";
export const CB_CUSTOMER_MODAL = "CB_CUSTOMER_MODAL";
export const CB_CONTRACT_MODAL = "CB_CONTRACT_MODAL";
export const CB_COSTS_MODAL = "CB_COSTS_MODAL";
export const CB_CHARGES_MODAL = "CB_CHARGES_MODAL";
export const CB_M_GRID_EDIT_MODE = "CB_M_GRID_EDIT_MODE";
export const RESET_CB_LISTS = "RESET_CB_LISTS";

axiosRetry(axios, { retries: 0 });

export function resetCBLists() {
  return {
    type: RESET_CB_LISTS,
    payload: null,
  };
}
function updatedMovement(movement) {
  return {
    type: UPDATED_MOVEMENT,
    payload: movement,
  };
}
export function set_CBMGridEditMode(value) {
  return {
    type: CB_M_GRID_EDIT_MODE,
    payload: value,
  };
}

export function CB_CustomerDialogViewChanged(value) {
  return (dispatch) => {
    dispatch({
      type: CB_CUSTOMER_MODAL,
      payload: value,
    });
  };
}
export function CB_ContractDialogViewChanged(value) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_MODAL,
      payload: value,
    });
  };
}
export function CB_CostsDialogViewChanged(value) {
  return (dispatch) => {
    dispatch({
      type: CB_COSTS_MODAL,
      payload: value,
    });
  };
}

export function CB_ChargesDialogViewChanged(value) {
  return (dispatch) => {
    dispatch({
      type: CB_CHARGES_MODAL,
      payload: value,
    });
  };
}
export function CB_CustomerChanged(id, field, value) {
  //console.log(`${id} ${field} ${value}`)
  return (dispatch) => {
    dispatch({
      type: CB_CUSTOMER_CHANGED,
      payload: { _id: id, field: field, value: value },
    });
  };
}

export function CB_ContractCostAdd(charge) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_COST_FORM_ADD,
      payload: charge,
    });
  };
}
export function CB_ContractChargeAdd(charge) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_CHARGE_FORM_ADD,
      payload: charge,
    });
  };
}

export function CB_ContractChanged(id, field, value) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_SUMMARY_CHANGED,
      payload: { _id: id, field: field, value: value },
    });
  };
}

export function CB_ContractChargeChanged(id, field, value) {
  //console.log("Charge Changed Action");
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_CHARGE_CHANGED,
      payload: { _id: id, field: field, value: value },
    });
  };
}

export function CB_ContractCostChanged(id, field, value) {
  //console.log("Cost Changed Action");
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_COST_CHANGED,
      payload: { _id: id, field: field, value: value },
    });
  };
}

export function CB_CustomerFormReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_CB_CUSTOMER,
      payload: null,
    });
  };
}

export function CB_CustomerFormChanged(field, value) {
  return (dispatch) => {
    dispatch({
      type: CB_CUSTOMER_FORM_CHANGED,
      payload: { field: field, value: value },
    });
  };
}
export function CB_ContractSummaryFormChanged(field, value) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_SUMMARY_FORM_CHANGED,
      payload: { field: field, value: value },
    });
  };
}

export function CB_ContractChargeFormChanged(field, value) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_CHARGE_FORM_CHANGED,
      payload: { field: field, value: value },
    });
  };
}

export function CB_ContractCostFormChanged(field, value) {
  return (dispatch) => {
    dispatch({
      type: CB_CONTRACT_COST_FORM_CHANGED,
      payload: { field: field, value: value },
    });
  };
}
export function CB_ContractFormsReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_CB_CONTRACT,
      payload: null,
    });
  };
}
function CB_RequestedTotals() {
  return {
    type: REQUEST_CB_TOTALS,
    payload: null,
  };
}

function CB_ResetContractEditFlag() {
  return {
    type: CB_RESET_CONTRACT_EDIT_FLAG,
    payload: null,
  };
}
function CB_invoiceDeleted(id) {
  return {
    type: DELETED_CB_INVOICE,
    payload: id,
  };
}
export function CB_InvoicesRequested() {
  return {
    type: REQUEST_CB_INVOICES,
    payload: null,
  };
}
export function CB_InvoicesReceived(data) {
  return {
    type: RECEIVED_CB_INVOICES,
    payload: data,
  };
}
function CB_TotalsReceived(data) {
  return {
    type: RECEIVED_CB_TOTALS,
    payload: data,
  };
}
function Created_CB_Invoice(data) {
  return {
    type: CREATED_CB_INVOICE,
    payload: data,
  };
}
export const enqueueSnackbar =
  (message, variant, duration, dismiss) => async (dispatch) => {
    dispatch(
      enqueueSnackbarAction({
        message: message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: variant ? variant : "default",
          autoHideDuration: duration,
          action: dismiss
            ? (key) => (
                <Fragment>
                  <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                    dismiss
                  </Button>
                </Fragment>
              )
            : "",
        },
      })
    );
  };
function CB_Error(error) {
      enqueueSnackbarAction({
        message: "Task Saved",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
          autoHideDuration: 2000,
        },
      })
  return {
    type: CB_ERROR,
    payload: error,
  };
}
export function Reset_CB_Error() {
  return {
    type: RESET_CB_ERROR,
    payload: null,
  };
}
function CB_CreatedMovement(data) {
  return {
    type: CREATED_MOVEMENT,
    payload: data,
  };
}
/*function CB_CreatedTask(data) {
  return {
    type: CREATED_TASK,
    payload: data,
  };
}*/

function CB_E_CreateMovement(error) {
  return {
    type: E_CREATE_MOVEMENT,
    payload: error,
  };
}

function CB_MovementsDeleted() {
  return {
    type: DELETED_CB_MOVEMENT,
    payload: null,
  };
}

function CB_TasksDeleted() {
  return {
    type: DELETED_CB_TASK,
    payload: null,
  };
}

function CB_CostsDeleted() {
  return {
    type: DELETED_CB_COST,
    payload: null,
  };
}
function CB_TasksRequested() {
  return (dispatch) => {
    dispatch({
      type: REQUEST_CB_TASKS,
      payload: null,
    });
  };
}

function CB_MovementsRequested() {
  return {
    type: REQUEST_CB_MOVEMENTS,
    payload: null,
  };
}
function CB_CostsRequested() {
  return {
    type: REQUEST_CB_COSTS,
    payload: null,
  };
}
function CB_TasksReceived(tasks) {
  return {
    type: RECEIVED_CB_TASKS,
    payload: tasks,
  };
}
function CB_MovementsReceived(movements) {
  return {
    type: RECEIVED_CB_MOVEMENTS,
    payload: movements,
  };
}
function CB_CostsReceived(costs) {
  return {
    type: RECEIVED_CB_COSTS,
    payload: costs,
  };
}
function CB_CustomersRequested() {
  return {
    type: REQUEST_CB_CUSTOMERS,
    payload: null,
  };
}

function CB_CostTypeChanged(value) {
  return {
    type: CB_COST_TYPE_CHANGED,
    payload: value,
  };
}
function CB_CustomersReceived(customers) {
  return {
    type: RECEIVED_CB_CUSTOMERS,
    payload: customers,
  };
}
function CB_ContractReceived(contract) {
  // we could have more than one contract over time but for now we take the first one
  return {
    type: RECEIVED_CB_CONTRACT,
    payload: contract,
  };
}
function CB_CustomerSelected(id) {
  return {
    type: SELECTED_CB_CUSTOMER,
    payload: id,
  };
}

export const deleteMovement = (id) => async (dispatch) => {
  const url = "/api/cbuser/movements/" + id;
  try {
    await axios.delete(url);
    //console.log(res.data);
    dispatch(CB_MovementsDeleted());
    dispatch(fetchThisMonthsMovements());
    dispatch(enqueueSnackbar("deleted movement", "info", 2000));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const deleteCost = (id) => async (dispatch) => {
  const url = "/api/cbuser/costs/" + id;
  try {
    await axios.delete(url);
    //console.log(res.data);
    dispatch(CB_CostsDeleted());
    dispatch(fetchThisMonthsCosts());
    dispatch(enqueueSnackbar("deleted cost", "info", 2000));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const deleteTask = (id) => async (dispatch) => {
  const url = "/api/cbuser/tasks/" + id;
  try {
    await axios.delete(url);
    //console.log(res.data);
    dispatch(CB_TasksDeleted());
    dispatch(fetchThisMonthsTasks());
    dispatch(enqueueSnackbar("deleted task", "info", 2000));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const deleteInvoice = (id) => async (dispatch) => {
  const url = "/api/cbuser/invoice/" + id;
  //console.log(id);
  try {
    await axios.delete(url);
    dispatch(CB_invoiceDeleted(id));
    dispatch(enqueueSnackbar("deleted invoice", "info", 2000));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
export const fetchCustomersContract = (id) => async (dispatch) => {
  const url = "/api/cbuser/contracts";
  const options = {
    params: { customerId: id },
  };
  try {
    dispatch(CB_CustomerSelected(id));
    let res = await axios.get(url, options);
    //console.log(res.data);

    dispatch(CB_ContractReceived(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchAllCustomers = () => async (dispatch) => {
  const url = "/api/cbuser/customers";
  try {
    dispatch(CB_CustomersRequested());
    let res = await axios.get(url);
    //console.log(res.data);
    dispatch(CB_CustomersReceived(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchThisMonthsTasks = () => async (dispatch) => {
  //console.log('here');
  const url = "/api/cbuser/tasks";
  const firstDay = firstDateOfThisMonth();
  const params = {
    fromDate: dateTimeToDateString(firstDay),
  };
  try {
    dispatch(CB_TasksRequested());
    let res = await axios.get(url, { params });
    dispatch(CB_TasksReceived(res.data));
    //console.log(res.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchThisMonthsCosts = () => async (dispatch) => {
  //console.log('here');
  const url = "/api/cbuser/costs";
  const firstDay = firstDateOfThisMonth();
  const params = {
    fromDate: dateTimeToDateString(firstDay),
  };
  try {
    dispatch(CB_CostsRequested());
    let res = await axios.get(url, { params });
    dispatch(CB_CostsReceived(res.data));
    //console.log(res.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchThisMonthsMovements = () => async (dispatch) => {
  const url = "/api/cbuser/movements";
  const firstDay = firstDateOfThisMonth();
  const params = {
    fromDate: dateTimeToDateString(firstDay),
  };
  try {
    dispatch(CB_MovementsRequested());
    let res = await axios.get(url, { params });
    dispatch(CB_MovementsReceived(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchCosts = () => async (dispatch, getState) => {
  //console.log('here');
  const url = "/api/cbuser/costs";

  try {
    const fromDate = firstDateOfGivenMonthString(
      getState().ui.searchParams.year,
      getState().ui.searchParams.month
    );
    const endDate = firstDateOfGivenMonthString(
      getState().ui.searchParams.year,
      getState().ui.searchParams.month,
      1
    );
    const params = {
      fromDate: fromDate,
      endDate: endDate,
      customerId: getState().ui.searchParams.customerId,
    };
    dispatch(CB_CostsRequested());
    let res = await axios.get(url, { params });
    dispatch(CB_CostsReceived(res.data));
    //console.log(res.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchTasks = () => async (dispatch, getState) => {
  //console.log('here');
  const url = "/api/cbuser/tasks";

  try {
    const fromDate = firstDateOfGivenMonthString(
      getState().ui.searchParams.year,
      getState().ui.searchParams.month
    );
    const endDate = firstDateOfGivenMonthString(
      getState().ui.searchParams.year,
      getState().ui.searchParams.month,
      1
    );
    const params = {
      fromDate: fromDate,
      endDate: endDate,
      customerId: getState().ui.searchParams.customerId,
    };
    dispatch(CB_TasksRequested());
    let res = await axios.get(url, { params });
    dispatch(CB_TasksReceived(res.data));
    //console.log(res.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
export const fetchMovements = () => async (dispatch, getState) => {
  const url = "/api/cbuser/movements";
  try {
    const fromDate = firstDateOfGivenMonthString(
      getState().ui.searchParams.year,
      getState().ui.searchParams.month
    );
    const endDate = firstDateOfGivenMonthString(
      getState().ui.searchParams.year,
      getState().ui.searchParams.month,
      1
    );
    //console.log(`year: ${getState().ui.searchParams.year}`);
    //console.log(`month: ${getState().ui.searchParams.month}`);
    //console.log(`fromDate: ${fromDate}`);
    //console.log(`endDate: ${endDate}`);
    const params = {
      customerId: getState().ui.searchParams.customerId,
      fromDate: fromDate,
      endDate: endDate,
    };
    //console.log(params);
    dispatch(CB_MovementsRequested());
    let res = await axios.get(url, { params });
    //console.log(res.data);
    dispatch(CB_MovementsReceived(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
export const fetchCBInvoices = () => async (dispatch, getState) => {
  const url = "/api/cbuser/invoices";
  try {
    const params = getState().ui.searchParams;
    dispatch(CB_InvoicesRequested());
    let res = await axios.get(url, { params });
    //console.log(res.data);
    dispatch(CB_InvoicesReceived(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
export const fetchCBTotals = () => async (dispatch, getState) => {
  const url = "/api/cbuser/reports/totals/summary";
  try {
    //if not period provided that is fine - the route will assume the client wants this month only
    const activeFlag = getState().ui.cbActiveFlag;
    let queryParams = { active: true };
    if (typeof activeFlag === "undefined") {
      queryParams = { active: true };
    }
    if (typeof activeFlag !== "undefined" && activeFlag === true) {
      queryParams = { active: true };
    }
    if (typeof activeFlag !== "undefined" && activeFlag === false) {
      queryParams = { active: null }; //get everything
    }
    //queryParams = {active: true};
    //check to see if the ui has selected a period
    const period = getState().ui.cbTotalPeriod;
    //console.log(`period: ${period}`);
    if (period) {
      queryParams["period"] = period;
    }
    //console.log(queryParams);
    dispatch(CB_RequestedTotals());
    let res = await axios.get(url, { params: queryParams }); // only active customers
    //console.log(res.data);
    dispatch(CB_TotalsReceived(res.data));
  } catch (e) {
    //console.log(e.message);
    //dispatch(enqueueSnackbarAction(e.message, "error", null, true));
    notifyError(e, dispatch);
    dispatch(CB_TotalsReceived([]));
    await checkFor401(e, dispatch);
  }
};

export const CBCostTypeChanged = (value) => async (dispatch) => {
  dispatch(CB_CostTypeChanged(value));
};
//fired off as soon as a user begins to type into the Input field
// this builds up the draft order definition ready to be saved
export function newMovementChange(fieldId, text) {
  let payload = { field: fieldId, value: text };
  return (dispatch) => {
    dispatch({
      type: NEW_CB_MOVEMENT_CHANGE,
      payload: payload,
    });
  };
}

export function newTaskChange(fieldId, text) {
  let payload = { field: fieldId, value: text };
  return (dispatch) => {
    dispatch({
      type: NEW_CB_TASK_CHANGE,
      payload: payload,
    });
  };
}
export function newCostChange(fieldId, text) {
  let payload = { field: fieldId, value: text };
  return (dispatch) => {
    dispatch({
      type: NEW_CB_COST_CHANGE,
      payload: payload,
    });
  };
}
export function resetNewMovement() {
  return (dispatch) => {
    dispatch({
      type: RESET_CB_MOVEMENT,
      payload: null,
    });
  };
}
export function resetNewTask() {
  return (dispatch) => {
    dispatch({
      type: RESET_CB_TASK,
      payload: null,
    });
  };
}
export function resetNewCost() {
  return (dispatch) => {
    dispatch({
      type: RESET_CB_COST,
      payload: null,
    });
  };
}

export const saveCBCustomerChanges = () => async (dispatch, getState) => {
  const url = "/api/cbuser/customers";
  try {
    let customerIds = getState().cbuser.editedCustomers;
    //filter the customer array if the id is in the customerId list
    // eslint-disable-next-line
    let customers = getState().cbuser.customers.filter((customer) => {
      if (customerIds.includes(customer._id)) {
        return customer;
      }
    });
    dispatch(CB_Error(null));
    await axios.put(url, customers);
    //TODO: need to dispatch event to clear editList (could add this to fetchAllCustomers)
    dispatch(fetchAllCustomers());
  } catch (e) {
    //console.log(e.message);
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const saveCBContractChanges = () => async (dispatch, getState) => {
  const url = "/api/cbuser/contracts";
  try {
    if (!getState().cbuser.contractEdited) {
      dispatch(
        CB_Error({ errorMessage: "No changes found. Save not triggered." })
      );
      return;
    }
    let contract = getState().cbuser.contract;
    //filter the customer array if the id is in the customerId list
    dispatch(CB_Error(null));
    const res = await axios.put(url, contract);
    //TODO: need to dispatch event to clear editList (could add this to fetchAllCustomers)
    dispatch(CB_ResetContractEditFlag());
    dispatch(CB_ContractReceived(res.data));
    dispatch(enqueueSnackbar("contract changes saved", "info", 2000, true));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const addNewChargeToContract = () => async (dispatch, getState) => {
  //all we are triggering here is that the new data is added into the charge array for the redux contract
  //the contract save is being dispatched within here too for now
  const newCharge = getState().cbuser.newChargeData;
  dispatch(CB_ContractChargeAdd(newCharge));
  dispatch(saveCBContractChanges());
};
export const addNewCostToContract = () => async (dispatch, getState) => {
  //all we are triggering here is that the new data is added into the charge array for the redux contract
  //the contract save is being dispatched within here too for now
  const newCost = getState().cbuser.newCostData;
  dispatch(CB_ContractCostAdd(newCost));
  dispatch(saveCBContractChanges());
};

export const saveNewMovement = () => async (dispatch, getState) => {
  //console.log(event.target.customers);
  const url = "/api/cbuser/movements";
  try {
    let movement = {};
    Object.assign(movement, getState().cbuser.newMovementData);
    let cust = getState().cbuser.customers.find((customer) => {
      return customer.id === movement.customer;
    });
    movement["customerName"] = cust.name;
    let check = validateMovement(movement);
    if (check) {
      dispatch(CB_E_CreateMovement(check));
      return;
    }
    const res = await axios.post(url, movement);
    dispatch(CB_CreatedMovement(res.data));
    dispatch(fetchThisMonthsMovements());
    dispatch(enqueueSnackbar("created movement", "info", 2500, true));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const saveNewTask = () => async (dispatch, getState) => {
  //console.log(event.target.customers);
  const url = "/api/cbuser/tasks";
  try {
    let task = Object.assign({}, getState().cbuser.newTaskData);
    //we need to get the customer name and add this to the task
    let cust = getState().cbuser.customers.find((customer) => {
      return customer.id === task.customer;
    });
    task["customerName"] = cust.name;
    await axios.post(url, task);
    //dispatch(CB_CreatedTask(res.data));

    dispatch(
      enqueueSnackbarAction({
        message: "Task Saved",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
          autoHideDuration: 2000,
        },
      })
    );
    dispatch(fetchThisMonthsTasks());
    //dispatch(enqueueSnackbar("Task saved", "info", 2000, true));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};


export const saveNewCost = () => async (dispatch, getState) => {
  // console.log(event.target.customers);
  const url = "/api/cbuser/costs";
  try {
    let cost = Object.assign({}, getState().cbuser.newCostData);
    //we need to get the customer name and add this to the task
    let cust = getState().cbuser.customers.find((customer) => {
      return customer.id === cost.customer;
    });
    cost["customerName"] = cust.name;
    await axios.post(url, cost);
    dispatch(
        enqueueSnackbarAction({
          message: "Cost Saved",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "info",
            autoHideDuration: 2000,
          },
        })
    );
    dispatch(fetchThisMonthsCosts());
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const saveNewCustomer = () => async (dispatch, getState) => {
  const url = "/api/cbuser/customer";
  try {
    let customer = getState().cbuser.newCustomerData;
    //we need to get the customer name and add this to the task
    //console.log(JSON.stringify(customer));
    await axios.post(url, customer);
    await dispatch(fetchAllCustomers());
    dispatch(enqueueSnackbar("Customer Saved", "info", 2000, true));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const saveNewContract = () => async (dispatch, getState) => {
  const url = "/api/cbuser/contract";
  try {
    let contract = cloneDeep(getState().cbuser.newContractData);

    const customerId = getState().cbuser.selectedCustomerId;
    //we need to add the customer id to the contract
    contract["customerId"] = customerId;
    const customer = getState().cbuser.customers.find((customer) => {
      return customer.id === customerId;
    });
    contract["customerName"] = customer.name;
    await axios.post(url, contract);
    dispatch(fetchCustomersContract(customerId));
    dispatch(enqueueSnackbar("Contract Saved", "info", 2000, true));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const createCBInvoice = () => async (dispatch, getState) => {
  const url = "/api/cbuser/invoice";
  try {
    //check the params
    const params = getState().ui.searchParams;
    if (!params.customerId || !params.month || !params.year) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error("missing parameters");
    }
    let res = await axios.post(url, null, { params: params });
    dispatch(Created_CB_Invoice(res.data));
    dispatch(enqueueSnackbar("Invoice Created", "info", 2000, true));
    dispatch(fetchCBInvoices());
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const updateMovement = () => async (dispatch, getState) => {
  //do the work then clear the change from memory
  const movement = getState().ui.movementChanged;
  try {
    await axios.put("/api/cbuser/movements/" + movement._id, movement);
    dispatch(updatedMovement(movement));
    dispatch(enqueueSnackbar("updated movement", "info", 2000, true));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  } finally {
    dispatch(movementClear());
  }
};
export const selectCustomer = (id) => async (dispatch) => {
  dispatch(CB_CustomerSelected(id));
  dispatch(fetchCustomersContract(id));
};

export const batchMovements = () => async (dispatch) => {
  const url = "/api/cbuser/batch/movements.json";
  try {
    console.log("batch movements - this has been disabled and should not be getting triggered ");
    //await axios.post(url, null);
    //console.log(res.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

function dateTimeToDateString(dateTime) {
  return dateTime.toISOString().split("T")[0];
}
function firstDateOfThisMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}
function firstDateOfGivenMonthString(year, month, monthIncrement) {
  //increment the month if needed
  let monthString, yearString;
  let monthNum = toInteger(month);
  yearString = year;

  if (monthIncrement > 0) {
    monthString = toString(toInteger(month) + monthIncrement);
    monthNum = monthNum + monthIncrement;
  } else {
    monthString = month;
  }
  // if we went over 12 need to go back to jan
  if (monthNum > 12) {
    monthString = toString(monthNum - 12);
    // need to increment the year too
    yearString = toString(toInteger(year) + 1);
  } else {
  }
  monthString = toInteger(monthString) < 9 ? "0" + monthString : monthString;

  // if we are dealing with december at this point monthString will be
  return `${yearString}-${monthString}-01`;
}
function validateMovement(movement) {
  let error = null;
  if (movement.carrier.toUpperCase() !== "ROYAL MAIL") {
    //must have a tracking number
    if (!movement.trackingNumber) {
      error = {
        errorMessage:
          "All carriers other than Royal Mail must have a tracking number",
        name: "validation",
        description: " Enter a code or change the carrier to Royal Mail",
      };
    }
  }
  return error;
}
export async function checkFor401(error, dispatch) {
  console.log(error.response);
  if (error.response.status === 401) {
    dispatch(resetStore());
    await axios.get("/api/logout");
  }
}


