// src/features/config/configSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
    const response = await axios.get('/config');
    return response.data;
});

const configSlice = createSlice({
    name: 'config',
    initialState: {
        uiConfig: {},
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConfig.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.status = 'success';
                state.uiConfig = action.payload;
            })
            .addCase(fetchConfig.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default configSlice.reducer;
