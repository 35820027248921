export const START_LOGIN = 'start_login';
export const FETCH_USER = 'fetch_user';
export const SET_ORDER_USER = 'set_order_user';
export const FETCH_SURVEYS = 'fetch_surveys';
export const FETCH_PRODUCTS = 'fetch_products';
export const FETCH_CATEGORIES = 'fetch_categories';
export const FETCH_ALL_STUDENTS = 'fetch_students;';
export const FETCH_ALL_XOD_STUDENTS = 'fetch_xod_students;';
export const NEW_CATEGORY = 'new_category';

export const FETCH_XOD_STUDENT = 'FETCH_XOD_STUDENT';
export const FETCH_XOD_ACHIEVEMENTS  = 'FETCH_XOD_ACHIEVEMENTS';
export const SET_PROGRESS_BAR = 'SET_PROGRESS_BAR';
export const SET_STUDENT_PAGES = 'SET_STUDENT_PAGES';

export const UPDATE_FAVS = 'update_favs';



