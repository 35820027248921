import React, {useEffect, useMemo, useRef, useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {
  addNewContact,
  addNewLead,
  addNewNote,
  contactUpdated,
  deleteContact,
  deleteLead,
  deleteNote,
  fetchLeads,
  initializeEdit,
  leadUpdated,
  noteUpdated,
  resetNewFields,
  setSelectedLeadId,
  updateAddressField,
  updateLead,
  updateLeadField,
  updateNewContactField,
  updateNewNoteField,
} from "../../reducers/leadSlice";

// Assuming generateGridValueSetter is a utility that creates a valueSetter
// function appropriate for use with Redux dispatches for updating data
import {generateGridValueSetter} from "../../utils/generateGridValueSetter";
import CreateLead from "./CreateLead";
import CreateContact from "./CreateContact";
import useNotifier from "../useNotifier";
import CreateNote from "./CreateNote";
import {ENQUEUE_SNACKBAR} from "../../actions/snackActions";
import {DialogContentText, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const ActionsCellRenderer = (props) => {
  const { onEdit, onDelete, onPreview} = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <IconButton
        style={{ padding: "4px" }}
        aria-label="edit"
        color="primary"
        size={"small"}
        key={"edit"}
        onClick={() => onEdit(props.data)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        style={{ padding: "4px" }}
        aria-label="delete"
        color="primary"
        size={"small"}
        key={"delete"}
        onClick={() => onDelete(props.data)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const LeadsDashboard = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [leadEditMode, setLeadEditMode] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);


  const handleAdd = () => {
    // This can be used to set up default values or manage dialog opening states
    setLeadEditMode(false); // Indicate that we are in edit mode
    setSelectedLead(null);
    setOpenAddDialog(true);
  };

  const handleEdit = async (item) => {
    console.log(item);
    await setSelectedLead(item); // Assuming setSelectedLead will set the lead to be edited
    await dispatch(initializeEdit(item));
    setOpenAddDialog(true); // Reuse the add dialog for edit by opening it
    setLeadEditMode(true); // Indicate that we are in edit mode
  };

  const handleDeleteRequest = (item) => {
    setItemToDelete(item);
    setOpenDeleteDialog(true);
  };

  const handleDelete = (item) => {
    if (selectedLead && item._id === selectedLead._id) {
      setSelectedLead(null); // Reset selected lead if it's the one being deleted
      // Additional logic to clear related UI states
    }
    dispatch(deleteLead(item._id));
  };

  const dispatch = useDispatch();
  const { leads, status, error, newLead} = useSelector((state) => state.leads);
  const leadStatusValues = useSelector((state) => state.config?.uiConfig?.lead?.leadStatusValues);
  useEffect(() => {
    if (status === "failed") {
      dispatch({
        type: ENQUEUE_SNACKBAR,
        key: new Date().getTime(),
        notification: {
          message: `Failed: ${error}`,
          options: { variant: "error" },
        },
      });
    } else if (status === "success") {
      dispatch({
        type: ENQUEUE_SNACKBAR,
        key: new Date().getTime(),
        notification: {
          message: "created ok",
          options: { variant: "success", autoHideDuration: 3000 },
        },
      });
    }

    // ... handle other statuses
  }, [status, error, dispatch]);


  useEffect(() => {
    dispatch(fetchLeads());
  }, [dispatch]);

  const gridRef = useRef();
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.updateGridOptions({ rowData: leads });
    }
  }, [leads]);

  const leadColumns = useMemo(
    () => [
      {
        field: "customerName",
        headerName: "Customer Name",
        editable: true,
        valueSetter: generateGridValueSetter(
          dispatch,
          leadUpdated,
          selectedLead
        ),
      },
      {
        field: "status",
        headerName: "Status",
        editable: true,
        valueSetter: generateGridValueSetter(
          dispatch,
          leadUpdated,
          selectedLead
        ),
      },

      // Add more columns as needed
    ],
    [dispatch, selectedLead]
  );

  const actionsColumn = useMemo(
    () => [
      {
        headerName: "Actions",
        cellRenderer: (params) => (
          <ActionsCellRenderer
            data={params.data}
            onEdit={handleEdit}
            onDelete={handleDeleteRequest}
          />
        ),
        filter: false,
        sortable: false,
        resizable: false,
      },
    ],
    [handleAdd, handleEdit, handleDeleteRequest]
  );

  // Combine your leadColumns with actionsColumn when you're setting up the grid or rendering it
  const combinedColumns = useMemo(
    () => [...leadColumns, ...actionsColumn],
    [leadColumns, actionsColumn]
  );
  const onLeadSelectionChanged = (params) => {
    const selectedRows = params.api.getSelectedRows();
    // Since selectedRows is an array, you should check its length to determine if a row is selected
    if (selectedRows.length > 0) {
      const selectedRowData = selectedRows[0]; // Access the first (and only) item in the array
      setSelectedLead(selectedRowData); // Update the state with the selected lead object
      dispatch(setSelectedLeadId(selectedRowData._id)); // Dispatch the action with the selected lead's _id
    } else {
      setSelectedLead(null); // Handle the case where no rows are selected (e.g., deselection)
      dispatch(setSelectedLeadId(null)); // Update the Redux state accordingly
    }
  };

  const gridOptions = {
    onSelectionChanged: onLeadSelectionChanged,
    rowSelection: "single", // Single row selection
    getRowId: (params) => params.data._id,
    getRowStyle: (params) => ({
      display: "flex",
      alignItems: "center", // Centers the content vertically
      padding: "0 4px", // Adjust padding as necessary
    }),
  };

  const handleUpdateLeadField = (fieldData) => {
    dispatch(updateLeadField(fieldData));
  };

  const handleUpdateAddressField = (fieldData) => {
    dispatch(updateAddressField(fieldData));
  };

  const handleAddLead = () => {
    dispatch(addNewLead());
  };

  const handleSaveEdit = () => {
    // Assuming you have a way to collect the updated lead data from the CreateLead componen
    dispatch(updateLead(selectedLead._id));
    setOpenAddDialog(false); // Close the dialog
    setLeadEditMode(false); // Exit edit mode
  };
  const handleCancelLead = () => {
    setOpenAddDialog(false);
    dispatch(resetNewFields());
  };
  useNotifier();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Leads Dashboard</h2>
        <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this lead? This will remove the entire document including contacts and notes.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => {
              handleDelete(itemToDelete);
              setOpenDeleteDialog(false);
            }} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
          <DialogTitle>{leadEditMode ? "Edit Lead" : "Add New Lead"}</DialogTitle>
          <DialogContent>
            <CreateLead
                leadStatusValues = {leadStatusValues}
                lead={leadEditMode ? newLead : newLead}
                updateAddressField={handleUpdateAddressField}
                updateLeadField={handleUpdateLeadField}
                handleSave={leadEditMode ? handleSaveEdit : handleAddLead}
                handleCancel={handleCancelLead}
            />
          </DialogContent>
        </Dialog>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <IconButton
                style={{ padding: "4px" }}
                aria-label="add"
                color="primary"
                size="small"
                onClick={() => handleAdd()}
            >
              <AddBoxIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} className="ag-theme-balham">
              <AgGridReact
                  ref={gridRef}
                  rowData={leads}
                  columnDefs={combinedColumns}
                  gridOptions={gridOptions}
                  domLayout={'autoHeight'}
              />
          </Grid>
        </Grid>
      </Grid>
      {selectedLead && (
        <Grid item xs={12}>
          <LeadDetails dispatch={dispatch}/>{" "}
        </Grid>
      )}
    </Grid>
  );
};

const LeadDetails = ({ dispatch }) => {
  const [openAddContactDialog, setOpenAddContactDialog] = useState(false);
  const [openAddNoteDialog, setOpenAddNoteDialog] = useState(false);
  const [contactEditMode, setContactEditMode] = useState(false);
  const [notePreviewMode, setNotePreviewMode] = useState(false);
  const [selectedNote, setSelectedNote] = useState(false);

  const contactsGridRef = useRef(null);
  const notesGridRef = useRef(null);
  const selectedLeadId = useSelector(state => state.leads.selectedLeadId);
  const lead = useSelector(state =>
      state.leads.leads.find(lead => lead._id === selectedLeadId)
  );
  const contactRoles = useSelector((state) => state.config?.uiConfig?.lead?.leadContactRoles);
  const noteTypes = useSelector((state) => state.config?.uiConfig?.lead?.leadNoteTypes );
  const ActionsCellRenderer = (props) => {
    const { onDelete, onPreview, disable } = props;

    return (
        <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
        >
          {onDelete && (
              <IconButton
                  style={{padding: "4px"}}
                  aria-label="delete"
                  disabled={lead?.status === 'closed'}
                  color="primary"
                  size={"small"}
                  key={"delete"}
                  onClick={() => onDelete(props.data)}
              >
                <DeleteIcon/>
              </IconButton>
          )}
          {onPreview && (
              <IconButton
                  style={{padding: "4px"}}
                  aria-label="preview"
                  disabled={lead?.status === 'closed'}
                  color="primary"
                  size={"small"}
                  key={"preview"}
                  onClick={() => onPreview(props.data)}
              >
                <PreviewIcon/>
              </IconButton>
          )}
        </div>
    );
  };
  useEffect(() => {
    if (contactsGridRef.current && contactsGridRef.current.api) {
      contactsGridRef.current.api.setGridOption('rowData', lead.contactPersons);
    }
  }, [lead?.contactPersons]);
  useEffect(() => {
    if (notesGridRef.current && notesGridRef.current.api) {
      notesGridRef.current.api.setGridOption('rowData', lead.notes);
    }
  }, [lead?.notes]);

  const contactColumns = useMemo(
      () => [
        {
          field: "name",
          headerName: "Name",
          editable: true,
          valueSetter: generateGridValueSetter(dispatch, contactUpdated, lead),
        },
        {
          field: "email",
          headerName: "Email",
          editable: true,
          valueSetter: generateGridValueSetter(dispatch, contactUpdated, lead),
        },
        {
          field: "phone",
          headerName: "Phone",
          editable: true,
        valueSetter: generateGridValueSetter(dispatch, contactUpdated, lead),
      },
      {
        field: "role",
        headerName: "Role",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, contactUpdated, lead),
      },
      {
        field: "otherRole",
        headerName: "Other Role",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, contactUpdated, lead),
      },
      {
        headerName: "Actions",
        cellRenderer: (params) => (
            <ActionsCellRenderer
                data={params.data}
                onDelete={handleDeleteContact}

            />
        ),
        width: 100,
        pinned: "right",
        filter: false,
        sortable: false,
        resizable: false,
      },
      // Add more columns for contacts as needed
    ],
    [dispatch, lead?._id,lead?.contactPersons]
  );

  const noteColumns = useMemo(
    () => [
      {
        field: "note",
        headerName: "Note",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, noteUpdated, lead),
      },
      {
        field: "type",
        headerName: "Type",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, noteUpdated, lead),
      },
      {
        field: "contactPersonsInvolved",
        headerName: "Contact",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, noteUpdated, lead),
      },
      {
        field: "staffContact",
        headerName: "CB Contact",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, noteUpdated, lead),
      },
      {
        field: "createdBy",
        headerName: "Created By",
        editable: true,
        valueSetter: generateGridValueSetter(dispatch, noteUpdated, lead),
      },
      {
        field: "date",
        headerName: "Created Date",
        editable: false,
        valueSetter: generateGridValueSetter(dispatch, noteUpdated, lead),
      },
      {
        headerName: "Actions",
        cellRenderer: (params) => (
            <ActionsCellRenderer
                data={params.data}
                onDelete={handleDeleteNote}
                onPreview={handlePreviewNote}
            />
        ),
        width: 100,
        pinned: "right",
        filter: false,
        sortable: false,
        resizable: false,
      },
      // Add more columns for notes as needed
    ],
    [dispatch, lead?._id,lead?.notes]
  );
  const handleSaveNewContact = async () => {
    setOpenAddContactDialog(false);
    await dispatch(addNewContact());


  };
  const handleSaveNewNote = async () => {
    setOpenAddNoteDialog(false);
    await dispatch(addNewNote());
  };
  const handleCancelContact = () => {
    setOpenAddContactDialog(false);
    dispatch(resetNewFields());
  };
  const handleCancelNote = () => {
    setOpenAddNoteDialog(false);
    dispatch(resetNewFields());
  };
  const handleDeleteContact = async (fieldData) => {

    await dispatch(deleteContact({leadId: lead._id,contactId: fieldData._id}));
  };
  const handleDeleteNote = async (fieldData) => {
    await dispatch(deleteNote({leadId: lead._id,noteId: fieldData._id}));
  };
  const handlePreviewNote = async (fieldData) => {
    setNotePreviewMode(true);
    console.log(fieldData);
    setSelectedNote(fieldData);
  }
  const handleUpdateNewContactField = (fieldData) => {
    dispatch(updateNewContactField(fieldData));
  };
  const handleUpdateNewNoteField = (fieldData) => {
    dispatch(updateNewNoteField(fieldData));
  };
  return (
    <div>
      <Dialog
        open={openAddContactDialog}
        onClose={() => setOpenAddContactDialog(false)}
      >
        <DialogContent>
          <CreateContact
              roles={contactRoles}
              updateContactField={handleUpdateNewContactField}
            handleSave={handleSaveNewContact}
            handleCancel={handleCancelContact}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddNoteDialog}
        onClose={() => setOpenAddNoteDialog(false)}
      >
        <DialogTitle>Add New Note </DialogTitle>
        <DialogContent>
          <CreateNote contactPersons={lead?.contactPersons}
                      types={noteTypes}
                      updateNoteField={handleUpdateNewNoteField}
                      handleCancel={handleCancelNote} handleSave={handleSaveNewNote}/>
        </DialogContent>
      </Dialog>
      <Dialog
          open={notePreviewMode}
          onClose={() => setNotePreviewMode(false)}
      >
        <DialogTitle>View Note </DialogTitle>
        <DialogContent>
          <TextField multiline={10}
            value={selectedNote.note}
          />

        </DialogContent>
      </Dialog>
      <Grid container spacing={2} direction={"column"}>
        <Grid item xs={12}>
          <Grid container direction={"column"}>
          <h3>Contact Persons</h3>
          <Grid container item direction={"row"}>
            <Grid item>
              <IconButton
                style={{ padding: "4px" }}
                aria-label="add"
                color="primary"
                size={"small"}
                key={"add"}
                disabled={lead?.status === 'closed'}
                onClick={() => setOpenAddContactDialog(true)}
              >
                <AddBoxIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item
            className="ag-theme-balham"
          >
            <AgGridReact
                ref={contactsGridRef}
              rowData={lead?.contactPersons}
              columnDefs={contactColumns}
              domLayout="autoHeight"
            />
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3>Notes</h3>
          <Grid container direction={"row"}>
            <Grid item>
              <IconButton
                style={{ padding: "4px" }}
                aria-label="add"
                color="primary"
                size={"small"}
                key={"add"}
                disabled={lead?.status === 'closed'}
                onClick={() => setOpenAddNoteDialog(true)}
              >
                <AddBoxIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div
            className="ag-theme-balham"
            style={{ height: 200, width: "100%" }}
          >
            <AgGridReact
                ref={notesGridRef}
              rowData={lead?.notes}
              columnDefs={noteColumns}
              domLayout="autoHeight"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadsDashboard;
