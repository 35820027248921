import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, FormControl, Grid, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";

class UserDetailCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email, // Initialize state with email from props
    };
    this.renderAddress = this.renderAddress.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  updateEmail() {
    //console.log(this.props);
    const { id, updateEmailAction } = this.props;
    updateEmailAction(id, this.state.email); // Dispatch the action to update email in Redux store
  }

  renderAddress(address) {
    if (typeof address === "string") {
      return address;
    } else {
      return JSON.stringify(address);
    }
  }

  render() {
    return (
      <Grid container spacing={2} alignItems="center" direction={"column"}>
        <Grid item sm={4}>
          <Typography variant="h6">
            {" "}
            {this.props.firstName + " " + this.props.lastName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction={"column"}
          spacing={2}
          alignItems={"center"}
        >
          <Grid item>
            <Typography variant="h6"> Change Email</Typography>
          </Grid>
          <Grid item container xs={12} spacing={2} justifyContent={"center"} directio={"row"}>
           <Grid item xs={3}>
             <FormControl fullWidth><TextField
              label={"Email"}
              variant="standard"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
             </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.updateEmail}
            >
              Change Email
            </Button>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UserDetailCard.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  updateEmailAction: PropTypes.func.isRequired, // Add propType for the Redux action
};

export default UserDetailCard;
