import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {selectProductById} from "./cbinventoryReducer";
import axios from "axios";
import {enqueueSnackbar} from "../actions/snackActions";

export const addItemThunk = (productId) => (dispatch, getState) => {
  const state = getState();
  const existingLine = state.customerOrder.lineItems.find(
    (product) => product.productId === productId
  );

  if (!existingLine) {
    const product = selectProductById(state.inventory, productId);
    dispatch(addItem({ ...product, quantity: 1 }));
  }
};
export const saveOrderToServer = createAsyncThunk(
  "customerOrder/saveOrder",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState(); // Get the current Redux state
    const orderData = state.customerOrder; // Replace 'yourOrderSlice' with the name of the slice where the order data resides

    try {
      const response = await axios.post("/api/customer/orders", orderData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const saveCBUserOrderToServer = createAsyncThunk(
  "CBUserOrder/saveOrder",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState(); // Get the current Redux state
    const orderData = { ...state.customerOrder }; // Replace 'yourOrderSlice' with the name of the slice where the order data resides
    // need to add the selected customer id to the order data
    const selectedCustomerId = state.cbuser.selectedCustomerId;
    orderData.customerId = selectedCustomerId;

    // Look up the customer name based on the selected ID
    const selectedCustomer = state.cbuser.customers.find(
      (customer) => customer.id === selectedCustomerId
    );
    if (selectedCustomer) {
      orderData.customerName = selectedCustomer.name;
    }
    try {
      const response = await axios.post("/api/cbuser/orders", orderData);
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Order Saved",
          options: { variant: "success" },
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        enqueueSnackbar(error.response?.data, { variant: "error" })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  firstName: "",
  lastName: "",
  status: "open",
  dateReceived: new Date().toISOString(), //iso date
  sellerNotes: "",
  shippingAddress: {
    Company: "",
    Address1: "",
    Address2: "",
    Address3: "",
    City: "",
    County: "",
    Country: "",
    postCode: "",
  },
  lineItems: [],
};

export const orderSlice = createSlice({
  name: "customerOrder",
  initialState,
  reducers: {
    updateOrderField: (state, action) => {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    updateAddressField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.shippingAddress[fieldName] = value;
    },
    addItem: (state, action) => {
      const product = action.payload;
      state.lineItems.push(product);
    },
    removeItem: (state, action) => {
      //console.log(action.payload);
      const productId = action.payload;
      state.lineItems = state.lineItems.filter(
        (product) => product.productId !== productId
      );
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const existingLine = state.lineItems.find(
        (product) => product.productId === id
      );
      if (existingLine) {
        existingLine.quantity = quantity;
      }
    },
    resetOrderForm: (state) => {
      return initialState;
    },
    clearOrderState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCBUserOrderToServer.fulfilled, (state, action) => {
        Object.assign(state, initialState);
        enqueueSnackbar({
          message: "Order Saved",
          options: { variant: "success" },
        });
      })
      .addCase(saveCBUserOrderToServer.rejected, (state, action) => {
        enqueueSnackbar(action.payload, { variant: "error" });
      })
      .addCase(saveOrderToServer.fulfilled, (state, action) => {
        Object.assign(state, initialState);
        enqueueSnackbar({
          message: "Order Saved",
          options: { variant: "success" },
        })
      })
      .addCase(saveOrderToServer.rejected, (state, action) => {
        enqueueSnackbar(action.payload, { variant: "error" });
      });
  },
});

export const {
  updateOrderField,
  updateAddressField,
  addItem,
  removeItem,
  updateQuantity,
  resetOrderForm,
  clearOrderState,
} = orderSlice.actions;

export const selectSelectedProducts = (state) => state.customerOrder.lineItems;

export default orderSlice.reducer;
