import PropTypes from "prop-types";
import React, {Component} from "react";
import CredentialsFormContainer from "../containers-user/CredentialsFormContainer";
import UserDetailCard from "./UserDetailCard";
import {Grid, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import {withTheme} from "@mui/styles";

class UserProfileScreen extends Component {

  render() {
      const { theme } = this.props;
    return (
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
              <Paper elevation={1} style={{
                  backgroundColor: theme.palette.primary.light, // Using primary light color from theme
                  color: theme.palette.primary.contrastText // Ensuring readable text color
              }}>
            <Box p={2}>
            <UserDetailCard
              id={this.props.user._id}
              firstName={this.props.user.firstName}
              lastName={this.props.user.lastName}
              username={this.props.user.username}
              email={this.props.user.email}
              updateEmailAction={this.props.updateEmailAction}
              address={""}
            />
            </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} mt={1}>
              <Paper elevation={1} style={{
                  backgroundColor: theme.palette.primary.light, // Using primary light color from theme
                  color: theme.palette.primary.contrastText // Ensuring readable text color
              }}>
            <Box p={2}>
              <CredentialsFormContainer
                delegateSubmit={this.props.pwdChangeSubmit}
              />
            </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

UserProfileScreen.propTypes = {
  user: PropTypes.object.isRequired,
  pwdChangeSubmit: PropTypes.func.isRequired,
  updateEmailAction: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withTheme(UserProfileScreen);
