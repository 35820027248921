import axios from "axios";
import _ from "lodash";
import {fetchUser, GLRError, notifyError, resetStore} from "./index";
import {DELETE_ALL_LINES} from "./cartActions";
import {saveDraftTriggered, selectDraftOrder} from "./UIActions";
import {setCentreClonedFlag} from "./centreActions";
import axiosRetry from "axios-retry";
import {enqueueSnackbar as enqueueSnackbarAction,} from "./snackActions";
import {enqueueSnackbar} from "./cbuserActions";

export const REQUEST_DRAFT_ORDERS = "REQUEST_DRAFT_ORDERS";
export const REQUEST_LINEITEMS = "REQUEST_LINEITEMS";
export const RECEIVED_LINEITEMS = "RECEIVED_LINEITEMS";
export const RECEIVED_LINEITEMS_NONE = "RECEIVED_LIEITEMS_NONE";
export const LINE_MARKED_DELETE = "LINE_MARKED_DELETE";
export const LINE_UNMARKED_DELETE = "LINE_UNMARKED_DELETE";
export const RESET_DELETED = "LINES_DELETED";
export const ADMIN_LINE_MARKED_DELETE = "ADMIN_LINE_MARKED_DELETE";
export const ADMIN_LINE_UNMARKED_DELETE = "ADMIN_LINE_UNMARKED_DELETE";
export const GLR_RECEIVED_ORDER_LIST = "GLR_RECEIVED_ORDER_LIST";
export const GLR_RECEIVED_DRAFT_ORDER_LIST = "GLR_RECEIVED_DRAFT_ORDER_LIST";
export const POST_CLONE_DRAFT_ORDER = "POST_CLONE_DRAFT_ORDER";
export const POST_CLONE_DRAFT_ORDER_DONE = "POST_CLONE_DRAFT_ORDER_DONE";
export const GLR_DELETE_DRAFT_ORDER = "GLR_DELETE_DRAFT_ORDER";
export const GLR_DELETE_DRAFT_ORDER_DONE = "GLR_DELETE_DRAFT_ORDER_DONE";
export const RECEIVED_DRAFT_ORDER = "RECEIVED_DRAFT_ORDER";
export const RECEIVED_DRAFT_ORDER_COUNT = "RECEIVED_DRAFT_ORDER_COUNT";
export const DRAFT_ORDER_PAGINATION = "DRAFT_ORDER_PAGINATION";
export const RECEIVED_INVOICE_LIST = "RECEIVED_INVOICE_LIST";
export const REQUEST_INVOICES = "REQUEST_INVOICES";
export const DELETED_INVOICE = "DELETED_INVOICE";
export const API_FAILURE = "API_FAILURE";
export const RECEIVED_DRAFT_ORDER_INVENTORY = "RECEIVED_DRAFT_ORDER_INVENTORY";

axiosRetry(axios, { retries: 0 });

let draftOrderLimit = 1000;
function setPagination(pagination) {
  return {
    type: DRAFT_ORDER_PAGINATION,
    payload: pagination,
  };
}

function receivedDraftedOrderInventory(data) {
  return {
    type: RECEIVED_DRAFT_ORDER_INVENTORY,
    payload: data,
  };
}
function apiFailure(message) {
  enqueueSnackbarAction({
    message: { message },
    options: {
      key: new Date().getTime() + Math.random(),
      variant: "error",
      autoHideDuration: 5000,
    },
  });
  return {
    type: API_FAILURE,
    payload: message,
  };
}

function validateDraftOrder(draft) {
  //console.log(draft);
  let result = { message: "" };
  if (!draft.targetDate) {
    result["targetDate"] = "missing";
    result.message += "Target Date Missing: ";
  }
  if (!draft.name) {
    result["name"] = "missing";
    result.message += "Name Missing: ";
  }
  if (!draft.centreType) {
    result["centreType"] = "missing";
    result.message += "Centre Type Missing: ";
  }
  return result;
}

function deletedInvoice(invoiceId) {
  return {
    type: DELETED_INVOICE,
    payload: invoiceId,
  };
}

function receivedInvoiceList(invoices) {
  return {
    type: RECEIVED_INVOICE_LIST,
    payload: invoices,
  };
}
function fetchingInvoices() {
  return {
    type: REQUEST_INVOICES,
    payload: null,
  };
}
function receivedDraftOrder(draft) {
  return {
    type: RECEIVED_DRAFT_ORDER,
    payload: draft,
  };
}

/*function receivedDraftOrderCount(json) {
  return {
    type: RECEIVED_DRAFT_ORDER_COUNT,
    payload: json
  };
}*/
function fetchingDraftOrder() {
  return {
    type: REQUEST_DRAFT_ORDERS,
    payload: null,
  };
}

export function cloneDraftOrderStart(draftOrderId, centreId, userId) {
  //M.toast({ html: "clone started", displayLength: 2000 });
  return {
    type: POST_CLONE_DRAFT_ORDER,
    payload: { draftOrderId: draftOrderId, userId: userId, centreId: centreId },
  };
}

export function cloneDraftOrderDone(draftOrderId, centreId, userId, data) {
  enqueueSnackbarAction({
    message: "Clone Completed OK.",
    options: {
      key: new Date().getTime() + Math.random(),
      variant: "info",
      autoHideDuration: 2000,
    },
  });
  return {
    type: POST_CLONE_DRAFT_ORDER_DONE,
    payload: {
      draftOrderId: draftOrderId,
      userId: userId,
      centreId: centreId,
      data: data,
    },
  };
}
function deleteDraftOrderStart(draftOrderId) {
  return {
    type: GLR_DELETE_DRAFT_ORDER,
    payload: { draftOrderId: draftOrderId },
  };
}

function deleteDraftOrderDone(draftOrderId) {
  return {
    type: GLR_DELETE_DRAFT_ORDER_DONE,
    payload: { draftOrderId: draftOrderId },
  };
}
export function resetDeleted() {
  return { type: RESET_DELETED };
}

//checking to see if a member or admin deleted the item
//admins can delete for any kid so we need to do something different here

function receiveGLROrderList(json) {
  return {
    type: GLR_RECEIVED_ORDER_LIST,
    payload: json,
  };
}
function receiveDraftOrderList(json) {
  return {
    type: GLR_RECEIVED_DRAFT_ORDER_LIST,
    payload: json,
  };
}

export function lineItemsDelete(studentId, checked, lineId) {
  //console.log(`lineItemsDelete student: ${studentId} line: ${lineId}`);
  if (studentId) {
    if (checked) {
      return {
        type: ADMIN_LINE_MARKED_DELETE,
        studentId,
        checked,
        lineId,
      };
    } else {
      return {
        type: ADMIN_LINE_UNMARKED_DELETE,
        studentId,
        checked,
        lineId,
      };
    }
  } else {
    if (checked) {
      return {
        type: LINE_MARKED_DELETE,
        checked,
        lineId,
      };
    } else {
      return {
        type: LINE_UNMARKED_DELETE,
        checked,
        lineId,
      };
    }
  }
}
function requestLineItems(centre, studentId) {
  return {
    type: REQUEST_LINEITEMS,
    centre,
    studentId,
  };
}
function receiveLineItems(centre, studentId, json) {
  return {
    type: RECEIVED_LINEITEMS,
    centre,
    studentId,
    payload: json,
    receivedAt: Date.now(),
  };
}
function receiveLineItemsError(centre, studentId, code) {
  return {
    type: RECEIVED_LINEITEMS_NONE,
    centre,
    studentId,
    payload: code,
    receivedAt: Date.now(),
  };
}
export const REQUEST_ORDER = "request_order";
function requestOrder(centre) {
  return {
    type: REQUEST_ORDER,
    centre,
  };
}

export const RECEIVE_ORDER = "receive_order";
function receiveOrder(centre, json) {
  //console.log(centre,json);
  return {
    type: RECEIVE_ORDER,
    centre,
    payload: json,
    receivedAt: Date.now(),
  };
}

export const INVALIDATE_ORDER = "invalidate_order";
export function invalidateOrder(centre) {
  return {
    type: INVALIDATE_ORDER,
    centre,
  };
}

export const deleteDraftOrder = (draftOrderId) => async (dispatch) => {
  dispatch(deleteDraftOrderStart(draftOrderId));
  //console.log(`delete: ${draftOrderId}`);
  try {
    let url = "/api/draft_orders/" + draftOrderId;
    await axios.delete(url);
    dispatch(deleteDraftOrderDone(draftOrderId));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
  //dispatch(deleteDraftOrderDone(draftOrderId));
};

export const deleteInvoice = (invoiceId) => async (dispatch) => {
  try {
    let url = "/api/invoice/" + invoiceId;
    const result = await axios.delete(url);
    //console.log(result.data);
    if (result.data.deletedCount < 1) {
      dispatch(apiFailure("delete failed"));
    } else {
      dispatch(deletedInvoice(invoiceId));
      dispatch(getInvoices(""));
    }
  } catch (e) {
    dispatch(apiFailure("delete failed"));
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
export const fetchOrder = (centre) => async (dispatch) => {
  let url = "/api/orders/" + centre;
  dispatch(requestOrder(centre)); //update state to say we are fetching cabinet
  const res = await axios.get(url);
  //console.log("response data: ", res.data);
  dispatch(receiveOrder(centre, res.data));
};

export const fetchDraftOrdersByParams =
  (params, pagination) => async (dispatch) => {
    try {
      dispatch(fetchingDraftOrder());
      if (!pagination) {
        //const countUrl = "/api/draft_orders/count/.json";
        //const countOptions = {
        // params: params
        //};
        //await dispatch(fetchProductCount());
        //console.log("getting draft order count");
        //let resCount = await axios.get(countUrl, countOptions);
        //const count = resCount.data.count;
        //const pages = Math.ceil(count/draftOrderLimit);
        const newPagination = {
          limit: draftOrderLimit,
          pages: 0,
          offset: 0,
        };
        dispatch(setPagination(newPagination));
        //dispatch(receivedDraftOrderCount(resCount.data));
      }
      //when we make a call the response will tell me what the max draftOrderLimit should be
      //if this is less that my initial value I need to revise my value
      let offset = 0;
      let page = 1;
      if (pagination) {
        page = pagination;
        offset = draftOrderLimit * (page - 1);
      }
      // pagination to params
      let newParams = Object.assign({}, params);
      if (!newParams) {
        newParams = { offset: offset, limit: draftOrderLimit };
      } else {
        newParams["limit"] = draftOrderLimit;
        newParams["offset"] = offset;
      }
      const url = "/api/draft_orders";
      const options = {
        params: newParams,
      };
      const res = await axios.get(url, options);
      draftOrderLimit = parseInt(res.headers["x-glr-limit"]);
      offset = parseInt(res.headers["x-glr-offset"]);
      let count = parseInt(res.headers["x-glr-count"]);
      const pages = Math.ceil(count / draftOrderLimit);
      dispatch(
        setPagination({ limit: draftOrderLimit, pages: pages, offset: offset })
      );

      dispatch(receiveDraftOrderList(res.data));
    } catch (e) {
      notifyError(e, dispatch);
      await checkFor401(e, dispatch);
    }
  };
export const fetchDraftOrderById = (id) => async (dispatch) => {
  //console.log("fetchOrderById");

  const url = "/api/draft_orders/" + id;
  try {
    dispatch(fetchingDraftOrder());
    const res = await axios.get(url);
    //dispatch(receivedDraftOrder(res.data));
    dispatch(selectDraftOrder(id, res.data));
    dispatch(receivedDraftOrder(res.data));
    //console.log(res.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchDraftOrderInventory = (id) => async (dispatch) => {
  const url = "/api/draft_orders/" + id + "/inventorycheck.json";
  //console.log(url);
  try {
    const res = await axios.get(url);
    //console.log(res);
    dispatch(receivedDraftedOrderInventory(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const postDraftOrder = () => async (dispatch, getState) => {
  //check the newdraftdata
  //clear any existing ui error info
  dispatch(GLRError());
  const validate = getState().ui.newDraftData;
  const validationResult = validateDraftOrder(validate);
  if (validationResult.message !== "") {
    dispatch(GLRError({ errorMessage: validationResult.message }));
    dispatch(enqueueSnackbar(validationResult.message, "error", null, true));
    return;
  }
  const url = "/api/draft_orders";
  try {
    //console.log(getState().ui.newDraftData);
    //we need to strip out all the product data
    const draft = _.cloneDeep(getState().ui.newDraftData);

    const lines = draft.line_items;
    console.log(draft);
    lines.map((line) => {
      line.product = {};
      return line;
    });
    let options = {};

    if (getState().ui.newDraft) {
      options = { params: { type: "new" } };
    }
    if (getState().ui.editDraft) {
      options = { params: { type: "edit" } };
    }

    //let options = {params:{type: "new"}};
    await axios.post(url, draft, options);
    dispatch(saveDraftTriggered());
    dispatch(enqueueSnackbar("saved draft order", "info", 2000));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
export const sendDraftOrderToShopify =
  (draftOrderId) => async (dispatch, getState) => {
    const url = "/api/draft_orders/" + draftOrderId + "/send";
    try {
      await axios.post(url);
      dispatch(fetchDraftOrdersByParams(getState().ui.searchParams));
    } catch (e) {
      notifyError(e, dispatch);
      await checkFor401(e, dispatch);
    }
  };
export const fetchOrdersByParams = (params) => async (dispatch) => {
  //console.log("fetchOrderByParams");
  const url = "/api/orders";
  const options = {
    params: params,
  };
  //console.log(options);
  //console.log(url);
  try {
    const res = await axios.get(url, options);
    //console.log(res.data);
    dispatch(receiveGLROrderList(res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const fetchLineItems = (centre, studentId) => async (dispatch) => {
  //console.log(centre, studentId);
  let url = "api/orders/" + centre + "/" + studentId;
  dispatch(requestLineItems(centre, studentId)); //update state to say we are fetching cabinet
  try {
    const res = await axios.get(url);
    //console.log(res.data);
    dispatch(receiveLineItems(centre, studentId, res.data));
  } catch (e) {
    //console.log("error",e);
    dispatch(receiveLineItemsError(centre, studentId, e.code));
   notifyError(dispatch,e);
    await checkFor401(e, dispatch);
  }
};


export const deleteLineItems =
  (centre, studentId, items) => async (dispatch) => {
    if (studentId) {
      let url = "api/orders/deletelines/" + centre + "/" + studentId;
      await axios.put(url, items);
      dispatch(resetDeleted());
      dispatch(fetchLineItems(centre, studentId));
      await dispatch(fetchUser());
    } else {
      // this means it is an admin delete and we have to group all the deletes
      //by student ID and then submit each set for each student
      //TODO: this first cut is calling for each single delete meaning a lot of
      // async colls! implement better grouping logic
      for (const item of items) {
        let studentId = item.studentId;
        let items = [item.lineId];
        let url = "/api/orders/deletelines/" + centre + "/" + studentId;
        console.log(url);
        try {
          await axios.put(url, items);
        } catch (e) {
          console.log(e.message);
        }
        dispatch(resetDeleted());
        dispatch(fetchOrder(centre));
      }
      await dispatch(fetchUser());
    }
  };

export const submitLineItems = (reqBody, history) => async (dispatch) => {
  try {
    //console.log(reqBody);
    await axios.post("/api/orders", reqBody);

    await dispatch(fetchUser());
    await dispatch({ type: DELETE_ALL_LINES }); //actually we clear the the local cart and then can populate the lineitems
    history.push("/cabinet");
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const createCabinetFromDraftOrder = (draftId) => async (dispatch) => {
  let response = null;
  try {
    response = await axios.post("/api/draft_orders/" + draftId + "/cabinet");
    console.log(`response data: ${response.data}`);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const cloneDraftOrder = (centreId) => async (dispatch, getState) => {
  const draftOrderId = getState().ui.selectedDraft;
  dispatch(cloneDraftOrderStart(draftOrderId, centreId));
  let url = "/api/draft_orders/" + draftOrderId + "/centre/" + centreId;
  try {
    const res = await axios.post(url);
    dispatch(setCentreClonedFlag(centreId));
    dispatch(cloneDraftOrderDone(draftOrderId, centreId, res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const autoSendDraftOrder = (centreId) => async (dispatch, getState) => {
  const draftOrderId = getState().ui.selectedDraft;
  dispatch(
    enqueueSnackbarAction({
      message: "Clone Started.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "default",
        autoHideDuration: 2000,
      },
    })
  );
  dispatch(cloneDraftOrderStart(draftOrderId, centreId));
  let url =
    "/api/draft_orders/" + draftOrderId + "/centre/" + centreId + "/autosend";
  try {
    const res = await axios.post(url);
    dispatch(setCentreClonedFlag(centreId));
    dispatch(
      enqueueSnackbarAction({
        message: "Clone Completed OK.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
          autoHideDuration: 2000,
        },
      })
    );
    dispatch(cloneDraftOrderDone(draftOrderId, centreId, res.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};

export const getInvoices = (name) => async (dispatch) => {
  let url = "/api/invoice/list";
  let params = { name: name };
  try {
    dispatch(fetchingInvoices());
    const response = await axios.get(url, { params: params });
    dispatch(receivedInvoiceList(response.data));
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};


export const createInvoice = () => async (dispatch, getState) => {
  let url = "/api/invoice/aggregate";
  try {
    const name = getState().ui.searchParams;
    await axios.post(url, name);
    dispatch(getInvoices(""));
    //console.log(response.data);
  } catch (e) {
    notifyError(e, dispatch);
    await checkFor401(e, dispatch);
  }
};
async function checkFor401(error, dispatch){
  console.log(error.response);
  if (error.response.status === 401) {
    dispatch(resetStore());
    await axios.get("/api/logout");
  }
}
