import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import LineItemsGrid from "./LineItemsGrid";

function OrderEditComponent({
  order,
  handleEditSave,
  handleClose,
    handleLineItemChange,
}) {
  const [newOrder, setNewOrder] = useState(order);
  const theme = useTheme();

  // Update newOrder when the order prop changes
  useEffect(() => {
    setNewOrder(order);
  }, [order]);

  const handleNestedInputChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split(".");
    setNewOrder((prevOrder) => {
      let updatedOrder = { ...prevOrder };
      if (keys.length === 2) {
        updatedOrder[keys[0]] = {
          ...updatedOrder[keys[0]],
          [keys[1]]: value,
        };
      } else {
        updatedOrder = { ...updatedOrder, [name]: value };
      }
      return updatedOrder;
    });
  };
  // Safely access nested properties
  const shippingAddress = order.shippingAddress || {};
  return (
    <Dialog open={true} maxWidth="lg">
      <DialogTitle style={{ color: theme.palette.primary.main }}>
        Edit Order
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="orderId"
                label="Order ID"
                value={newOrder.orderId}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="customerName"
                label="Customer Name"
                value={newOrder.customerName}
                onChange={handleNestedInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="firstName"
                label="First Name"
                value={newOrder.firstName}
                onChange={handleNestedInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="lastName"
                label="Last Name"
                value={newOrder.lastName}
                onChange={handleNestedInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                  label={"Status"}
                  name="status"
                  variant={"outlined"}
                  value={newOrder.status}
                  onChange={handleNestedInputChange}
                  fullWidth
              >
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {/* Fields for editing shippingAddress */}
          <Paper
            style={{
              padding: "16px",
              margin: "16px 0",
              backgroundColor: "#f0c0a1",
            }}
            elevation={2}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: theme.palette.primary.main }}
            >
              Address
            </Typography>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={6}>
                <TextField
                  name="shippingAddress.Address1"
                  label="Address 1"
                  value={newOrder.shippingAddress.Address1}
                  onChange={handleNestedInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="shippingAddress.Address2"
                  label="Address 2"
                  value={newOrder.shippingAddress.Address2}
                  onChange={handleNestedInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="shippingAddress.City"
                  label="City"
                  value={newOrder.shippingAddress.City}
                  onChange={handleNestedInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="shippingAddress.County"
                  label="County"
                  value={newOrder.shippingAddress.County}
                  onChange={handleNestedInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="shippingAddress.Country"
                  label="Country"
                  value={newOrder.shippingAddress.Country}
                  onChange={handleNestedInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="shippingAddress.postCode"
                  label="Post Code"
                  value={newOrder.shippingAddress.postCode}
                  onChange={handleNestedInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
          <Grid container>
          <LineItemsGrid
              lineItems={newOrder.lineItems}
              onLineItemChange={handleLineItemChange}
          />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '8px' }}>
        <Button onClick={() => handleEditSave(newOrder)} color="primary">
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderEditComponent;
