import PropTypes from "prop-types";
import React, {Component} from "react";
import {FormControl, List, ListItem, ListSubheader} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

class UserList extends Component {
  render() {
    //create the rows
    let checked = false;
    let items2 = [];
    let style = "";
    for (let i = 0; i < this.props.userList.length; i++) {
      const labelId = `checkbox-list-secondary-label-${this.props.userList[i].username}`;
      //create classname string to highlight if it is a selected item

      if (this.props.userList[i].selected) {
        style = "blue lighten-5 blue-text";
        checked = true;
      } else checked = false;
      style = "blue-text";
      items2.push(
        <ListItem
          id={this.props.userList[i].username}
          key={this.props.userList[i].username}
          secondaryAction={
            <Checkbox
              edge="end"
              onChange={(event) => {
                this.props.onUserSelected(
                  event,
                  this.props.userList[i].username,
                  this.props.title
                );
              }}
              checked={checked}
              inputProps={{ "aria-labelledby": labelId }}
            />
          }
          className={style}
        >
          {this.props.userList[i].firstName +
            " " +
            this.props.userList[i].lastName}{" "}
        </ListItem>
      );
    }

    return (
      <Box>
<FormControl fullWidth={true}>
          <List
          subheader={
            this.props.title ? (
              <ListSubheader component="div" id="nested-list-subheader">
                {this.props.title}
              </ListSubheader>
            ) : (
              ""
            )
          }
        >
          {items2}
        </List>
</FormControl>
      </Box>
    );
  }
}

UserList.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  enabled: PropTypes.bool,
  header: PropTypes.string,
  userList: PropTypes.array.isRequired,
  onUserSelected: PropTypes.func.isRequired,
};

export default UserList;
