import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

// Async thunk for fetching orders
export const fetchOrders = createAsyncThunk(
    'picking/fetchOrders',
    async (arg, { rejectWithValue }) => {
        try {
            const params = {
                status: 'open'
            };
            const response = await axios.get ('/api/cbuser/orders', {params: params}); // Adjust API endpoint as needed

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Initial state of the picking slice
const initialState = {
    orders: [],
    scannedItems: [],
    currentOrder: null,
    filter: '',
    loading: false,
    error: null,
};

const pickingSlice = createSlice({
    name: 'picking',
    initialState,
    reducers: {
        // Reducer to set the current order
        setCurrentOrder: (state, action) => {
            state.currentOrder = action.payload;
        },
        // Reducer to set the filter
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        // Reducer to add a scanned item
        addScannedItem: (state, action) => {
            console.log('Scanned item SLICE: ' + action.payload);
            const itemId = action.payload;
            const existingItem = state.scannedItems.find(item => item.productId === itemId);

            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                state.scannedItems.push({ productId: itemId, quantity: 1 });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.orders = action.payload;
                state.loading = false;
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions and reducer
export const { setCurrentOrder, setFilter, addScannedItem } = pickingSlice.actions;
export default pickingSlice.reducer;
