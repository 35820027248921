import {
  CANCEL_CBORDER,
  DELETE_CBORDER,
  FETCH_CBORDERS,
  RESET_CBORDERS,
  RESET_EDITED_CBORDER,
  SET_EDITED_CBORDER,
  SYNC_ORDERS,
  UPDATE_CBORDER,
} from "../actions/cbOrderActions";

const initialState = {
  data: null,
  loading: false,
  error: null,
  selectedProduct: null,
};

export const cbOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${DELETE_CBORDER}_SUCCESS`:
      return {
        ...state,
        orders: state.data.filter((order) => {
              return order._id !== action.payload
            }
        ),
      };

    case RESET_CBORDERS:
      return initialState;
      case SET_EDITED_CBORDER:
          return { ...state, editedOrder: action.payload };
      case RESET_EDITED_CBORDER:
          return { ...state, editedOrder: null };
      case `${UPDATE_CBORDER}_SUCCESS`:  // reset the edited order upon successful update
          return { ...state, editedOrder: null };
    case `${FETCH_CBORDERS}_REQUEST`:
      return { ...state, loading: true, error: null };
    case `${SYNC_ORDERS}_REQUEST`:
      return { ...state, loading: true, error: null };
    case `${SYNC_ORDERS}_SUCCESS`:
      //console.log(action.payload);
      return { ...state, loading: false, data: action.payload, error: null };
    case `${FETCH_CBORDERS}_SUCCESS`:
      //console.log(action.payload);
      return { ...state, loading: false, data: action.payload, error: null };
    case `${SYNC_ORDERS}_FAILURE`:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_CBORDER:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.orderId === action.payload.orderId ? action.payload : order
        ),
      };
    case CANCEL_CBORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.orderId === action.payload.orderId) {
            return {
              ...order,
              status: "cancelled",
              shouldRestock: action.payload.shouldRestock,
            };
          }
          return order;
        }),
      };

    default:
      return state;
  }
};
