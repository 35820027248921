import axios from 'axios';
import axiosRetry from "axios-retry";
import {notifyError} from "./index";
import {enqueueSnackbar} from "./cbuserActions";

axiosRetry(axios, { retries: 0 });
export const RESET_CBORDERS = 'RESET_CBORDERS';
export const FETCH_CBORDERS = 'FETCH_CBORDER';
export const UPDATE_CBORDER = 'UPDATE_CBORDER';
export const CREATE_CBORDER = 'CREATE_CBORDER';
export const DELETE_CBORDER = 'DELETE_CBORDER';
export const CANCEL_CBORDER = 'CANCEL_CBORDER';

export const SYNC_ORDERS = 'SYNC_ORDERS';
export const SEND_ORDERS = 'SEND_ORDERS';



// ... your other action types
export const SET_EDITED_CBORDER = 'SET_EDITED_CBORDER';
export const RESET_EDITED_CBORDER = 'RESET_EDITED_CBORDER';

export const setEditedCBOrder = (order) => ({
    type: SET_EDITED_CBORDER,
    payload: order
});

export const resetEditedCBOrder = () => ({
    type: RESET_EDITED_CBORDER
});

export const fetchOrders = (customerId,date) => async dispatch => {
    const url = `/api/cbuser/orders/${customerId}`;
    dispatch({ type: `${FETCH_CBORDERS}_REQUEST` });
    const options = {params: {fromDate: date}};
    try {
        // console.log("fetching orders for customer: " + customerId);
        let res = await axios.get(url,options);
        // console.log(res.data);
        dispatch({ type: `${FETCH_CBORDERS}_SUCCESS`, payload: res.data });
    } catch (e) {
        dispatch({ type: `${FETCH_CBORDERS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};

export const replaceCBOrder = (order) => async dispatch => {
    console.log('updating order: ' + JSON.stringify(order));
    const url = `/api/cbuser/orders`;  // Assuming that the inventory object has an id
    dispatch({ type: `${UPDATE_CBORDER}_REQUEST` });
    try {
        let res = await axios.put(url, order);
        dispatch({ type: `${UPDATE_CBORDER}_SUCCESS`, payload: res.data });
    } catch (e) {
        dispatch({ type: `${UPDATE_CBORDER}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};

export const syncOrders = (customerId) => async dispatch => {
    const url = `/api/cbuser/customer/${customerId}/orders/sync.json`;
    dispatch(enqueueSnackbar("syncing orders", "info", 2500, true));
    dispatch({ type: `${SYNC_ORDERS}_REQUEST` });
    try {
        console.log("fetching orders for customer: " + customerId);
        let res = await axios.post(url);
        //console.log(res.data);
        dispatch({ type: `${SYNC_ORDERS}_SUCCESS`, payload: res.data });
        dispatch(enqueueSnackbar("orders imported", "info", 2500, true));
    } catch (e) {
        dispatch({ type: `${SYNC_ORDERS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};
export const sendOrders = () => async (dispatch, getState) =>{
    const url = `/api/rm/orders`;
    const selectedOrders = getState().selectedOrders;
    //console.log("sending orders: " + JSON.stringify(selectedOrders));
    dispatch(enqueueSnackbar("sending orders to rm", "info", 2500, true));
    dispatch({ type: `${SEND_ORDERS}_REQUEST` });
    try {
        console.log("sending orders");
        let res = await axios.post(url, selectedOrders);

        dispatch({ type: `${SEND_ORDERS}_SUCCESS`, payload: res.data });
        dispatch(enqueueSnackbar("orders sent", "info", 2500, true));
    } catch (e) {
        dispatch({ type: `${SEND_ORDERS}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};

export const deleteCBOrder = (orderId) => async (dispatch, getState) =>{
    const url = `/api/cbuser/orders/${orderId}`;
    console.log("deleting order: " + url);
    dispatch(enqueueSnackbar("deleting order", "info", 2500, true));
    dispatch({ type: `${DELETE_CBORDER}_REQUEST` });
    try {
        let res = await axios.delete(url);
        dispatch({ type: `${DELETE_CBORDER}_SUCCESS`, payload: orderId });
        dispatch(enqueueSnackbar("deleted order", "info", 2500, true));
    } catch (e) {
        dispatch({ type: `${DELETE_CBORDER}_FAILURE`, payload: e.message });
        notifyError(e, dispatch);
    }
};

export const resetOrders = () => async dispatch => {
    dispatch({ type: RESET_CBORDERS });
};


