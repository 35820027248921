import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

function Copyright(props) {
  // noinspection RequiredAttributes
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <a color="inherit" href="https://copperbeechtrading.co.uk/" target={"_blank"}>
        Copper Beech Trading
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}


export default function SignIn(props) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = {username: data.get('username'), password: data.get('password')};
    //console.log(user);
    props.onSubmit(user);
  };

  return (

      <Container component="main" maxWidth="xs">
        <CssBaseline />
          <Paper>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <Avatar sx={{ m: 1, bgcolor: "primary.dark" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, ml:1 , mr: 1}}
          >
            <TextField
              margin="normal"
              required
              fullWidth={true}
              id="username"
              label="User Name"
              name="username"
              autoComplete="username"
              autoFocus
              variant={"standard"}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              variant={"standard"}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              fullWidth={true}
            >
              Sign In
            </Button>
            <Grid container spacing={2}>
              <Grid item xs>
                <Link to={"/forgotpassword"} variant="body2">
                  Forgot password
                </Link>
              </Grid>
              <Grid item xs>
                <Link to={"/forgotpassword"} variant="body2">
                  Reset password
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
              </Paper>
      </Container>

  );
}
