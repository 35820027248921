// React imports
import React, {useEffect} from "react";

// Redux imports
import {useDispatch, useSelector} from "react-redux";

// Component and Hook imports
import CustomerDashboard from "../customer/CustomerDashboard";
import useNotifier from "../useNotifier";

// UI components and style imports
import {Container, Paper} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import {
  fetchCustomerCosts,
  fetchCustomerInventoryTxSummary,
  fetchCustomerInvoices,
  fetchCustomerMovements,
  fetchCustomerOrders,
  fetchCustomerTasks,
  setSelectedDate
} from "../../actions/customerActions";
import Typography from "@mui/material/Typography";
import {setNavigate} from "../../utils/api";

function CustomerDashboardContainer() {
  const dispatch = useDispatch();
  useNotifier();
  let orders = [];
  const user = useSelector((state) => state.auth);
  const cbuser = useSelector((state) => state.cbuser);
  const products = useSelector((state) => state.inventory.summary);
  const cborder = useSelector((state) => state.cborder);
  const movements = useSelector((state) => state.customer.movements);
  const invoices = useSelector((state) => state.customer.invoices);
  const tasks = useSelector((state) => state.customer.tasks);
  const costs = useSelector((state) => state.customer.costs);
  const selectedDate = useSelector((state) => state.customer.selectedDate);

  if (cborder) {
    orders = cborder.data;
  }

  const navigate = useNavigate(); // Use useNavigate to get the navigation function

  // Set the reference to the navigate function using setNavigate
  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    if (!selectedDate) {  // Only set if no date is selected
      const firstDayOfMonth = new Date();
      firstDayOfMonth.setDate(1);
      dispatch(setSelectedDate(firstDayOfMonth.toISOString()));
    }

    if (user && user.cbCustomerId) {
      dispatch(fetchCustomerInvoices());
      dispatch(fetchCustomerOrders());
      dispatch(fetchCustomerInventoryTxSummary());
    }
  }, [user, selectedDate, dispatch]); // Including used values in the dependency array


  return (

      <Container >
        <Paper elevation={5}>
          <Typography variant={"h4"} align={"center"}>
            {user.cbCustomerName} Dashboard
          </Typography>
        </Paper>
        <CustomerDashboard
          inventoryList={products}
          invoiceList={invoices}
          orderList={orders}
          movementList={movements}
          taskList={tasks}
          costList={costs}
          fetchInventory={() => dispatch(fetchCustomerInventoryTxSummary())}
          fetchInvoices={() => dispatch(fetchCustomerInvoices())}
          fetchOrders={() => dispatch(fetchCustomerOrders())}
          fetchMovements={() => dispatch(fetchCustomerMovements())}
          fetchTasks={() => dispatch(fetchCustomerTasks())}
          fetchCosts={() => dispatch(fetchCustomerCosts())}
        />
        <Outlet />
      </Container>
  );
}

export default CustomerDashboardContainer;
