import React, {Component} from "react";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-theme-balham.css";
import {Box} from "@mui/material";
import PropTypes from "prop-types";

function getOrderDate(params) {
  const dateTime = params.data.orderDate.split("T");
  return dateTime[0];
}

function getDispatchDate(params) {
  const dateTime = params.data.dispatchDate.split("T");
  return dateTime[0];
}

function onFirstDataRendered(params) {
  params.api.sizeColumnsToFit();
}

class MovementGrid extends Component {
  colDefs = [
    {
      headerName: "Order Date",
      colId: "orderDate",
      width: 250,
      valueGetter: getOrderDate,
      sort: "desc",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
      sortable: true,
    },
    {
      field: "orderNumber",
      colId: "orderNumber",
      headerName: "Order Number",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
      sortable: true,
    },

    {
      headerName: "Dispatch Date",
      colId: "dispatchDate",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
      width: 250,
      valueGetter: getDispatchDate,
      sortable: true,
    },
    {
      field: "lastName",
      colId: "lastName",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
      sortable: true,
    },
    {
      field: "carrier",
      colId: "carrier",
      headerName: "Carrier",
      sortable: true,
    },
    {
      field: "trackingNumber",
      colId: "trackingNumber",
      headerName: "Tracking Number",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
      },
      sortable: true,
    },
    {
      valueFormatter: (params) => {
        if (typeof params.value === Number) {
          return params.value.toFixed(2);
        }
      },
      headerName: "Cost",
      field: "actualCharge",
      colId: "actualCharge",
      sortable: true,
      type: "rightAligned",
    },
    {
      headerName: "VAT",
      field: "vat",
      colId: "vat",
      sortable: true,
      type: "rightAligned",
    },
    {
      headerName: "Units",
      field: "units",
      colId: "units",
      editable: true,
      type: "rightAligned",
    },
  ];

  onGridReady = params => {
    if (this.props.setGridApi) {
      this.props.setGridApi(params.api);
    }
  }

  render() {
    return (
        <Box
          id="CBMovementsGrid"
          style={{ height: 500 }}
          className="ag-theme-balham"
        >
          <AgGridReact
            gridOptions={{
              domLayout: "normal",
              pagination: true,
              paginationPageSize: 50,
              getRowId: (params) => params.data._id,
              onFirstDataRendered: onFirstDataRendered,
            }}
            defaultColDef={{ flex: 1 }}
            columnDefs={this.colDefs}
            onGridReady={this.onGridReady}
            rowData={this.props.list}
            animateRows={true}
          />
        </Box>
    );
  }
}

MovementGrid.propTypes = {
  list: PropTypes.array.isRequired,
  setGridApi: PropTypes.func
};

export default MovementGrid;
