// CreateOrderContainer.js
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CreateOrder from '../customer/CreateOrder';
import {
  addItemThunk,
  clearOrderState,
  removeItem,
  resetOrderForm,
  saveOrderToServer,
  updateAddressField,
  updateOrderField,
  updateQuantity
} from '../../reducers/orderSlice';
import useNotifier from "../useNotifier";

const CreateOrderContainer = () => {
  const dispatch = useDispatch();
  useNotifier();
  const productInventory = useSelector((state) => state.inventory.summary);
  const selectedProducts = useSelector((state) => state.customerOrder.lineItems);
  const entireOrderState = useSelector((state) => state.customerOrder);

  const handleUpdateOrderField = (fieldData) => {
    dispatch(updateOrderField(fieldData));
  };

  const handleUpdateAddressField = (addressData) => {
    dispatch(updateAddressField(addressData));
  };

  const handleAddItem = async (productId) => {
   await  dispatch(addItemThunk(productId));
  };

  const handleRemoveItem = (productId) => {
    dispatch(removeItem(productId));
  };

  const handleUpdateQuantity = (productId, quantity) => {
    dispatch(updateQuantity({ id: productId, quantity }));
  };

  const handleSave = async () => {
    const result = await dispatch(saveOrderToServer());
    if (result.payload.status === 201) {
      dispatch(clearOrderState());
  };
    };

  const handleCancel = () => {
    dispatch(resetOrderForm());

  };

  return (
      <CreateOrder
          entireOrderState={entireOrderState}
          selectedProducts={selectedProducts}
          productInventory={productInventory}
          updateOrderField={handleUpdateOrderField}
          updateAddressField={handleUpdateAddressField}
          addItem={handleAddItem}
          removeItem={handleRemoveItem}
          updateQuantity={handleUpdateQuantity}
            handleSave={handleSave}
            handleCancel={handleCancel}
      />
  );
};

export default CreateOrderContainer;
