import axios from "axios";
import axiosRetry from "axios-retry";

export const GLR_FETCH_PRODUCTS = 'GLR_FETCH_PRODUCTS'
export const GLR_RECEIVED_PRODUCTS = 'GLR_RECEIVED_PRODUCTS'
export const GLR_FETCH_PRODUCT_COUNT = "GLR_FETCH_PRODUCT_COUNT"
export const GLR_RECEIVED_PRODUCT_COUNT = 'GLR_RECEIVED_PRODUCT_COUNT'
export const GLR_PRODUCT_PAGINATION = 'GLR_PRODUCT_PAGINATION'
axiosRetry(axios, { retries: 0 });
function setPagination(pagination){
    return {
        type: GLR_PRODUCT_PAGINATION,
        payload: pagination
    }
}
function receivedProductList(json) {
    return{
        type: GLR_RECEIVED_PRODUCTS,
        payload: json
    }
}

function fetchingProducts(){
    return{
        type: GLR_FETCH_PRODUCTS,
        payload: null
    }
}

function receivedProductCount(json) {
    return{
        type: GLR_RECEIVED_PRODUCT_COUNT,
        payload: json
    }
}


export const fetchProductsByParams = (params,pagination) => async (dispatch) => {
    //console.log("fetchProductsByParams");
    const url = "/api/products";

    try {
        //if no pagination then we assume this is a first call and need create the pagination state
        //before going on to call for the first page.
        if(!pagination){
            //console.log(`fetchProductCount ${JSON.stringify(params)}`);
            const countUrl = "/api/products/count";
            const countOptions = {
                params: params
            }
            //await dispatch(fetchProductCount());
            dispatch(fetchingProducts());
            let resCount = await axios.get(countUrl, countOptions);
            const count = resCount.data.count;
            const limit = 25;
            const pages = Math.ceil(count/limit);
            const newPagination = {
                limit: 25,
                pages: pages,
                offset: 0
            }
            //console.log(newPagination);
            //set pagination
            dispatch(setPagination(newPagination));
            dispatch(receivedProductCount(resCount.data));


        }
        //console.log(url);
        //deal with pagination. This is either provided as a parameter or we default to limit 50 from offset 0
        let offset = 0;
        let page = 1;
        if(pagination){
            page = pagination;
            offset = 25 * (page - 1);
        }
        // pagination to params
        let newParams = Object.assign({},params);
        if(!newParams){
         newParams = {offset: offset, limit: 25}
        }else{

            newParams["limit"] = 25;
            newParams["offset"] = offset;
        }
        //console.log(params);
        const options = {
            params: newParams
        }
        let res = await axios.get(url, options);
        //console.log(res.data);
        dispatch(receivedProductList(res.data));
    }catch (e){
        console.log(e.message);
    }

}
