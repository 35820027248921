import {RECEIVED_RELATED, UPDATE_EMAIL_ERROR, UPDATE_EMAIL_SUCCESS,} from "../actions/memberActions";

export default function member(state = null, action) {
    switch (action.type) {
        case RECEIVED_RELATED:
            console.log("received related need to store data ", action);
            return action.payload || false;

        case UPDATE_EMAIL_SUCCESS:
            // Update only the email field of the user object
            if (state && state._id === action.userId) {
                return { ...state, email: action.email };
            }
            return state;

        case UPDATE_EMAIL_ERROR:
            // Handle the error (e.g., log it or set an error field in state)
            console.error("Email update error for user ID:", action.userId, "Error:", action.error);
            return state; // Return existing state without modification

        default:
            return state;
    }
}
