import React from "react";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";

const CreateContact = ({
                        roles,
                        contact,
                        updateContactField,
                        handleSave,
                        handleCancel,
                    }) => {
console.log('roles', roles);
    const [selectedRoleType, setSelectedRoleType] = React.useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave(); // Call the handleSave function here
    };

    const handleSelectChange = (event) => {
        setSelectedRoleType(event.target.value);
        //console.log(event.target);
        updateContactField({
            fieldName: event.target.name, //need to use name not id for a select
            value: event.target.value,
        });
    };
    return (
        <Grid container>
            <Paper elevation={3} style={{ padding: "20px" }}>
                <Typography variant="h5" gutterBottom>
                    Create New Contact
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                required={true}
                                size={"small"}
                                fullWidth
                                value={contact?.name}
                                onChange={(e) =>
                                    updateContactField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={contact?.email}
                                onChange={(e) =>
                                    updateContactField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="phone"
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={contact?.phone}
                                onChange={(e) =>
                                    updateContactField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="role-type-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-type-select-label"
                                    id="role"
                                    name="role"
                                    value={selectedRoleType} label={"Role"} onChange={handleSelectChange}>
                                    {roles.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="otherRole"
                                label="Other Role"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={contact?.otherRole}
                                onChange={(e) =>
                                    updateContactField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid container justifyContent="flex-end" spacing={2} mt={2}>
                            <Grid item>
                                <Button variant="contained" color="primary" type={"submit"}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};

export default CreateContact;
