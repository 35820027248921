// theme.js


import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#d65c00',       // Enhanced Orange
            light: '#f0c0a1',      // Light Warm Orange
            dark: '#a64d00',       // Deep Rich Orange
            contrastText: '#fff',
        },
        secondary: {
            main: '#0073cc',
            light: '#3399ff',
            dark: '#004d99',
            contrastText: '#fff',
        },
        info: {
            main: '#2196F3',
            light: '#64b5f6',
            dark: '#1565c0',
            contrastText: '#fff',
        },
        warning: {
            main: '#ffcc00',
            light: '#ffdd33',
            dark: '#b29d00',
            contrastText: '#000',
        },
        error: {
            main: '#f44336',
            light: '#e57373',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        success: {
            main: '#4CAF50',
            light: '#81c784',
            dark: '#388e3c',
            contrastText: '#fff',
        },
    },
});


export default theme;


