// remember that some  customer actions are handled in Inventory and CBUser reducers

// reducer.js
import {
  FETCH_CUSTOMER_INVOICES,
  SET_SELECTED_DATE,
  UPDATE_COSTS,
  UPDATE_MOVEMENTS,
  UPDATE_TASKS,
} from "../actions/customerActions";

const initialState = {
  selectedDate: null,
  movements: [],
  tasks: [],
  costs: [],
  invoices: [],
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
      case `${FETCH_CUSTOMER_INVOICES}_SUCCESS`:
      return {
        ...state,
        invoices: action.payload,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
        movements: [],
      };
    case UPDATE_MOVEMENTS:
      return {
        ...state,
        movements: action.payload,
      };
    case UPDATE_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case UPDATE_COSTS:
      return {
        ...state,
        costs: action.payload,
      };
    default:
      return state;
  }
};
