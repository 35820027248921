/**
 * this component will contain 4 others.
 *
 * A member search bar
 * A list of matching members
 * a group summary set of fields (name etc)
 * buttons for moving users between the general list and the group
 *
 */

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import UserSearch from "../user/UserSearch";
import UserList from "../user/UserList";
import {
  addMembers,
  addOwners,
  delMembers,
  delOwners,
  fetchMembers,
  itemClicked,
  saveContainer,
  setContainerFields,
} from "../../actions/containerActions";
import {search} from "../../actions/UIActions";
import {Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import useNotifier from "../useNotifier";

function GroupManagementContainer () {
  const container = useSelector((state) => state.container);
  const dispatch = useDispatch();
  useNotifier();
  function handleSearch() {
    dispatch(fetchMembers());
  }
  function handleInputChanged(event) {
    dispatch(search(event.target.id, event.target.value));
  }
  function handleMemberClicked(event, username, title) {
    //console.log(event.nativeEvent.target);
    //dispatch the clicked action
    //this.props.dispatch(itemClicked(event.nativeEvent.target));
    dispatch(itemClicked(username, title));
  }
  function handleAddClicked() {
    dispatch(addMembers());
  }
  function handleDelClicked() {
    dispatch(delMembers());
  }

  function handleAddOwnerClicked() {
    dispatch(addOwners());
  }
  function handleDelOwnerClicked() {
 dispatch(delOwners());
  }

  function handleSave() {
   dispatch(saveContainer());
  }

  const centreStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let status;
  if (container.saved === "ok") {
    status = <div className={"light-green"}>saved</div>;
  } else if (container.saved === "nok") {
    status = <div className={"red"}>Not Saved</div>;
  } else {
    status = "";
  }

  return (
      <Grid container direction={"column"} spacing={2} p={2}>
        <Paper elevation={1}>
          <UserSearch
              search={handleSearch}
              inputChanged={handleInputChanged}
          />
          <Grid container direction={"row"} spacing={1} mt={2}>
            <Grid sm={3} item>
              <UserList
                  id={"members"}
                  title={"members"}
                  userList={container.members}
                  onUserSelected={handleMemberClicked}
              />
            </Grid>
            <Grid item container spacing={1} sm={2} direction={"column"}>
              <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleAddClicked}
                >
                  Add Member
                </Button>
              </Grid>
              <Grid item style={centreStyle}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleDelClicked}
                >
                  Del Member
                </Button>
              </Grid>
              <Grid item style={centreStyle}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleAddOwnerClicked}
                >
                  Add Owner
                </Button>
              </Grid>
              <Grid item style={centreStyle}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleDelOwnerClicked}
                >
                  Del Owner
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <UserList
                  id={"group"}
                  title={"Group"}
                  header={"Members"}
                  userList={container.group}
                  onUserSelected={handleMemberClicked}
              />
            </Grid>
            <Grid item>
              <UserList
                  title="Owners"
                  id={"owners"}
                  header={"Owners"}
                  userList={container.owners}
                  onUserSelected={handleMemberClicked}
              />
            </Grid>
          </Grid>
          <Paper >
            <Grid container direction={"row"} spacing={1}>
              <Grid item>
                <TextField
                    variant={'standard'}
                    className={"right"}
                    id={"name"}
                    label={"Name"}
                    onChange={(e) => {
                      dispatch(
                          setContainerFields(e.target.id, e.target.value)
                      );
                    }}
                />
              </Grid>
              <Grid item>
                <TextField
                    id={"type"}
                    label={"type"}
                    variant={"standard"}
                    onChange={(e) => {
                      dispatch(
                          setContainerFields(e.target.id, e.target.value)
                      );
                    }}
                />
              </Grid>
              <Grid item justifyContent={"center"}>
                <Button variant={"contained"} color={"primary"} onClick={handleSave} >
                  Save Container
                </Button>
              </Grid>
            </Grid>
            {status}
          </Paper>
        </Paper>
      </Grid>
  );

}

export default GroupManagementContainer;
