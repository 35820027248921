// noinspection JSUnusedGlobalSymbols

import axios from "axios";
import {
  FETCH_ALL_STUDENTS,
  FETCH_ALL_XOD_STUDENTS,
  FETCH_PRODUCTS,
  FETCH_USER,
  FETCH_XOD_ACHIEVEMENTS,
  FETCH_XOD_STUDENT,
  NEW_CATEGORY,
  SET_ORDER_USER,
  SET_STUDENT_PAGES,
  START_LOGIN,
  UPDATE_FAVS,
} from "./types";
import axiosRetry from "axios-retry";
import {closeSnackbar, enqueueSnackbar as enqueueSnackbarAction,} from "./snackActions";
import React, {Fragment} from "react";
import Button from "@mui/material/Button";

export const PWD_RESET_REQUESTED = "PWD_RESET_REQUESTED";
export const GLR_ERROR = "GLR_ERROR";
export const GLR_FEEDBACK = "GLR_FEEDBACK";
export const PROGRESS_ON = "PROGRESS_ON";
export const PROGRESS_OFF = "PROGRESS_OFF";
export const RESET_STORE = "RESET_STORE";

function setProgress(state) {
  if (state === true) {
    return {
      type: PROGRESS_ON,
      payload: null,
    };
  } else {
    return {
      type: PROGRESS_OFF,
      payload: null,
    };
  }
}

function passwordResetRequested() {
  return {
    type: PWD_RESET_REQUESTED,
    payload: null,
  };
}
export function GLRError(error) {
  return {
    type: GLR_ERROR,
    payload: error,
  };
}
export function GLRFeedback(data) {
  return {
    type: GLR_FEEDBACK,
    payload: data,
  };
}

/*
    remember dispatch is a function (it is the action dispatcher
    which we can use here because have imported reactThunk
    which will let us intercept the action dispatching process when we
    return a function from an action.

    we are using it here because we don't want to dispatch until after our
    async get has returned something - at which point we can trigger the refresh that
    goes with this


     */
axiosRetry(axios, { retries: 0 });

export const requestPasswordReset = (params) => async (dispatch) => {
  const url = "/passwordresetrequest";

  try {
    dispatch(passwordResetRequested());
    dispatch(GLRFeedback(null)); //reset redux to blank
    dispatch(GLRError(null)); //reset to blank
    dispatch(setProgress(true));
    const response = await axios.post(url, params);
    dispatch(
      GLRFeedback({
        message:
          "An email has been sent to the provided address. Please follow the instructions in the email.",
        status: response.status,
      })
    );
    dispatch(setProgress(false));
  } catch (e) {
    console.log(e.response.data);
    dispatch(setProgress(false));
    dispatch(GLRError(e.response.data));
  }
};

export const sendPasswordUpdate = (params) => async (dispatch) => {
  const url = "/passwordreset";
  try {
    dispatch(passwordResetRequested());
    dispatch(GLRFeedback(null)); //reset redux to blank
    dispatch(GLRError(null)); //reset to blank
    dispatch(setProgress(true));
    await axios.post(url, params);
    dispatch(setProgress(false));
    dispatch(
      GLRFeedback({
        message:
          "password updated please go to the login page to login with your new password",
        status: 200,
      })
    );
  } catch (e) {
    console.log(e.response.data);
    dispatch(setProgress(false));
    dispatch(GLRError(e.response.data));
  }
};

export const fetchUser = () => async (dispatch) => {
  try {
    let res = await axios.get("/api/current_user");
    //console.log("in fetchUser",res.data);
    dispatch({ type: FETCH_USER, payload: res.data });
    // i dont want to reset the selected user if this is  guardian as the guardian does not have a student
    // section and so the populating the member order section will fail
    // this happens when a guardian deletes an item from the kids order and then the whole refresh kicks in
    // to refresh the lizard points etc.
    if (res && res.data) {
      if (!res.data.roles.includes("guardian")) {
        dispatch({ type: SET_ORDER_USER, payload: res.data });
      }
    }
  } catch (e) {
    console.log(e);
    dispatch(GLRError({ errorMessage: e.message }));
  }
};

export const handleToken = (token) => async (dispatch) => {
  const res = await axios.post("/api/stripe", token);
  dispatch({ type: FETCH_USER, payload: res.data });
};

/* this is the shopify fetch products route */

export const fetchProducts = (cursor, backward) => async (dispatch) => {
  let url = "/api/products";
  if (cursor) {
    url = url + "?cursor=" + cursor;
  }
  if (backward) {
    if (!cursor) {
      url = url + "?backward=" + backward;
    } else {
      url = url + "&backward=" + backward;
    }
  }
  const res = await axios.get(url);
  dispatch({ type: FETCH_PRODUCTS, payload: res.data });
};

export const submitCategory = (values) => async (dispatch) => {
  //console.log("in submitCategory action");
  const res = await axios.post("/api/categories", values);
  //console.log("in submitCategory");
  dispatch({ type: NEW_CATEGORY, payload: res.data });
};

export const updateFavourite = (userId, value, add) => async (dispatch) => {
  //axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  //console.log(process.env.REACT_APP_API_URL);
  //console.log(axios);
  const instance = axios.create({
    baseURL: "/",
  });
  let url = "api/users/" + userId + "/favourites";
  let param = { _rewardId: value };
  let res = {};
  try {
    if (add) {
      res = await instance.post(url, param);
      //console.log(res);
    } else {
      url = url + "/" + value;
      console.log(url);
      res = await instance.delete(url);
    }
    await dispatch(fetchUser());
    dispatch({ type: UPDATE_FAVS, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};
export const submitLogin = (values) => async (dispatch) => {
  try {
    const res = await axios.post("/login", values);
    dispatch({ type: START_LOGIN, payload: res.data });
    dispatch({ type: SET_ORDER_USER, payload: res.data });
  } catch (e) {
    console.log(e);
    alert("Login Failed: " + e.response.data.message);
    notifyError(e, dispatch);
  }
};
export const submitEmail = (values, history) => async (dispatch) => {
  const res = await axios.post("/api/surveys", values);
  history.push("/admin");
  console.log("in submitEmail");
  dispatch({ type: NEW_CATEGORY, payload: res.data });
};

export const fetchAllStudents = () => async (dispatch) => {
  const res = await axios.get("/api/students/all");
  dispatch({ type: FETCH_ALL_STUDENTS, payload: res.data });
};

export const fetchAllXODStudents =
  (schoolId, page, limit) => async (dispatch) => {
    let url = "/api/Students/School/" + schoolId;
    if (page) {
      url += "/?page=" + (page - 1);
    }
    if (!page && limit) {
      url += "/?limit=" + limit;
    } else {
      if (limit) {
        url += "&limit=" + limit;
      }
    }
    //const url = "/api/Students/School/" + schoolId;
    const res = await axios.get(url);
    dispatch({ type: FETCH_ALL_XOD_STUDENTS, payload: res.data });
  };

export const fetchXODStudentCount = (schoolId) => async (dispatch) => {
  let url = "/api/StudentsCount/School/" + schoolId;
  const res = await axios.get(url);
  dispatch({ type: SET_STUDENT_PAGES, payload: res.data });
};

export const fetchXODStudent = (schoolId, studentId) => async (dispatch) => {
  const url = "/api/School/" + schoolId + "/Student/" + studentId;
  const res = await axios.get(url);
  dispatch({ type: FETCH_XOD_STUDENT, payload: res.data });
};

export const fetchXODStudentAchievements =
  (schoolId, studentId) => async (dispatch) => {
    const url =
      "/api/School/" +
      schoolId +
      "/Student/" +
      studentId +
      "/AchievementSummary";
    const res = await axios.get(url);

    dispatch({ type: FETCH_XOD_ACHIEVEMENTS, payload: res.data });
  };

export const resetStore = () => async (dispatch) => {
  dispatch({ type: RESET_STORE, undefined });
};
export function notifyError(e, dispatch) {
  console.log(e);
  const message = e.response.data.message ? e.response.data.message : e.message;
  dispatch(
    enqueueSnackbarAction({
      message: " the reason given was " + message,
      options: {
        variant: "error",
        autoHideDuration: null,
        action: (key) => (
          <Fragment>
            <Button
              size="small"
              onClick={() => {
                dispatch(closeSnackbar(key));
              }}
            >
              Dismiss
            </Button>
          </Fragment>
        ),
      },
    })
  );
}
