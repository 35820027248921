import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Container from "@mui/material/Container";
import {useSelector} from "react-redux";
import {Grid} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

import Button from "@mui/material/Button";


function ResponsiveAppBar() {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart.cart);

  const renderLogin = () => {
    return (
      <Button>
        <Link to={"/"} key="login" component={RouterLink}>
          <Typography color={"white"}>Login </Typography>
        </Link>
      </Button>
    );
  };
  const renderProfileLink = () => {
    if (auth) {
      return (
        <Button>
          <Link component={RouterLink} to={"/profile"} key={"profile"}>
            <Typography color={"white"}>
              {auth.firstName}
            </Typography>
          </Link>
        </Button>
      );
    }
  };
  const renderGLRPoints = () => {
    if (auth) {
      if (auth._student) {
        return (
          <Typography color={"white"}>
            {"Lizard Cards: " + auth._student.currentPoints}
          </Typography>
        );
      } else {
        return <Typography color={"white"}></Typography>;
      }
    }
  };
  const renderRewardCabinet = () => {
    if (auth) {
      if (
        auth.roles.some((role) => {
          return ["admin", "member", "guardian"].includes(role);
        })
      ) {
        return (
          //<NavItem key={"cabinet"} className="sidenav-close">
          //</NavItem>
          //<NavItem key={"cabinet"} href="cabinet" className="sidenav-close">Reward Cabinet </NavItem>
          (<Button key={"cab"}>
            <Link component={RouterLink} to={"/cabinet"}>
            <Typography color={"white"}>Reward Cabinet </Typography>
            </Link>
          </Button>)
        );
      }
    }
  };
  const renderCartLink = () => {
    if (cart) {
      if (cart.length > 0) {
        //console.log(this.props.cart);
        return (
          //<NavItem className={"sidenav-close"}>
          //</NavItem>
          (<Button  key={"cart"}>
            <Link component={RouterLink} to={"/shop/cart"}>
              <ShoppingCartIcon />
            </Link>
          </Button>)
        );
      }
    }
  };
  const renderMyOrder = () => {
    if (auth) {
      if (auth.roles.includes("member")) {
        return (
          //<NavItem key={"lineItems"} className="sidenav-close">
          //</NavItem>
          (<Button  key={"orders"}>
            <Link component={RouterLink} to={"/lineitems"}>
            <Typography color={"white"}>Orders </Typography>
            </Link>
          </Button>)
        );
      }
    }
  };
  const renderCBUser = () => {
    if (auth) {
      if (auth.roles.includes("cbuser")) {
        return (
          //<NavItem key={"glradmin"} className="sidenav-close">
          ([<Button key={"cbuser_dashboard"}>
            <Link component={RouterLink} to={"/cbuser"}>
            <Typography color={"white"}>Copper Beech </Typography>
            </Link>
          </Button>])
        );
      }
    }
  };
  const renderGuardian = () => {
    if (auth) {
      if (auth.roles.includes("guardian")) {
        return (
          //<NavItem key={"guardian"} className="sidenav-close">
          // </NavItem>
          //<NavItem key={"guardian"} href="/guardian" className="sidenav-close">Guardian Board </NavItem>
          (<Button key={"guardian"}>
            <Link component={RouterLink} to={"/guardian"}>
            <Typography color={"white"}>Guardian Board </Typography>
            </Link>
          </Button>)
        );
      }
    }
  };
  const renderAdminItem = () => {
    if (auth) {
      if (!auth.roles.includes("admin")) {
      } else {
        return [
          <Button key={"admin"}>
            <Link component={RouterLink} to={"/admin"}>
            <Typography color={"white"}>Centre Dashboard </Typography>
            </Link>
          </Button>,
        ];
      }
    }
  };
  const renderSuperAdmin = () => {
    if (auth) {
      if (auth.roles.includes("glradmin")) {
        return (
          //<NavItem key={"glradmin"} className="sidenav-close">
          // </NavItem>
          //<NavItem key={"glradmin"} href="glradmin" className="sidenav-close">Admin Dashboard </NavItem>
          ([<Button key={"admin_dashboard"}>
            <Link component={RouterLink} to={"/glradmin"}>
            <Typography color={"white"}>GLR Dashboard </Typography>
            </Link>
          </Button>])
        );
      }
    }
  };

  const renderContentTop = () => {
    switch (auth) {
      case null:
        return renderLogin();
      case false:
        return renderLogin();
      default:
        return [
          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
            key={"core-menu"}
          >
            <Grid container direction={"row"} spacing={1} ml={2}>
              <Grid item>{renderRewardCabinet()} </Grid>
              <Grid item>{renderMyOrder()} </Grid>
              <Grid item>{renderGuardian()} </Grid>
              <Grid item>{renderAdminItem()} </Grid>
              <Grid item>{renderSuperAdmin()} </Grid>
              <Grid item>{renderCBUser()} </Grid>
              <Grid item>{renderCartLink()} </Grid>
            </Grid>
          </Box>,
          <Box sx={{ flexGrow: 0 }} key={"right-menu"}>
            <Grid container direction={"row"} spacing={1}>
              <Grid item>{renderGLRPoints()}</Grid>
              <Grid item>{renderProfileLink()}</Grid>
              <Grid item>
                <Button href="/api/logout" key={"logout"}>
                  <Typography color={"white"}>Logout </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>,
        ];
    }
  };

  return (
      <AppBar position="static" color={"primary"}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton edge="start" color="inherit" aria-label="home" component="a" href="/">
              <img src={"/Copper-Beech-Trading-Header-Logo.png"} alt="Home" style={{ height: '50px', width: 'auto' }} />
            </IconButton>
            {renderContentTop()}
          </Toolbar>
        </Container>
      </AppBar>
  );

}

export default ResponsiveAppBar;
