import axios from "axios";
import axiosRetry from "axios-retry";
import {notifyError} from "./index";

axiosRetry(axios, { retries: 0 });
export const RESET_INVENTORY = "RESET_INVENTORY";
export const FETCH_INVENTORY = "FETCH_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const CREATE_INVENTORY = "CREATE_INVENTORY";
export const FETCH_INVENTORY_TX_SUMMARY = "FETCH_INVENTORY_TX_SUMMARY";
// in your actionTypes file
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const SAVE_EDIT_PRODUCT = "SAVE_EDIT_PRODUCT";
export const CANCEL_EDIT_PRODUCT = "CANCEL_EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const BATCH_UPDATE_REQUEST = "BATCH_UPDATE_REQUEST";
export const BATCH_UPDATE_SUCCESS = "BATCH_UPDATE_SUCCESS";
export const BATCH_UPDATE_FAILURE = "BATCH_UPDATE_FAILURE";
export const CLEAR_PRODUCT_CHANGES = "CLEAR_PRODUCT_CHANGES";

// cbinventoryActions.js

export function editProduct(id, fieldId, text) {
  const payload = { productId: id, fieldId: fieldId, value: text };
  return {
    type: EDIT_PRODUCT,
    payload: payload,
  };
}

export const fetchInventory = (customerId) => async (dispatch) => {
  const url = `/api/cbuser/inventory/${customerId}`;
  dispatch({ type: `${FETCH_INVENTORY}_REQUEST` });
  try {
    //console.log("fetching inventory for customer: " + customerId);
    let res = await axios.get(url);
    //console.log(res.data);
    dispatch({ type: `${FETCH_INVENTORY}_SUCCESS`, payload: res.data });
  } catch (e) {
    dispatch({ type: `${FETCH_INVENTORY}_FAILURE`, payload: e.message });
    notifyError(e, dispatch);
  }
};

export const fetchInventoryTxSummary = (customerId) => async (dispatch) => {
  const url = `/api/cbuser/inventory/${customerId}/transactions/summary`;
  //console.log("fetching inventory tx summary for customer: " + customerId);
  try {
    dispatch({ type: `${FETCH_INVENTORY_TX_SUMMARY}_REQUEST` });
    let res = await axios.get(url);
    dispatch({
      type: `${FETCH_INVENTORY_TX_SUMMARY}_SUCCESS`,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: `${FETCH_INVENTORY_TX_SUMMARY}_FAILURE`,
      payload: e.message,
    });
    notifyError(e, dispatch);
  }
};
export const updateInventory = () => async (dispatch, getState) => {
  const inventory = getState().inventory;
  const url = `/api/cbuser/inventory/${inventory.data.customerId}`; // Assuming that the inventory object has an id
  dispatch({ type: `${UPDATE_INVENTORY}_REQUEST` });
  try {
    //console.log("inventory to update: " + JSON.stringify(inventory.data));
    let res = await axios.put(url, inventory);
    await dispatch({ type: `${UPDATE_INVENTORY}_SUCCESS`, payload: res.data });
    await dispatch(fetchInventoryTxSummary(inventory.data.customerId));
  } catch (e) {
    dispatch({ type: `${UPDATE_INVENTORY}_FAILURE`, payload: e.message });
    notifyError(e, dispatch);
  }
};

export const updateProducts = () => async (dispatch, getState) => {
  const inventory = getState().inventory;
  const url = `/api/cbuser/inventory/products/${inventory.data.customerId}`; // Assuming that the inventory object has an id
  dispatch({ type: `${UPDATE_INVENTORY}_REQUEST` });
  try {
    //console.log("inventory to update: " + JSON.stringify(inventory.data));
    let res = await axios.put(url, inventory.updatedProducts);
    await dispatch({ type: `${UPDATE_INVENTORY}_SUCCESS`, payload: res.data });
    await dispatch(fetchInventoryTxSummary(inventory.data.customerId));
  } catch (e) {
    dispatch({ type: `${UPDATE_INVENTORY}_FAILURE`, payload: e.message });
    notifyError(e, dispatch);
  }
};
export const createInventory = (inventory) => async (dispatch) => {
  const url = "/api/inventory";
  dispatch({ type: `${CREATE_INVENTORY}_REQUEST` });
  try {
    let res = await axios.post(url, inventory);
    dispatch({ type: `${CREATE_INVENTORY}_SUCCESS`, payload: res.data });
  } catch (e) {
    dispatch({ type: `${CREATE_INVENTORY}_FAILURE`, payload: e.message });
    notifyError(e, dispatch);
  }
};

export const clearSelectedProduct = () => ({
  type: "CLEAR_SELECTED_PRODUCT",
});

export const resetInventory = () => ({
  type: RESET_INVENTORY,
});

export const TOGGLE_STOCK_TAKE_MODE = "TOGGLE_STOCK_TAKE_MODE";

export const toggleStockTakeMode = () => {
  return {
    type: TOGGLE_STOCK_TAKE_MODE,
  };
};
export const TOGGLE_RETURNS_MODE = "TOGGLE_RETURNS_MODE";
export const toggleReturnsMode = (product) => {
  return {
    type: TOGGLE_RETURNS_MODE,
    payload: product,
  };
};

export const CREATE_RETURN = "CREATE_RETURN";
export const createReturn = (productId, quantity) => {

  return {
    type: CREATE_RETURN,
    payload: { productId: productId, quantity: quantity, transactionType: 'return' },
  };
};
export const SEND_RETURN = "SEND_RETURN";
export const SEND_RETURN_REQUEST = "SEND_RETURN_REQUEST";
export const SEND_RETURN_FAILURE = "SEND_RETURN_FAILURE";
export const SEND_RETURN_SUCCESS = "SEND_RETURN_SUCCESS";
export const sendReturn = () => async (dispatch, getState) => {
  const inventoryId = getState().inventory.data._id;
  const productTx = getState().inventory.returnData;
  const customerId = getState().inventory.data.customerId;
  const customerName = getState().inventory.data.customerName;

  const url = `/api/cbuser/inventory/${inventoryId}/returns`;
  dispatch({ type: SEND_RETURN_REQUEST });
  try {
    let res = await axios.post(url, {
      customerName: customerName,
      customerId: customerId,
      productTx: productTx,
    });
    dispatch({ type: SEND_RETURN_SUCCESS, payload: res.data });
    await dispatch(fetchInventoryTxSummary(customerId));
    dispatch(toggleReturnsMode());
  } catch (e) {
    dispatch({ type: SEND_RETURN_FAILURE, payload: e.message });
    notifyError(e, dispatch);
  }
};
