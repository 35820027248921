import {FETCH_USER, START_LOGIN} from "../actions/types";
import {RESET_STORE} from "../actions";


export default function auth(state = null, action) { // jshint ignore:line
  switch (action.type) {

    case FETCH_USER:
      //console.log("in fetch user",action);
      return action.payload || false;
    case START_LOGIN:
      //console.log("action payload: ",action.payload);
      return action.payload || false;
    case RESET_STORE:
      return  false;
    default:
      return state;
  }
}
