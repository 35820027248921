import React, {Component} from "react";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import PropTypes from "prop-types";
import {Grid, Typography} from "@mui/material";


function onFirstDataRendered(params){
    params.api.sizeColumnsToFit();
}

const onGridReady = () => {

    //console.log(`onGridReady: no code`);
};
class CustomerInvoiceGrid extends Component {

    colDefs = [
        {
            headerName: "Month",
            field: 'month',
            sortable: true
        },
        {
            field: "year",
            headerName: "Year",
            sortable: true
        },
        {
            field: "_id",
            headerName: "ID",
            cellRenderer: function (params) {
                 let ref = `/api/customer/invoices/${params.data._id}`;
                // noinspection HtmlUnknownAttribute
                return <span><a href={ref} target="_blank" node="a" rel="noreferrer">
          ${params.data._id}
        </a></span>;

            }
        },

    ];
    render() {
        return (
            <Grid  container direction={"column"} className={"glr-datagrid container"}>
                <Grid item >
                    <Typography variant={"h4"} align={"center"}>Invoices</Typography>

                </Grid>
                <Grid item
                    id="invoice-grid"
                    style={{ height: 200, width:"100%"}}
                    className="ag-theme-balham"
                >
                    <AgGridReact
                        onGridReady={onGridReady}
                        gridOptions={{
                            domLayout: "normal",
                            pagination: true,
                            paginationPageSize: 10,
                            getRowId:  params => params.data._id,
                            onFirstDataRendered: onFirstDataRendered
                        }}
                        columnDefs={this.colDefs}
                        rowData={this.props.list}
                        animateRows={false}
                    />
                </Grid>
            </Grid>
        );
    }

}

CustomerInvoiceGrid.propTypes = {
    id: PropTypes.string,
    header: PropTypes.string,
    list: PropTypes.array.isRequired,
    onDeleteClicked: PropTypes.func,
    isGLRAdmin: PropTypes.bool
};

export default CustomerInvoiceGrid;
