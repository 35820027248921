import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";

export default class OrderDetailCommands extends Component {
  render() {

    return (
        <Grid container direction={"row"} spacing={1} mt={2} justifyContent={"flex-end"}>

            <Grid item sm={1}>
              <Button variant={"contained"} color={"primary"}
                      disabled={false}
              onClick={() => {this.props.deleteClick()}}>Delete</Button>
            </Grid>
        </Grid>
    );
  }
}
OrderDetailCommands.propTypes = {
  deleteClick: PropTypes.func.isRequired
};
