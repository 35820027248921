// adminEventsSlice.js
import {createSlice} from '@reduxjs/toolkit';

const adminEventsSlice = createSlice({
    name: 'adminEvents',
    initialState: {
        events: [],
    },
    reducers: {
        addAdminEvent: (state, action) => {
            state.events.push(action.payload);
        },
    },
});

export const { addAdminEvent } = adminEventsSlice.actions;

export default adminEventsSlice.reducer;
