import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SendIcon from '@mui/icons-material/Send';

class CredentialsForm extends Component {
  render() {
    return (
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h6"}>Change Password</Typography>
        </Grid>
        <Grid item>


            <form
              title={"Title"}
              name={"pwdform"}
              id={"pwdform"}
              onChange={e => {
                this.props.onChange(e);
              }}
            >
              <Grid container direction={"row"} spacing={1}>
                <Grid item>
              <TextField
                id={"oldPwd"}
                name={"oldPwd"}
                variant={"standard"}
                defaultValue={this.props.oldPwd}
                label={"Current Password"}
                type={"password"}
              />
                </Grid>
                <Grid item>
              <TextField
                id={"newPwd"}
                name={"newPwd"}
                variant={"standard"}
                defaultValue={this.props.newPwd}
                label={"New Password"}
                type={"password"}
              />
                </Grid>
                <Grid item>
              <TextField
                id={"confirmPwd"}
                name={"confirmPwd"}
                variant={"standard"}
                defaultValue={this.props.newPwd}
                label={"Confirm Password"}
                type={"password"}
              />
                </Grid>
                <Grid item>
              <Button
                  variant={"contained"}
                  color={"primary"}
                type={"submit"}
                  startIcon={<SendIcon />}
                onClick={e => {
                  e.preventDefault();
                  this.props.onSubmit(e);
                }}
              >
                Change Password
              </Button>
                </Grid>
              </Grid>
            </form>

        </Grid>
      </Grid>
    );
  }
}

CredentialsForm.propTypes = {
  userId: PropTypes.string,
  oldPwd: PropTypes.string,
  newPwd: PropTypes.string,
  confirmPwd: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};
export default CredentialsForm;
