import React, {Component} from "react";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-theme-balham.css";

import PropTypes from "prop-types";
import {Box, Button} from "@mui/material";
import InventoryButtonsRenderer from "./InventoryButtonsRenderer";

function getUpdatedDate(params) {
    const dateTime = params.data.updatedDate.split("T");
    return dateTime[0];
}
function onFirstDataRendered(params){
    //params.api.sizeColumnsToFit();
    params.columnApi.autoSizeColumn('productTitle');

}

class ProductGrid extends Component {
    constructor(props){
        super(props);
        this.onGridReady = this.onGridReady.bind(this);
        this.colDefs = [
            {
                field: "SKU",
                colId: "SKU",
                headerName: "SKU",
                width: 80,
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                },
                sortable: true
            },
            {
                field: "productTitle",
                colId: "productTitle",
                headerName: "Name",
                filterParams: {
                    buttons: ['reset', 'apply'],
                },
                sortable: true,
                sort: 'asc',
                cellClass: 'wrap-text',
                filter: true,
                floatingFilter: true,
                autoHeight: true,
                width: 200,

            },
            {
                field: "originalQuantity",
                colId: "originalQuantity",
                headerName: "Stock Take Quantity",
                width: 100,
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                },
                sortable: true
            },
            {
                field: "currentQuantity",
                colId: "currentQuantity",
                headerName: "Current Quantity",
                width: 100,
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                },
                sortable: true
            },
            {
                field: "numberOfTransactions",
                colId: "numberOfTransactions",
                headerName: "Tx Count",
                width: 80,
                sortable: true
            },
            {
                headerName: "updatedDate",
                colId: "updatedDate",
                valueGetter: getUpdatedDate,
                sort: 'desc',
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                },
                sortable: true
            }
            ];



    }

    addActions() {
        if (this.props.showActions) {
            this.colDefs = this.colDefs.filter(col => col.field !== "Actions"); //avoid duplicate columns
            this.colDefs.push({
                field: "Actions",
                pinned: "right",
                cellRenderer: "inventoryButtonsRenderer",
                cellRendererParams: {
                    onReturnClicked: this.props.onReturnClicked,
                },
                width: 100,
            });
        }
    }
    onGridReady = params => {
        if (params.api) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            // Auto-size columns
            this.autoSizeColumns();

            // Expose the gridApi to parent components
            this.setApiRef();
        }
    };

    autoSizeColumns = () => {
        // Auto-size all columns
        this.gridApi.sizeColumnsToFit();
        // Auto-size specific column to fit its content
        this.gridColumnApi.autoSizeColumn('productTitle');
    };

    setApiRef = () => {
        if (this.props.gridApiRef) {
            this.props.gridApiRef.current = this.gridApi;
        } else if (this.props.setGridApiRef) {
            this.props.setGridApiRef(this.gridApi);
        }
    };


    componentDidUpdate(prevProps) {
        // Compare the new props with the previous props
        if(!this.gridApi) return;
        if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) {
            // If the list prop has changed, resize the columns
            this.gridApi.sizeColumnsToFit();
            this.gridColumnApi.autoSizeColumn('productTitle');
        }
    }

    exportToCSV = () => {

        if (this.gridApi) {
            const params = {
                fileName: 'products.csv',
                // Add additional params here if needed
            };
            this.gridApi.exportDataAsCsv(params);
        }
    };

    render() {
        this.addActions();
        return (
            <div
                 id="cb-grid"
                 style={{ height: 500, width: "100%" }}
                 className="ag-theme-balham"
            >
                {/* Add this button for CSV Export */}
                {/* Wrap the Button in a Box and move it to the right */}
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button variant="contained" color="primary" onClick={this.exportToCSV}>
                        Export to CSV
                    </Button>
                </Box>
                <AgGridReact
                    onCellValueChanged={event => {
                        //console.log(event);
                        this.props.onCellValueChanged(event);
                        setTimeout(function() {  event.api.redrawRows(event.node); }, 0);
                    }}
                    style={{ height: "100px" }}
                    suppressAutoSize={false}
                    gridOptions={{
                        headerHeight: 50,
                        domLayout: "normal",
                        pagination: true,
                        paginationPageSize: 50,
                        getRowId:  (params) => {
                            //console.log(params);
                            return params.data.productId},
                        onFirstDataRendered: onFirstDataRendered
                    }}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        editable: true,
                        cellStyle: { whiteSpace: 'normal' },
                        autoHeight: true,
                    }}
                    columnDefs={this.colDefs}
                    rowData={this.props.list}
                    onGridReady={this.onGridReady}
                    suppressClickEdit={true}
                    components={{
                        inventoryButtonsRenderer: InventoryButtonsRenderer,
                    }}
                />
            </div>
        );
    }

}

ProductGrid.propTypes = {
    showActions: PropTypes.bool,
    header: PropTypes.string,
    list: PropTypes.array.isRequired,
    onCellValueChanged: PropTypes.func,
    onReturnClicked: PropTypes.func,
    stockTakeMode: PropTypes.bool,
    gridApiRef: PropTypes.object, // used bu function parents to get gridApi
    setGridApiRef: PropTypes.func //used by class parents to get gridApi
};

ProductGrid.defaultProps = {
    showActions: true,
    stockTakeMode: false
};

export default ProductGrid;
