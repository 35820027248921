import {useDispatch, useSelector} from "react-redux";
import {useContext, useState} from "react";
import {setUsername} from "../../actions/chatActions";
import {WebSocketContext} from "../../utils/WebSocket";

function ChatRoom() {
  const [usernameInput, setUsernameInput] = useState("");
  const [msgInput, setMsgInput] = useState("");
  const room = useSelector((state) => state.chat.room);
  const username = useSelector((state) => state.chat.username);
  const chats = useSelector((state) => state.chat.chatLog);
  const dispatch = useDispatch();

  const ws = useContext(WebSocketContext);
  function enterRoom() {
    dispatch(setUsername(usernameInput));
  }
  const sendMessage = () => {
    console.log(ws.socket);
    ws.sendMessage(room.id, {
      username: username,
      message: msgInput,
    });
  };
    return (
        <div>
            <h3>{room.name} ({room.id})</h3>
            {!username &&
                <div className="user">
                    <input type="text" placeholder="Enter username" value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} />
                    <button onClick={enterRoom}>Enter Room</button>
                </div>
            }
            {username &&
                <div className="room">
                    <div className="history" style={{width:"400px", border:"1px solid #ccc", height:"100px", textAlign: "left", padding: "10px", overflow: "scroll"}}>
                        {chats.map((c, i) => (
                            <div key={i}><i>{c.username}:</i> {c.message}</div>
                        ))}
                    </div>
                    <div className="control">
                        <input type="text" value={msgInput} onChange={(e) => setMsgInput(e.target.value)} />
                        <button onClick={sendMessage}>Send</button>
                    </div>
                </div>
            }

        </div>
    );
}
export default ChatRoom;
