// Note: Uncomment import lines during working with JSX Compiler.
import React, { Component } from "react";
import PropTypes from "prop-types";

class Feedback extends Component {


    render() {
        let classNameValues = "container " + this.props.status;
        return (

            <div className={classNameValues}>
                <h5 className={"center"}>{this.props.message}</h5>
            </div>
        );
    }
}

Feedback.propTypes = {
    message: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired

}
export default Feedback;
