import {
  CENTRE_DRAFT_ORDER_CLONED,
  CENTRE_MODAL_FLAG_CHANGED,
  CENTRES_SORTED,
  FETCH_CENTRES,
  LINK_USER_TO_CENTRE,
  POST_CENTRE_DONE,
  RECEIVED_CENTRES
} from "../actions/centreActions";

function centre(state = null, action) {
  switch (action.type) {
    case CENTRE_MODAL_FLAG_CHANGED:
      return Object.assign({}, state,{editModal: action});
    case CENTRE_DRAFT_ORDER_CLONED:
      //console.log("CENTRE_DRAFT_ORDER_CLONED");
      let newCentres = state.centres.map(line => {
        if (line._id === action.payload) {
          return Object.assign({}, line, { confirmed: true });
        } else {
          return line;
        }
      });
      return Object.assign({}, state, { centres: newCentres });
    case CENTRES_SORTED:
      return Object.assign({}, state, { centres: action.payload });
    case FETCH_CENTRES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVED_CENTRES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        centres: action.payload
      });
    case LINK_USER_TO_CENTRE:
      //find the related centre
      if (!state.centres) {
        return state;
      }
      let items = state.centres.map(centre => {
        if (centre._id === action.payload.relatedItem) {
          centre["selectedUser"] = action.payload.value;
        }
        return centre;
      });
      return Object.assign({}, state, {
        centres: items
      });
    case POST_CENTRE_DONE:
      //copy the values from the ui state into the right centre (saves us retrieving the whole list)
      const centre = action.payload;
      //console.log(centre);
      let centres = state.centres.map(line => {
        if (line._id === centre._id) {
          return Object.assign({}, line, centre);
        } else {
          return line;
        }
      });
      return Object.assign({}, state, { centres: centres });
    default:
      return state;
  }
}

export default centre;
