import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CredentialsForm from "../user/CredentialsForm";
import {search, setOpenCredentialsForm} from "../../actions/UIActions";
import {changePwd} from "../../actions/memberActions";
import * as PropTypes from "prop-types";
import useNotifier from "../useNotifier";

function CredentialsFormContainer ({delegateSubmit}){
  //the current user
const navigate = useNavigate();
  useNotifier();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.auth);

  function handleChange(event) {
    dispatch(search(event.target.id, event.target.value));

  }
  function handleSubmit() {
    console.log('Credential');
    dispatch(changePwd(navigate()));
  }
  useEffect(() => {dispatch(setOpenCredentialsForm());},[dispatch]);
  return (
      <CredentialsForm
          userId={user._id}
          oldPwd={""}
          newPwd={""}
          confirmPwd={""}
          onChange={handleChange}
          onSubmit={delegateSubmit ? delegateSubmit : handleSubmit}
      />
  );
}

CredentialsFormContainer.propTypes = {
  delegateSubmit: PropTypes.func
}
export default CredentialsFormContainer;
