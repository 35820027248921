import React, {Component} from "react";
import PropTypes from "prop-types";
import "../../index.css";
import {Box, Button, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";

class PasswordReset extends Component {
  render() {
    return (
      <Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onSubmit(e);
          }}
        >
          <Grid container spacing={1} direction={"column"}>
            <Grid item>
              <h5> Enter your new password and confirm</h5>
            </Grid>
            <Grid item>
              <TextField
                id={"password"}
                name={"password"}
                label={"New Password"}
                type={"password"}
                variant={"standard"}
              />
              <TextField
                id={"confirm"}
                name={"confirm"}
                label={"Confirm Password"}
                type={"password"}
                variant={"standard"}
              />
            </Grid>
            <Grid item>
              <Button type={"submit"} color={"primary"} variant={"contained"}>
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }
}

PasswordReset.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default PasswordReset;
