import React, {createRef, useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete, Button, Divider, Grid, Paper, TextField, Typography, useTheme,} from "@mui/material";
import {PostcodeLookup} from "@ideal-postcodes/postcode-lookup";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    captionStyle: {
        ...theme.typography.caption, // This spreads the caption styles
    },
}));
const PostcodeLookupComponent = (props) => {
  const key = process.env.REACT_APP_IDEAL_POSTCODES_KEY;
  const context = createRef();

  useEffect(() => {
    PostcodeLookup.setup({
      apiKey: key,
      context: context.current,
      buttonStyle: {
        backgroundColor: "green",
        color: "white",
      },
      ...props,
    });
  }, []);

  return <div ref={context}></div>;
};

const OrderLineItem = ({ product, removeItem, updateQuantity }) => {
  const theme = useTheme();
    const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      alignItems="flex-start"
      mt={0.5}
      style={{ backgroundColor: theme.palette.primary.light, padding: '8px 0' }}
    >
      <Grid item xs={4} style={{ padding: '4px 0' }}>
        <Typography variant="caption">{product.productTitle}</Typography>
      </Grid>
      <Grid item xs={3} style={{ padding: '4px 0' }}>
        <Typography variant="caption">
          Available: {product.currentQuantity}
        </Typography>
      </Grid>
        <Grid item xs={2} style={{ padding: '4px 0' }}>
            <TextField
                label="Quantity"
                type="number"
                variant={"standard"}
                InputProps={{
                    inputProps: {
                        min: 1,
                        max: product.currentQuantity,
                        className: classes.captionStyle
                    },
                    className: classes.captionStyle
                }}
                InputLabelProps={{
                    className: classes.captionStyle
                }}
                value={product.quantity}
                onChange={(e) =>
                    updateQuantity(product.productId, parseInt(e.target.value))
                }
            />
        </Grid>

        <Grid item xs={3} style={{ padding: '4px 0' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.light,
            color: "white",
          }}
          onClick={() => removeItem(product.productId)}
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  );
};

const CreateOrder = ({
  entireOrderState,
  selectedProducts = [],
  productInventory = [],
  addItem,
  removeItem,
  updateQuantity,
  updateOrderField,
  updateAddressField,
  handleSave,
  handleCancel,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave(); // Call the handleSave function here
  };

  useEffect(() => {
    // console.log("Selected Products: ", selectedProducts);
    // console.log("Product Inventory: ", productInventory);
  }, [selectedProducts, productInventory]);

  return (
    <Grid container>
      <Grid item container direction={"column"}>
        <PostcodeLookupComponent
          onAddressSelected={(address) => {
            console.log("Address: ", address);
            updateAddressField({
              fieldName: "Address1",
              value: address.line_1,
            });
            updateAddressField({
              fieldName: "Address2",
              value: address.line_2,
            });
              updateAddressField({
                  fieldName: "Address3",
                  value: address.line_3,
              });
            updateAddressField({ fieldName: "City", value: address.post_town });
            updateAddressField({
              fieldName: "County",
              value: address.postal_county,
            });
            updateAddressField({
              fieldName: "postCode",
              value: address.postcode,
            });
          }}
        />
      </Grid>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Create Order
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="Company"
                label="Company"
                variant="outlined"
                size={"small"}
                fullWidth
                value={entireOrderState.shippingAddress.Company}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.firstName}
                onChange={(e) =>
                  updateOrderField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                label="Last Name"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.lastName}
                onChange={(e) =>
                  updateOrderField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Address1"
                label="Address1"
                variant="outlined"
                required={true}
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.Address1}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Address2"
                label="Address2"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.Address2}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
              <Grid item xs={6}>
                  <TextField
                      id="Address3"
                      label="Address3"
                      variant="outlined"
                      fullWidth
                      size={"small"}
                      value={entireOrderState.shippingAddress.Address3}
                      onChange={(e) =>
                          updateAddressField({
                              fieldName: e.target.id,
                              value: e.target.value,
                          })
                      }
                  />
              </Grid>
            <Grid item xs={6}>
              <TextField
                id="City"
                label="City"
                variant="outlined"
                required
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.City}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="County"
                label="County"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.County}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Country"
                label="Country"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.Country}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="postCode"
                label="PostCode"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.postCode}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.email}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="phone"
                label="Phone"
                variant="outlined"
                fullWidth
                size={"small"}
                value={entireOrderState.shippingAddress.phone}
                onChange={(e) =>
                  updateAddressField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="sellerNotes"
                label="Invoice Number"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                size={"small"}
                value={entireOrderState.sellerNotes}
                onChange={(e) =>
                  updateOrderField({
                    fieldName: e.target.id,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
              <Grid item xs={12}>
                  <Autocomplete
                      id="combo-box-product"
                      options={productInventory.filter(
                          (item) => item.currentQuantity > 0
                      )}
                      getOptionLabel={(option) => option.productTitle}
                      renderOption={(props, option) => (
                          <Box
                              component="li"
                              {...props}
                              sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"  // Ensures vertical alignment
                              }}
                          >
                              <Typography variant="body1" style={{ marginRight: '16px' }}>
                                  {option.productTitle}
                              </Typography>
                              <Typography variant="body2">
                                  {option.currentQuantity}
                              </Typography>
                          </Box>
                      )}
                      renderInput={(params) => (
                          <TextField {...params} label="Add Product" />
                      )}
                      onChange={(event, newValue) => {
                          if (newValue) {
                              addItem(newValue.productId);
                          }
                      }}
                  />
              </Grid>

              <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item container xs={12} m={1}>
              {selectedProducts.map((product) => (
                <OrderLineItem
                  key={product.productId}
                  product={product}
                  removeItem={removeItem}
                  updateQuantity={updateQuantity}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2} mt={2}>
              <Grid item>
                <Button variant="contained" color="primary" type={"submit"}>
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default CreateOrder;
