import React, {createRef, useEffect} from "react";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {PostcodeLookup} from "@ideal-postcodes/postcode-lookup";


const PostcodeLookupComponent = (props) => {
    const key = process.env.REACT_APP_IDEAL_POSTCODES_KEY;
    const context = createRef();

    useEffect(() => {
        PostcodeLookup.setup({
            apiKey: key,
            context: context.current,
            buttonStyle: {
                backgroundColor: "orange",
                color: "white",
            },
            ...props,
        });
    }, []);

    return <div ref={context}></div>;
};


const CreateLead = ({
                         lead,
                         updateLeadField,
                         updateAddressField,
                         handleSave,
                         handleCancel,
    leadStatusValues
                     }) => {

    const [selectedStatus, setSelectedStatus] = React.useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave(); // Call the handleSave function here
    };

    const handleSelectChange = (event) => {
        setSelectedStatus(event.target.value);
        //console.log(event.target);
        updateLeadField({
            fieldName: event.target.name, //need to use name not id for a select
            value: event.target.value,
        });
    };
    return (
        <Grid container>
            <Grid item container direction={"column"}>
                <PostcodeLookupComponent
                    onAddressSelected={(address) => {
                        console.log("Address: ", address);
                        updateAddressField({
                            fieldName: "Address1",
                            value: address.line_1,
                        });
                        updateAddressField({
                            fieldName: "Address2",
                            value: address.line_2,
                        });
                        updateAddressField({
                            fieldName: "Address3",
                            value: address.line_3,
                        });
                        updateAddressField({ fieldName: "City", value: address.post_town });
                        updateAddressField({
                            fieldName: "County",
                            value: address.postal_county,
                        });
                        updateAddressField({
                            fieldName: "postCode",
                            value: address.postcode,
                        });
                    }}
                />
            </Grid>
            <Paper elevation={3} style={{ padding: "20px" }}>
                <Typography variant="h5" gutterBottom>
                    Create New Lead
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                id="customerName"
                                label="Company Name"
                                variant="outlined"
                                size={"small"}
                                fullWidth
                                value={lead?.customerName}
                                onChange={(e) =>
                                    updateLeadField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="value-type-select-label">Status</InputLabel>
                                <Select
                                    labelId="value-type-select-label"
                                    id="status"
                                    name="status"
                                    value={selectedStatus ? selectedStatus : lead?.status } label={"Status"} onChange={handleSelectChange}>
                                    {leadStatusValues.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="Address1"
                                label="Address1"
                                variant="outlined"
                                required={true}
                                fullWidth
                                size={"small"}
                                value={lead?.address?.Address1}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="Address2"
                                label="Address2"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={lead?.address?.Address2}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="Address3"
                                label="Address3"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={lead?.address?.Address3}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="City"
                                label="City"
                                variant="outlined"
                                required
                                fullWidth
                                size={"small"}
                                value={lead?.address?.City}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="County"
                                label="County"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={lead?.address?.County}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="Country"
                                label="Country"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={lead?.address?.Country}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="postCode"
                                label="PostCode"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={lead?.address?.postCode}
                                onChange={(e) =>
                                    updateAddressField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid container justifyContent="flex-end" spacing={2} mt={2}>
                            <Grid item>
                                <Button variant="contained" color="primary" type={"submit"}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};

export default CreateLead;
