// userSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import _ from "lodash";
import {enqueueSnackbar} from "../actions/snackActions";

export const saveUpdatedUsersToServer = createAsyncThunk(
    'users/saveUpdatedUsersToServer',
    async (updatedUsers, thunkAPI) => {
        try {
            const response = await axios.put('/api/admin/users', updatedUsers);
            thunkAPI.dispatch(clearUpdatedRows());
            console.log(response.data);
            response.data.forEach((item) => {
                console.log(item);
                if (item.success) {
                    thunkAPI.dispatch(enqueueSnackbar({
                        message: `User ${item.userId} updated successfully.`,
                        options: { variant: "success" },
                    }));
                } else {
                    thunkAPI.dispatch(enqueueSnackbar({
                        message: `Failed to update user ${item.userId}: ${item.data}`,
                        options: { variant: "error" },
                    }));
                }
            });
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(
                enqueueSnackbar(error.response?.data, { variant: "error" })
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (searchParams, thunkAPI) => {
    const query = searchParams ? new URLSearchParams(searchParams).toString() : '';
    try {
        const response = await axios.get(searchParams ? `/api/admin/users?${query}` : '/api/admin/users');
        return response.data;
    } catch (error) {
        thunkAPI.dispatch(
            enqueueSnackbar(error.response?.data, { variant: "error" })
        );
        return thunkAPI.rejectWithValue(error.response.data);

    }

});

export const addUser = createAsyncThunk('users/addUser', async (newUserData,thunkAPI) => {
    try {
        const response = await axios.post('/api/admin/users', newUserData);
        thunkAPI.dispatch(
            enqueueSnackbar({
                message: "New User Created",
                options: { variant: "success" },
            })
        );
        return response.data;
    } catch (error) {
        thunkAPI.dispatch(
            enqueueSnackbar(error.response?.data, { variant: "error" })
        );
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

const userSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        updatedRows: {},
        status: 'idle',
        error: null,
        roles: [
            { value: 'glradmin', label: 'GLR Admin' },
            { value: 'customer', label: 'Customer' },
            { value: 'cbuser', label: 'Staff' },
            {value: 'cbadmin', label: 'CB Admin'},
            {value: 'member', label: 'Member'},
        ],
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = _.cloneDeep(action.payload);
        },
        updateRow: (state, action) => {
            const { rowId, updatedValues } = action.payload;

            // Find the index of the user with the given rowId in the users array
            const index = state.users.findIndex(user => user._id === rowId);

            // Check if the user exists
            if (index !== -1) {
                // Update the user in the original users array
                state.users[index] = {
                    ...state.users[index],
                    ...updatedValues,
                };
            }

            // Check if updatedRows already has an entry for this row
            if (!state.updatedRows[rowId]) {
                state.updatedRows[rowId] = {};
            }

            // Update the entry in updatedRows
            state.updatedRows[rowId] = {
                ...state.updatedRows[rowId],
                ...updatedValues,
            };
        },
        clearUpdatedRows: (state) => {
            state.updatedRows = {}; // Resetting updatedRows to an empty array
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = _.cloneDeep(action.payload);

            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                enqueueSnackbar(action.error.message, {variant: 'error'});
            })
            .addCase(addUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Add the new user to the users array
                //state.users.push(action.payload);
                enqueueSnackbar('User added successfully', {variant: 'success'});
            })
            .addCase(addUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                enqueueSnackbar(action.error.message, {variant: 'error'});
            });
    },
});

export const { setUsers, updateRow,clearUpdatedRows } = userSlice.actions;

export default userSlice.reducer;
