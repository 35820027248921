// rmSlice.js
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 0 });

export const fetchOrders = createAsyncThunk(
  "api/rm/orders/orders",
  async ({ startDate, endDate, orderStatus, customerId }, thunkAPI) => {
    const startDateStr = startDate.toISOString();
    const endDateStr = endDate.toISOString();
    const axiosOptions = {
      params: {
        startDateTime: startDateStr,
        endDateTime: endDateStr,
        orderStatus: orderStatus,
        customerId: customerId,
      },
    };
    try {
      const response = await axios.get(`/api/rm/orders`, axiosOptions);
      return response.data;
    } catch (e) {
      // set the status to failed
      return thunkAPI.rejectWithValue({ error: e.message });
    }
  }
);

// Create an async thunk for sending orders to the server
export const sendOrdersToServer = createAsyncThunk(
  "api/rm/orders/addmovements",
  async (selectedOrders, thunkAPI) => {
    try {
      const response = await axios.post("/api/rm/orders/addmovement", {
        orders: selectedOrders,
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      console.log("error.response", error.response);
      if (error.response && error.response.status === 409) {
        // Handle 409 conflict
        console.log("Duplicate Detected");
        return thunkAPI.rejectWithValue({
          status: 409,
          message: "Duplicate Detected",
        });
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const syncOrders = createAsyncThunk(
  "api/rm/orders/sync.json",
  async (_, { dispatch }) => {
    try {
      const response = await axios.get("/api/rm/orders/sync.json");
      dispatch(setSocketStatus("working"));
      return response.data;
    } catch (error) {
      // Handle error
      throw error;
    }
  }
);
const initialState = {
  orders: [],
  selectedOrders: [],
  movementCost: null,
  status: "idle",
  error: null,
  socket: {
    status: "idle",
  },
};

const rmSlice = createSlice({
  name: "rm",
  initialState,
  reducers: {
    updateOrdersFromWebSocket: (state, action) => {
      // Update the 'orders' state with the payload
      state.orders = action.payload;
    },
    setSelectedOrder: (state, action) => {
      const exists = state.selectedOrders.some(
        (order) => order.orderIdentifier === action.payload.orderIdentifier
      );
      if (!exists) {
        state.selectedOrders.push(action.payload);
      }
    },
    removeSelectedOrder: (state, action) => {
      const index = state.selectedOrders.findIndex(
        (row) => row.orderIdentifier === action.payload.orderIdentifier
      );
      if (index !== -1) {
        state.selectedOrders.splice(index, 1);
      }
    },
    setMovementCost: (state, action) => {
      const movementCost = action.payload;
      state.movementCost = movementCost;
      state.selectedOrders = state.selectedOrders.map((order) => ({
        ...order,
        movementCost,
      }));
    },
    setSocketStatus: (state, action) => {
      // If the incoming status is "error", reset relevant statuses
      if (action.payload === "error") {
        // Set socket status to "error"
        state.socket.status = "failed";
        // Reset the general status to indicate no ongoing operation
        state.status = "failed";
        state.error = "error"; // or "error" if you want to distinguish this state explicitly
        // Optionally reset other statuses or fields if necessary
        // For example, clearing selected orders or error messages
        // state.selectedOrders = [];
        // state.error = null; // or set a specific error message
      } else {
        // If the status is not "error", proceed as normal
        state.socket.status = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload;
        state.error = null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(syncOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(syncOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload;
        state.error = null;
      })
      .addCase(syncOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendOrdersToServer.pending, (state) => {
        state.status = "sending";
      })
      .addCase(sendOrdersToServer.fulfilled, (state) => {
        state.status = "sent";
        // Here, you can also reset your selectedOrders if you like
        // state.selectedOrders = [];
      })
      .addCase(sendOrdersToServer.rejected, (state, action) => {
        //console.log("action", action);
        state.status = "failed";
        if (action.payload && action.payload.status === 409) {
          // Specific logic for 409 status

          state.error = action.payload.message;
        } else {
          state.error = action.error.message || "An unknown error occurred";
        }
      });
  },
});

export const {
  setSocketStatus,
  setSelectedOrder,
  setMovementCost,
  removeSelectedOrder,
  updateOrdersFromWebSocket,
} = rmSlice.actions;
export default rmSlice.reducer;
