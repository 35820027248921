// index file within reducers directory
import authReducer from './authReducer';
import membersReducer from './membersReducer';
import surveyReducer from './surveysReducer';
import studentReducer from './studentReducer';
import categorysReducer from './categorysReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import XODstudentReducer from './XODStudentsReducer';
import XODSchoolReducer from './XODSchoolReducer';
import XODSingleStudentReducer from './XODSingleStudentReducer';
import XODAchievementsReducer from './XODAchivementReducer';
import cabinetReducer from './rewardReducer';
import orderReducer from './orderReducer';
import UIReducer from './UIReducer';
import containerReducer from './containerReducer';
import centreReducer from './centreReducer';
import reportReducer from './reportReducer';
import cbuserReducer from './cbuserReducer';
import snackReducer from './snackReducer';
import chatReducer from './chatReducer';
import {inventoryReducer} from './cbinventoryReducer';
import {cbOrderReducer} from "./cbOrderReducer";
import {customerReducer} from "./customerReducer";
import selectedOrdersReducer from "./selectedOrdersSlice";
import rmReducer from "./rmSlice";
import custOrderReducer from "./orderSlice";
import adminEventsReducer from "./adminEventsSlice";
import adminUsersReducer from "./userSlice";
import pickingReducer from "./pickingSlice";
import shipmentsReducer from "./shipmentsSlice";
import leadReducer from "./leadSlice";
import configReducer from "./configSlice";


const rootReducer = {
    auth: authReducer,
    members: membersReducer,
    container: containerReducer,
    cabinet: cabinetReducer,
    centre: centreReducer,
    cart: cartReducer,
    order: orderReducer,
    surveys: surveyReducer,
    students: studentReducer,
    xodstudents: XODstudentReducer,
    xodSingleStudent: XODSingleStudentReducer,
    xodschool: XODSchoolReducer,
    xodAchievements: XODAchievementsReducer,
    categories: categorysReducer,
    products: productReducer,
    reports: reportReducer,
    ui: UIReducer,
    chat: chatReducer,
    cbuser: cbuserReducer,
    snack: snackReducer,
    inventory: inventoryReducer,
    cborder: cbOrderReducer,
    customer: customerReducer,
    selectedOrders: selectedOrdersReducer,
    rm: rmReducer,
    customerOrder: custOrderReducer,
    adminEvents: adminEventsReducer,
    users: adminUsersReducer,
    picking: pickingReducer,
    shipments: shipmentsReducer,
    leads: leadReducer,
    config: configReducer,
};

export default rootReducer;
