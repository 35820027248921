import {CREATE_ROOM_SUCCESS, JOIN_ROOM_SUCCESS, SET_USERNAME, UPDATE_CHAT_LOG} from '../actions/chatActions';

const initialState = {
        room: null,
        chatLog: [],
        username: null
}
export default function chatReducer(state, action) {
        if (typeof state === 'undefined') {
                return initialState
            }

        switch(action.type){
                case CREATE_ROOM_SUCCESS:
                    console.log(`CREATE_ROOM_SUCCESS: ${JSON.stringify(action.payload)}`);
                    return Object.assign({}, state, {
                        room: action.payload,
                    });
                    case JOIN_ROOM_SUCCESS:
                        console.log(`JOIN_ROOM_SUCCESS: ${JSON.stringify(action.payload)}`);
                        return Object.assign({}, state, {
                            room: action.payload,
                        });

                    case SET_USERNAME:
                        console.log(`reducer: ${action.payload}`);
                        return Object.assign({}, state, {
                            username: action.payload,
                        });

            case UPDATE_CHAT_LOG:
                if(state.room !== null && action.update.roomId === state.room.id){
                    console.log(`reducer: UPDATE_CHAT_LOG`);
                   return {...state, chatLog: [...state.chatLog, action.update.data]};
                }
                break;
            default:
                break;

        }

        return state
    }
