import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {createRoom, joinRoom} from '../../actions/chatActions';
import ChatRoom from './ChatRoom'
import useNotifier from "../useNotifier";
import Container from "@mui/material/Container";
import {Outlet} from "react-router-dom";
import {Grid} from "@mui/material";

function ChatContainer(){
    const [roomName, setRoomName] = useState("");
    const [roomId, setRoomId] = useState("");
        const currentRoom = useSelector(state => state.chat.room);
        const dispatch = useDispatch();
        useNotifier();
        return (
       <Container>
                    {!currentRoom &&
                        <Grid container>
                                <Grid>
                                        <span>Create new room</span>
                                        <input type="text" placeholder="Room name" value={roomName} onChange={(e) => {
                                            setRoomName(e.target.value)}} />
                                        <button onClick={() => dispatch(createRoom(roomName))}>Create</button>
                                </Grid>
                                <Grid>
                                        <span>Join existing room</span>
                                        <input type="text" placeholder="Room code" value={roomId} onChange={(e) => {
                                            setRoomId(e.target.value)}} />
                                        <button onClick={() => dispatch(joinRoom(roomId))}>Join</button>
                                </Grid>
                        </Grid>
                    }

                    {currentRoom &&
                        <ChatRoom />
                    }
           <Outlet />
       </Container>

        );
    }
export default ChatContainer;
