import React, {useEffect, useRef} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community';

function LineItemsGrid({ lineItems }) {
    const gridApiRef = useRef(null);

    const onGridReady = (params) => {
        console.log(params.api);
        gridApiRef.current = params.api;
        const columnApi = params.columnApi;
        columnApi.autoSizeColumns(['productTitle']);
    };

    const resizeColumns = () => {
        if (gridApiRef.current) {
            gridApiRef.current.columnApi.autoSizeColumns(['productTitle']);
        }
    };


    const columnDefs = [
        { headerName: 'Product ID', field: 'productId', sortable: true, filter: true,maxWidth: 150 },
        { headerName: 'SKU', field: 'sku', sortable: true, filter: true, maxWidth: 100 },
        { headerName: 'Quantity', field: 'quantity', sortable: true, filter: true, maxWidth: 50 },
        { headerName: 'Product Title', field: 'productTitle', sortable: true, filter: true, minWidth: 100},
        { headerName: 'Variant ID', field: 'variantId', sortable: true, filter: true },
        { headerName: 'Price', field: 'price', sortable: true, filter: true },
        { headerName: 'VAT', field: 'vat', sortable: true, filter: true },
        { headerName: 'Price Inc VAT', field: 'priceIncVat', sortable: true, filter: true },
        { headerName: 'Weight', field: 'weight', sortable: true, filter: true },
        { headerName: 'Weight Unit', field: 'weightUnit', sortable: true, filter: true },
        { headerName: 'Width', field: 'width', sortable: true, filter: true },
        { headerName: 'Height', field: 'height', sortable: true, filter: true },
        { headerName: 'Length', field: 'length', sortable: true, filter: true },
        { headerName: 'Category', field: 'category', sortable: true, filter: true },
        { headerName: 'HS Code', field: 'hsCode', sortable: true, filter: true },
        // You can easily add more columns as needed.
    ];


    useEffect(() => {
        resizeColumns();
    }, [lineItems]);
    return (
        <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
            <AgGridReact
                onGridReady={onGridReady}
                defaultColDef={{ resizable: true }}
                rowData={lineItems}
                columnDefs={columnDefs}
                domLayout='autoHeight'
            />
        </div>
    );
}

export default LineItemsGrid;
