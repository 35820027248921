import {
  ADMIN_LINE_MARKED_DELETE,
  ADMIN_LINE_UNMARKED_DELETE,
  INVALIDATE_ORDER,
  LINE_MARKED_DELETE,
  LINE_UNMARKED_DELETE,
  RECEIVED_LINEITEMS,
  RECEIVED_LINEITEMS_NONE,
  RECEIVE_ORDER,
  REQUEST_LINEITEMS,
  REQUEST_DRAFT_ORDERS,
  REQUEST_ORDER,
  RESET_DELETED,
  GLR_RECEIVED_ORDER_LIST,
  GLR_RECEIVED_DRAFT_ORDER_LIST,
  GLR_DELETE_DRAFT_ORDER_DONE,
  RECEIVED_DRAFT_ORDER_COUNT, RECEIVED_DRAFT_ORDER,
  DRAFT_ORDER_PAGINATION, REQUEST_INVOICES, RECEIVED_INVOICE_LIST, DELETED_INVOICE,
    RECEIVED_DRAFT_ORDER_INVENTORY
} from "../actions/orderActions";

function order(
  state = {
    isFetching: false,
    didInvalidate: false,
    deletes: [],
    adminDeletes: [],
    orderDetail: {}
  },
  action
) {
  switch (action.type) {
    case RECEIVED_DRAFT_ORDER_INVENTORY:
      return {
        ...state,
        inventory: action.payload
      }
    case DRAFT_ORDER_PAGINATION:
    return Object.assign({}, state, {
      pagination: action.payload
    });
    case RECEIVED_DRAFT_ORDER_COUNT:
      return Object.assign({}, state, {
        count: action.payload.count
      });
    case REQUEST_INVOICES:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_DRAFT_ORDERS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case DELETED_INVOICE:
      //do nothing
      //remove the line from the store
      let invoiceIndex = state.invoiceList.findIndex(item => {
        return item._id === action.payload;
      });
      if (invoiceIndex > -1) {
        return Object.assign({}, state, {
          draftOrderList: [
            ...state.invoiceList.slice(0, invoiceIndex),
            ...state.invoiceList.slice(invoiceIndex + 1)
          ]
        });
      } else {
        return state;
      }
    case GLR_DELETE_DRAFT_ORDER_DONE:
      //remove the line from the store
      let draftIndex = state.draftOrderList.findIndex(item => {
        return item._id === action.payload.draftOrderId;
      });
      if (draftIndex > -1) {
        return Object.assign({}, state, {
          draftOrderList: [
            ...state.draftOrderList.slice(0, draftIndex),
            ...state.draftOrderList.slice(draftIndex + 1)
          ]
        });
      } else {
        return state;
      }
    case RESET_DELETED:
      return Object.assign({}, state, {
        deletes: [],
        adminDeletes: []
      });
    case ADMIN_LINE_MARKED_DELETE:
      //just need to add it because we remove it if unchecked
      return Object.assign({}, state, {
        adminDeletes: [
          ...state.adminDeletes,
          { lineId: action.lineId, studentId: action.studentId }
        ]
      });
    case ADMIN_LINE_UNMARKED_DELETE:
      let adminIndex = state.adminDeletes.findIndex(
        item =>
          item.lineId === action.lineId && item.studentId === action.studentId
      );
      //console.log(adminIndex);
      if (adminIndex > -1) {
        return Object.assign({}, state, {
          adminDeletes: [
            ...state.adminDeletes.slice(0, adminIndex),
            ...state.adminDeletes.slice(adminIndex + 1)
          ]
        });
      } else {
        return state;
      }
    case LINE_MARKED_DELETE:
      //just need to add it because we remove it if unchecked
      //console.log(`LINE_MARKED_DELETE ${action.lineId}`);
      return Object.assign({}, state, {
        deletes: [...state.deletes, action.lineId]
      });
    case LINE_UNMARKED_DELETE:
      let index = state.deletes.indexOf(action.lineId);
      //console.log(`LINE_UNMARKED_DELETE ${index}`);
      if (index > -1) {
        return Object.assign({}, state, {
          deletes: [
            ...state.deletes.slice(0, index),
            ...state.deletes.slice(index + 1)
          ]
        });
      } else {
        return state;
      }
    case RECEIVED_DRAFT_ORDER:
      return Object.assign({},state, {isFetching: false}); //data retrieved so turn off progress bar
    case REQUEST_LINEITEMS: //a member is looking at their items
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVED_LINEITEMS: // we received a members list of items
      //console.log(action);
      //this payload is only an array of line items
      //previous but set order details to this array
      //it needs to be orderDetail.lineItems

      const orders = action.payload;

      return Object.assign({}, state, {
        isFetching: false,
        orderExists: true,
        didInvalidate: false,
        centre: action.centre,
        orders: orders,
        lastUpdated: action.receivedAt
      });
    case RECEIVED_LINEITEMS_NONE:
      return Object.assign({}, state, {
        orderExists: false,
        isFetching: false
      });
    case INVALIDATE_ORDER:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_ORDER:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_ORDER:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        centre: action.centre,
        orders: action.payload,
        lastUpdated: action.receivedAt
      });
    case GLR_RECEIVED_ORDER_LIST:
      return Object.assign({}, state, {
        GLROrderList: action.payload
      });
    case GLR_RECEIVED_DRAFT_ORDER_LIST:
      return Object.assign({}, state, {
        draftOrderList: action.payload,
        isFetching: false
      });
    case RECEIVED_INVOICE_LIST:
      return Object.assign({}, state, {
        invoiceList: action.payload,
        isFetching: false
      });
    default:
      return state;
  }
}
export default order;
