import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserProfileScreen from "../user/UserProfileScreen";
import {changePwd, updateEmailAction} from "../../actions/memberActions";
import useNotifier from "../useNotifier";
import {useNavigate} from "react-router-dom";

function UserProfileScreenContainer() {
  useNotifier();
  let navigate = useNavigate();
  let user = useSelector((state) => state.auth);

  let dispatch = useDispatch();
  function handlePasswordSubmit() {
    dispatch(changePwd());
  }
  function handleEmailSubmit(id,email) {
    console.log(`id: ${id}, email: ${email}`);
    dispatch(updateEmailAction(id,email));
  }
  useEffect(() => {
    console.log(user);
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);
  return (
      <Fragment>
            <UserProfileScreen
                user={user}
                pwdChangeSubmit={handlePasswordSubmit}
                updateEmailAction={handleEmailSubmit}
            />

        </Fragment>
  );
}

export default UserProfileScreenContainer;
