import axios from 'axios';
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction,} from "./snackActions";
import Button from "@mui/material/Button";
// These are our action types
export const CREATE_ROOM_REQUEST = "CREATE_ROOM_REQUEST"
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS"

const API_BASE = '/chat';
// Now we define actions
export const enqueueSnackbar =
    (message, variant, duration, dismiss) => async (dispatch) => {
        dispatch(
            enqueueSnackbarAction({
                message: message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: variant ? variant : "default",
                    autoHideDuration: duration,
                    action: dismiss ? (key) => (
                        <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                            dismiss
                        </Button>
                    ) : ""
                },
            })
        );
    };
export function createRoomRequest(){
    return {
        type: CREATE_ROOM_REQUEST
    }
}

export function createRoomSuccess(payload){
    return {
        type: CREATE_ROOM_SUCCESS,
        payload
    }
}


export function createRoom(roomName) {
    return async function (dispatch) {
        dispatch(createRoomRequest());
        try{
            const response = await axios.get(`${API_BASE}/room?name=${roomName}`)
            dispatch(createRoomSuccess(response.data));
        }catch(error){
            dispatch(enqueueSnackbar(error.message,"error",10000,true  ));
        }
    }
}


export const JOIN_ROOM_REQUEST = "JOIN_ROOM_REQUEST"
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS"
export const JOIN_ROOM_ERROR = "JOIN_ROOM_ERROR"

export function joinRoomRequest(){
    return {
        type: JOIN_ROOM_REQUEST
    }
}

export function joinRoomSuccess(payload){
    return {
        type: JOIN_ROOM_SUCCESS,
        payload
    }
}

export function joinRoomError(error){
    return {
        type: JOIN_ROOM_ERROR,
        error
    }
}

export function joinRoom(roomId) {
    return async function (dispatch) {
        dispatch(joinRoomRequest());
        try{
            const response = await axios.get(`${API_BASE}/room/${roomId}`)
            dispatch(joinRoomSuccess(response.data));
        }catch(error){
            dispatch(joinRoomError(error));
        }
    }
}

export const SET_USERNAME = "SET_USERNAME"

export function setUsername(username){
    console.log(username);
    return {
        type: SET_USERNAME,
        payload: username
    }
}

export const UPDATE_CHAT_LOG = "UPDATE_CHAT_LOG"

export function updateChatLog(update){
    return {
        type: UPDATE_CHAT_LOG,
        update
    }
}
