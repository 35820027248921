import { FETCH_PRODUCTS } from "../actions/types";
import {
  GLR_FETCH_PRODUCT_COUNT,
  GLR_PRODUCT_PAGINATION,
  GLR_RECEIVED_PRODUCT_COUNT,
  GLR_RECEIVED_PRODUCTS,
  GLR_FETCH_PRODUCTS
} from "../actions/productActions";

export default function product(state = null, action) {
  switch (action.type) {
      case GLR_FETCH_PRODUCTS:
          return Object.assign({}, state, {
              isFetching: true
          });

    case GLR_RECEIVED_PRODUCTS:
      //console.log(action.payload);
      return Object.assign({}, state, {
        items: action.payload,
          isFetching: false
      });
    case FETCH_PRODUCTS:
      let firstCursor = null;
      let lastCursor = null;
      if (action.payload.prods) {
        lastCursor = getProductCursor(action.payload.prods);
        firstCursor = getProductCursor(action.payload.prods, true);
      }
      console.log("productReducer: ", action.payload.page);
      return Object.assign({}, state, {
        lastCursor: lastCursor,
        firstCursor: firstCursor,
        prods: action.payload.prods || false,
        page: action.payload.page || false
      });
    case GLR_PRODUCT_PAGINATION:
      return Object.assign({}, state, {
        pagination: action.payload
      });
    case GLR_RECEIVED_PRODUCT_COUNT:
      return Object.assign({}, state, {
        count: action.payload.count
      });
    case GLR_FETCH_PRODUCT_COUNT:
      return Object.assign({}, state, {
        count: action.payload.count
      });
    default:
      return state;
  }
}

function getProductCursor(products, reverse) {
  if (!products) {
    return;
  }
  if (reverse) {
    return products[0].cursor;
  }
  let val = products[products.length - 1];
  return val.cursor;
}
