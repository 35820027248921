import {
  RECEIVED_DRAFT_ORDER_MTD_TOTALS,
  RECEIVED_DRAFT_ORDER_TOTALS,
  REQUEST_DRAFT_ORDER_TOTALS,
  REQUEST_DRAFT_ORDER_MTD_TOTALS,
  RECEIVED_DRAFT_ORDER_RANKED_CENTRES,
  REQUEST_DRAFT_ORDER_RANKED_CENTRES, REQUEST_DRAFT_ORDER_YTD_TOTALS, RECEIVED_DRAFT_ORDER_YTD_TOTALS
} from "../actions/reportActions";

export const reports = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_DRAFT_ORDER_TOTALS ||
      REQUEST_DRAFT_ORDER_MTD_TOTALS || REQUEST_DRAFT_ORDER_YTD_TOTALS ||
      REQUEST_DRAFT_ORDER_RANKED_CENTRES:
          return Object.assign({},state, {isFetching: true});
    case RECEIVED_DRAFT_ORDER_TOTALS:
      return Object.assign({}, state, { totals: action.payload, isFetching: false });
    case RECEIVED_DRAFT_ORDER_MTD_TOTALS:
      return Object.assign({}, state, { mtdtotals: action.payload, isFetching: false });
    case RECEIVED_DRAFT_ORDER_YTD_TOTALS:
      return Object.assign({}, state, { ytdtotals: action.payload, isFetching: false });
    case RECEIVED_DRAFT_ORDER_RANKED_CENTRES:
      return Object.assign({}, state, { rankedcentres: action.payload, isFetching: false });
    default:
      return state;
  }
};

export default reports;
