import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchLastSync = createAsyncThunk(
    'shipments/fetchLastSync',
    async (serviceName, thunkAPI) => {
        const axiosOptions = {
            params: { serviceName: serviceName },
        };
        try {
            const response = await axios.get(`/api/cb/shipments/lastSync`, axiosOptions);
            //console.log('serviceName', serviceName);
            //console.log('response', response.data);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


const shipmentsSlice = createSlice({
    name: 'shipments',
    initialState: {
        lastSync: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {
        // Add reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLastSync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLastSync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                //console.log(action.payload);
                state.lastSync = action.payload.lastSync; // Assuming the payload is the last sync data
            })
            .addCase(fetchLastSync.rejected, (state,action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
        // You can chain more cases here
    },
});

export default shipmentsSlice.reducer;
