import "@fontsource/roboto";
import React, {Component, lazy} from "react";
import {fetchConfig} from "../reducers/configSlice";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

import {connect} from "react-redux";
import * as actions from "../actions";

import GLRHeader from "./GLRHeader";
import ErrorBoundary from "./errorpages/ErrorBoundary";
//import RewardContainer from "./containers-rewards/RewardContainer";
import GroupManagementContainer from "./containers-admin/GroupManagementContainer";
import UserProfileScreenContainer from "./containers-user/UserProfileScreenContainer";
import MemberOrderContainer from "./containers-rewards/MemberOrderContainer";
//import SuperAdminDashboardContainer from "./containers-admin/SuperAdminDashboardContainer";
import CustomerDashboardContainer from "./containers-customer/CustomerDashboardContainer";
//import CBUserDashboardContainer from "./containers-cbuser/CBUserDashboardContainer";
import CentreOrderListContainer from "./containers-rewards/CentreOrderListContainer";
import PasswordResetRequestContainer from "./containers-user/PasswordResetRequestContainer";
import PasswordResetContainer from "./containers-user/PasswordResetContainer";
import ChatContainer from "./chat/ChatContainer";
import PrivateRoute from "./PrivateRoute";
import LoginFormContainer from "./login/LoginFormContainer";
import LeadsDashboard from "./leads/LeadsDashboard";

const Landing = lazy(() => import("./Landing"));
//const ChatContainer = lazy(() => import("./chat/ChatContainer"));

const LeadsContainer = lazy(() =>
    import("./leads/LeadsDashboard")
);

const ChartsContainer = lazy(() => import("./containers-cbuser/ChartsContainer"));
const UserAdminContainer = lazy(() =>
  import("./containers-user/UserAdminContainer")
);

const RewardContainer = lazy(() =>
  import("./containers-rewards/RewardContainer")
);
const SuperAdminDashboardContainer = lazy(() =>
  import("./containers-admin/SuperAdminDashboardContainer")
);
const CustomerCBOrdersContainer = lazy(() =>
  import("./containers-cbuser/CustomerCBOrdersContainer")
);
const CBUserDashboardContainer = lazy(() =>
  import("./containers-cbuser/CBUserDashboardContainer")
);
const PickingContainer = lazy(() =>
  import("./containers-cbuser/picking/PickingContainer")
);
const CentreAdminDashboardContainer = lazy(() =>
  import("./containers-admin/CentreAdminDashboardContainer")
);
const SuperAdminDraftOrderContainer = lazy(() =>
  import("./containers-admin/SuperAdminDraftOrderContainer")
);
const InventoryContainer = lazy(() =>
  import("./containers-cbinventory/InventoryContainer")
);

const RMOrdersContainer = lazy(() =>
  import("./containers-rm/RMOrdersContainer")
);
const CBTotalsGridContainer = lazy(() =>
  import("./containers-cbuser/CBTotalsGridContainer")
);
const GuardianDashboardContainer = lazy(() =>
  import("./containers-user/GuardianDashboardContainer")
);
const CabinetContainer = lazy(() =>
  import("./containers-rewards/CabinetContainer")
);
const SuperAdminInvoice = lazy(() => import("./admin/SuperAdminInvoice"));

/*const CentreGridContainer = lazy(() =>
  import("./containers-centres/CentreGridContainer")
);*/

const SuperAdminCentreContainer = lazy(() =>
  import("./containers-admin/SuperAdminCentreContainer")
);
const SuperAdminOrderContainer = lazy(() =>
  import("./containers-admin/SuperAdminOrderContainer")
);
const CBMovementsContainer = lazy(() =>
  import("./containers-cbuser/CBMovementsContainer")
);
const CBCostsContainer = lazy(() =>
  import("./containers-cbuser/CBCostsContainer")
);
const CBTasksContainer = lazy(() =>
  import("./containers-cbuser/CBTasksContainer")
);

const CBInvoiceDashboardContainer = lazy(() =>
  import("./containers-cbuser/CBInvoiceDashboardContainer")
);

const CBCustomerContractContainer = lazy(() =>
  import("./containers-cbuser/CBCustomerContractContainer")
);
const AdminEventsView = lazy(() => import("./admin/AdminEventsView"));

class App extends Component {
  componentDidMount() {
      console.log(this.props);
    //like an initialize function
    //we imported connect above to link react components to the redux store stuff
    //the export at the bottom means the actions will not be in the props for this
    //class
    //console.log("in App component did mount");
    this.props.fetchUser();
console.log('$$$$$ fetching Config');
      // Dispatch fetchConfig to load UI configuration
      this.props.fetchConfig(); // Make sure fetchConfig is connected through your Redux actions
      console.log('$$$$$ fetched Config');
  }

  render() {
    return (
      <ErrorBoundary>
        <BrowserRouter>
          <div>
            <GLRHeader />
            <Routes>
              <Route path="/Login" element={<LoginFormContainer />} />
              <Route path="chat" element={<ChatContainer />} />
              <Route path="/lineitems" element={<MemberOrderContainer />} />
              <Route
                path="/customer"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <PrivateRoute>
                      <CustomerDashboardContainer />{" "}
                    </PrivateRoute>
                  </React.Suspense>
                }
              >
                <Route //proute
                  index={true}
                  path="invoices"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <PrivateRoute>
                        <CustomerDashboardContainer />
                      </PrivateRoute>
                    </React.Suspense>
                  }
                />
              </Route>
              <Route
                path="/glradmin"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <PrivateRoute>
                      <SuperAdminDashboardContainer />
                    </PrivateRoute>
                  </React.Suspense>
                }
              >
                <Route
                  path="users"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <PrivateRoute>
                        <UserAdminContainer />{" "}
                      </PrivateRoute>
                    </React.Suspense>
                  }
                />

                <Route
                  path="events"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <PrivateRoute>
                        <AdminEventsView />{" "}
                      </PrivateRoute>
                    </React.Suspense>
                  }
                />
                <Route //proute
                  index={true}
                  path="draft_orders"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <PrivateRoute>
                        <SuperAdminDraftOrderContainer />
                      </PrivateRoute>
                    </React.Suspense>
                  }
                />
                <Route //proute
                  path="centres"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <SuperAdminCentreContainer />
                    </React.Suspense>
                  }
                />
                <Route //proute
                  path="invoices"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <SuperAdminInvoice />{" "}
                    </React.Suspense>
                  }
                />
                <Route //proute
                  path="orders"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <SuperAdminOrderContainer />{" "}
                    </React.Suspense>
                  }
                />
              </Route>
              <Route
                path="/cbuser"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <PrivateRoute>
                      <CBUserDashboardContainer />{" "}
                    </PrivateRoute>
                  </React.Suspense>
                }
              >
                <Route
                  index={true}
                  path="movements"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <CBMovementsContainer />
                    </React.Suspense>
                  }
                />

                <Route
                  path="costs"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <CBCostsContainer />{" "}
                    </React.Suspense>
                  }
                />
                <Route
                  path="tasks"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <CBTasksContainer />{" "}
                    </React.Suspense>
                  }
                />
                <Route
                  path="invoices"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <PrivateRoute>
                        <CBInvoiceDashboardContainer />
                      </PrivateRoute>
                    </React.Suspense>
                  }
                />
                <Route
                  path="cbtotals"
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <PrivateRoute>
                        <CBTotalsGridContainer />
                      </PrivateRoute>
                    </React.Suspense>
                  }
                />
                <Route
                  path={"customers"}
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <CBCustomerContractContainer />
                    </React.Suspense>
                  }
                />
                <Route
                  path={"inventory"}
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <InventoryContainer />
                    </React.Suspense>
                  }
                />
                <Route
                  path={"orders"}
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <CustomerCBOrdersContainer />
                    </React.Suspense>
                  }
                />
                <Route
                  path={"rmorders"}
                  element={
                    <React.Suspense
                      fallback={<LinearProgress color="primary" />}
                    >
                      <RMOrdersContainer />
                    </React.Suspense>
                  }
                />
                  <Route
                      path={"leads"}
                      element={
                          <React.Suspense
                              fallback={<LinearProgress color="primary" />}
                          >
                              <PrivateRoute>
                                  <LeadsDashboard />
                              </PrivateRoute>
                          </React.Suspense>
                      }
                      />
                  <Route
                      path="charts"
                      element={
                          <React.Suspense
                              fallback={<LinearProgress color="primary" />}
                          >
                              <PrivateRoute>
                                  <ChartsContainer />{" "}
                              </PrivateRoute>
                          </React.Suspense>
                      }
                  />
              </Route>
              <Route
                path="/reward/:id"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <RewardContainer />{" "}
                  </React.Suspense>
                }
              />
              <Route path="/profile" element={<UserProfileScreenContainer />} />
              <Route
                exact
                path="/forgotpassword"
                element={<PasswordResetRequestContainer />}
              />
              <Route path="/newpassword" element={<PasswordResetContainer />} />
              <Route
                index={true}
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <Landing />
                  </React.Suspense>
                }
              />
              <Route
                path="/landing"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <Landing />
                  </React.Suspense>
                }
              />
              <Route
                path="/admin"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <CentreAdminDashboardContainer />{" "}
                  </React.Suspense>
                }
              >
                <Route
                  exact
                  path="/admin/orders"
                  element={<CentreOrderListContainer />}
                />
                <Route
                  exact
                  path="/admin/centremaint"
                  element={<GroupManagementContainer />}
                />
              </Route>
              <Route
                path="/cabinet"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <CabinetContainer />{" "}
                  </React.Suspense>
                }
              />
              <Route
                path="/guardian"
                element={
                  <React.Suspense fallback={<LinearProgress color="primary" />}>
                    <GuardianDashboardContainer />{" "}
                  </React.Suspense>
                }
              />

              <Route path="*" element={<h1> route not found</h1>} />
            </Routes>
          </div>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default connect(null, {...actions, fetchConfig})(App);
