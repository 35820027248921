// noinspection DuplicatedCode

import React from "react";
import PasswordReset from "../user/PasswordReset";
import Feedback from "../Feedback";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {GLRError, sendPasswordUpdate} from "../../actions";
import {Grid} from "@mui/material";

let search;
function renderProgress(props) {
  if (props.progress === true) {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    );
  }
}
function PasswordResetContainer(props) {
  search = useLocation().search;

  return (
    <Grid container spacing={1} direction={"column"}>
      {renderProgress(props)}
      <Grid item>
        <Feedback message={props.message} status={props.status} />
      </Grid>
      <Grid item>
        <PasswordReset onSubmit={props.onSubmit} />
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  let message = "";
  let status = "";
  let progress = false;
  if (state.ui) {
    progress = state.ui.isFetching;
    if (state.ui.error) {
      message = state.ui.error.errorMessage;
      status = state.ui.error.status;
    } else if (state.ui.feedback) {
      message = state.ui.feedback.message;
      status = state.ui.feedback.status;
    }
  }
  return { message, status, progress };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: event => {
      console.log(search);
      console.log(new URLSearchParams(search).get("token"));
      //console.log(event.target[0]);
      //console.log(event.target[1]);
      if (event.target[0].value !== event.target[1].value) {
        dispatch(
          GLRError({
            errorMessage:
              "New Password and Confirmed Password are not the same",
            name: "validation",
            status: "V1"
          })
        );
      }else if ((event.target[0].value.length < 8)||(event.target[1].value.length < 8)){
          dispatch(
              GLRError({
                  errorMessage:
                      "password needs to be at least 8 characters long",
                  name: "validation",
                  status: "V2"
              })
          );
      }
      else{
          dispatch(GLRError(null));
          //and we can now try to fire the action to update the password
          const params = {token: new URLSearchParams(search).get("token"),
              id: new URLSearchParams(search).get("id"),
              password: event.target[0].value
          }
          dispatch(sendPasswordUpdate(params));
      }
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetContainer);
