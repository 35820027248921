export const generateGridValueSetter = (dispatch, updateActionCreator) => {
    return (params) => {
        const { data, newValue, colDef, parent } = params;
        console.log(data);
        console.log(parent);
        console.log(newValue);
        console.log(colDef);
        // Assuming your updateActionCreator expects an object like { id, field, value }
        dispatch(updateActionCreator({
            id: data._id, // Assuming each row has a unique _id
            field: colDef.field,
            value: newValue,
            parent: parent
        }));
        return true; // Indicates the data was updated
    };
};
