import React, {Component, Fragment} from "react";

import {fetchUser, submitLogin} from "../../actions";
//import {fetchCategories} from "../../actions";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import SignIn from "./SignIn";
import Box from "@mui/material/Box";

class LoginFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = values => {
    //console.log(values);
    this.props.submitLogin(values);
    //this.props.fetchUser();
  };

  /**
   * This is an important function this redirects to the right page for the roles
   * @returns {JSX.Element}
   */
  renderContent() {
    if (
      this.props.user &&
      !this.props.user.roles.includes("cbuser") &&
      !this.props.user.roles.includes("glradmin") &&
        !this.props.user.roles.includes("customer")
    ) {
      return <Navigate to="/cabinet" />;
    } else if (this.props.user && this.props.user.roles.includes("glradmin")) {
      //console.log("redirect to admin");
      return <Navigate to="/glradmin" />;
    } else if (this.props.user && this.props.user.roles.includes("cbuser")){
        return <Navigate to={"/cbuser"} />
    } else if (this.props.user && this.props.user.roles.includes("customer")){
        return <Navigate to={"/customer"} />
    }
    else {
      return (
        <Box>
          <SignIn onSubmit={this.handleSubmit} />
        </Box>
      );
    }
  }

  render() {
    return <Fragment>{this.renderContent()}</Fragment>;
  }
}

function mapStateToProps(state) {
  //console.log(state);
  return { actions: state.actions, user: state.auth };
}

export default connect(mapStateToProps, { submitLogin, fetchUser })(
  LoginFormContainer
);
