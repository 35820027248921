import React, {Component} from "react";
import PropTypes from "prop-types";
import "../../index.css";
import {Button, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

class RequestForm extends Component {
  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("clicked");
          this.props.onSubmit(e);
        }}
      >
        <Grid container spacing={3} direction={"column"} alignItems={"center"}>
          <Grid item>
            <Typography variant={"h5"}>
              Please enter your email address
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              id={"email"}
              name={"email"}
              label={"email"}
              variant={"standard"}
              type={"email"}
              className={"validate"}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              className={"submit"}
              variant={"contained"}
              color={"primary"}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default RequestForm;
