import React from "react";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";

const CreateNote = ({       types,
                           note,
                            contactPersons,
                           updateNoteField,
                           handleSave,
                           handleCancel,
                       }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave(); // Call the handleSave function here
    };
    const [selectedContactPerson, setSelectedContactPerson] = React.useState('');
    const [selectedNoteType, setSelectedNoteType] = React.useState('');
    const handleSelectChange = (event) => {
        setSelectedContactPerson(event.target.value);
        //console.log(event.target);
        updateNoteField({
            fieldName: event.target.name, //need to use name not id for a select
            value: event.target.value,
        });
    };
    const handleTypeSelectChange = (event) => {
        setSelectedNoteType(event.target.value);
        //console.log(event.target);
        updateNoteField({
            fieldName: event.target.name, //need to use name not id for a select
            value: event.target.value,
        });
    };
    return (
        <Grid container>
            <Paper elevation={3} style={{ padding: "20px" }}>
                <Typography variant="h5" gutterBottom>
                    Create New Note
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                id="note"
                                label="Note"
                                multiline
                                rows={4}
                                variant="outlined"
                                required={true}
                                size={"small"}
                                fullWidth
                                onChange={(e) =>
                                    updateNoteField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="contact-person-select-label">Contact Person</InputLabel>
                                <Select
                                    labelId="contact-person-select-label"
                                    id="contactPersonsInvolved"
                                    name="contactPersonsInvolved"
                                    value={selectedContactPerson}
                                    label="Contact Person"
                                    onChange={handleSelectChange}
                                >
                                    {contactPersons.map((person) => (
                                        <MenuItem key={person.name} value={person.name}>
                                            {person.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="staffContact"
                                label="CB Contact"
                                variant="outlined"
                                fullWidth
                                size={"small"}
                                value={note?.staffContact}
                                onChange={(e) =>
                                    updateNoteField({
                                        fieldName: e.target.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="note-type-select-label">Type</InputLabel>
                                <Select
                                    labelId="note-type-select-label"
                                    id="type"
                                    name="type"
                                    value={selectedNoteType} label={"Type"} onChange={handleTypeSelectChange}>
                                    {types.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid container justifyContent="flex-end" spacing={2} mt={2}>
                            <Grid item>
                                <Button variant="contained" color="primary" type={"submit"}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};

export default CreateNote;
